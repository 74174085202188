import React, { useState, useEffect, useContext, useRef } from "react";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import {
  handleAdd,
  piColumns,
  handleRemove,
  handleRemovePrev,
} from "./DeliveryChallanFunc.js";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import {
  Button,
  Checkbox,
  Collapse,
  Input,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";
import {
  getToday,
  dateFormatter,
  convertToBoxAndPieces,
  enterPress,
} from "../../CommonControllers/AppFunction";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { AuthStore } from "../../Auth/AuthStore";
import { IoAperture } from "react-icons/io5";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import {
  apiCallwithBaseUrl,
  getfastpathapi,
} from "../../CommonControllers/ApiCalls";
import { Divider, List } from "antd";
import { FcAddDatabase } from "react-icons/fc";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import {
  getdomaincustomeritemlist,
  getDomaincustomerlist,
  getDomainCustomerSalesman,
  getDomainLocationUrl,
} from "../../CommonControllers/Urls";
import CustomerLocationList from "../../CommonControllers/SaveComponents/HigherLevel/CustomerLocationList";

import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import "../../CommonControllers/global.css";
import ExecuteTextInput from "../../CommonControllers/Components/ExecuteComponents/ExecuteTextInput";
import InputComponent from "../../CommonControllers/Components/InputComponent";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import { currentTabData } from "../../CommonControllers/Variable";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn.js";
import { notification } from "antd";
import ItemSelectionModal from "../../CommonControllers/ItemController/ItemSelectionModal.js";
import axios from "axios";
function DeliveryChallan({ fastPath }) {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [drivername, setdrivername] = useState(null);
  const [vehiclenumber, setvehiclenumber] = useState(null);

  ////
  const [change, setChange] = useState(0);

  // const [displayPrevPO, setDisplayPrevPO] = useState(false);
  // const [previousPOList, setPreviousPOList] = useState([]);
  const fetchItems = useFetchItem();

  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const { Panel } = Collapse;
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR014") {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  // console.log( "tabStore data", tabStore)
  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  // render
  const [countRender, setcountRender] = useState(0);
  const [dcNumber, setDcNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const itemSearch = useRef(null);
  const btnRef = useRef(null);

  const [box, setBox] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [purchaseRate, setPurchaseRate] = useState(getSelectedItem?.purrate);
  const [selectedQty, setSelectedQty] = useState(0);
  const [itemInformation, setItemInformation] = useState();
  const [acctualQty, setAcctualQty] = useState(); // Qty as in pieces in
  const [freeBox, setFreeBox] = useState(0);
  const [freeQty, setFreeQty] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPer, setDiscountPer] = useState(0);
  const [batchNo, setBatchNo] = useState(null);
  const [expDate, setExpDate] = useState();
  const [manuDate, setManuDate] = useState();
  const [showTable, setShowTable] = useState(true);
  const [getInfoFromPO, setInfoFromPO] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [salesman, setsalesman] = useState({});
  const [surgeon, setsurgeon] = useState({});
  const [dcDate, setDcDate] = useState(auth?.state?.userdata?.workdate);
  const [statusOptions, setStatusOptions] = useState([]);
  const [status, setstatus] = useState(true);
  //scan table

  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);

  // -------------multiple selecton-----------------
  const [previousPOList, setPreviousPOList] = useState([]);
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [displayPP, setDisplayPP] = useState(false);
  const [returnData, setreturnData] = useState([]); //To Get the data from Selection Component of Miltiple Selection
  const [count, setCount] = useState(0);
  const [itemForSelection, setItemForSelection] = useState(null);
  const [opnenBatch, setopenBatch] = useState(false);
  //// here the batch after getting from the api cALL
  const [apidata, setApiData] = useState();

  //------------------end------------------------------------

  // scan table validation rules

  // Getting Page Details
  const [pageDetails, setPageDetails] = useState();
  // here is the logic for maxcred
  // here is the logic for the maxcreditamt
  const [maxcredTrue, setmaxcredTrue] = useState(false);
  const [alertShown, setAlertShown] = useState(true);

  const [validator, setValidator] = useState(0);
  const [totalQty, setTotalQty] = useState(0);

  ///////rendering/////
  const [modalcount, setModalCount] = useState(0);
  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
      }
    });
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    setDcDate(formattedDate);
  }, []);

  // useEffect(() => {
  //   if(customerData!=""){
  //     setModalCount((modalcount)=>modalcount+1)
  //   }
  // }, [customerData]);

  useEffect(() => {
    setlocation("");
    setsurgeon("");
    setsalesman("");
  }, [customerData]);

  useEffect(() => {
    if (getSelectedItem) {
      setPurchaseRate(Number(getSelectedItem?.purrate).toFixed(2));
    }
  }, [getSelectedItem]);
  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [rowData?.length]);
  let grossAmt =
    (Number(box) * 10 + Number(selectedQty)) *
      (Number(purchaseRate) - Number(discount)) || "00";

  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  useEffect(() => {
    setAcctualQty(
      Number(box) * Number(getSelectedItem?.conversionfactor) +
        Number(selectedQty)
    );
  }, [box, selectedQty]);

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);

    // Get Suppliers Purchase Orders where status == "C"
    if (e) {
      getPurchaseOrdersForSupplier(e?.supplierrecno);
    }
  }

  const getPurchaseOrdersForSupplier = async (supplierdescn) => {
    const res = await apiCallwithBaseUrl("POST", "/filterpurchaseorder/", {
      supplierrecno: supplierdescn,
      withitems: true,
      status: "P",
    });
    console.log("Resposne of Filter PO is", res);
    setPreviousPOList(res);
  };

  const addItemstoTable = () => {
    console.log("addItemstoTable", getSelectedItem);
    if (getSelectedItem.itemasbatch) {
      if (!batchNo) {
        alert("Batch Cannot be Null");
        return;
      }
    }

    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");

        setSelectedQty("");
        // setPurchaseRate("");

        setFreeBox(0);
        setFreeQty(0);
        setDiscount(0);
        setDiscountPer(0);
        setBatchNo("");
        setExpDate(null);
        setManuDate("");
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          selectedQty,
          box,
          freeBox,
          freeQty,
          batchNo,
          expDate,
          discount,
          discountPer,
          manuDate
        );
        itemSearch.current.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch.current.focus();
      }
    }
  };

  useEffect(() => {
    if (supplierdata?.igstappl) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    // var status = "P";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    // var shortguid;i

    amount = rowData.reduce((total, item) => total + Number(item.amount), 0);
    if (alertShown && amount && amount > customerData?.maxcreditamt) {
      alert("Credit Limit Exceeded");
      setAlertShown(false);
      //return;
    }
   
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item?.igstamt), 0);
    // shortguid = existingObject.shortguid ? (existingObject.shortguid) : (shortguid.getShortguid("DC"));
    // console.log("new/old shortguid is", shortguid)

    existingObject = {
      ...existingObject,
      cgstamt: Number(cgstamt).toFixed(2),
      igstamt: Number(igstamt).toFixed(2),
      sgstamt: Number(sgstamt).toFixed(2),
      taxableamount: Number(taxableamount).toFixed(2),
      amount: Number(amount).toFixed(2),
      shortguid: existingObject?.shortguid
        ? existingObject.shortguid
        : shortguid.getShortguid("DC"),
      items: rowData?.map((it) => {
        if (it.itemasbatch === 0) {
          return { ...it, itembatcharray: [] };
        } else {
          return it;
        }
      }),
      podate: getToday(),
      // status: "C",
      status: status ? statusOptions[1]?.value : statusOptions[0]?.value,
      trdate: existingObject?.trdate
        ? existingObject?.trdate
        : auth?.state?.workdate,
    };

    if (existingObject?.customerrecno && existingObject?.items?.length > 0) {
      useChangedRows.updateValue(false, existingObject);
    }


  }, [rowData, status]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data```
    var currentData = useChangedRows.getValue();
    console.log("Current", currentData.customerrecno);
    if (currentData.customerrecno) {
      if (!currentData?.shortguid) {
        useChangedRows.updateValue("shortguid", shortguid.getShortguid("DC"));
      }

      if (rowData?.length == 0) {
        var items = useChangedRows.getValue("items");
        if (items) {
          setRowData(items);
        }
      }
    }
  }, []);



  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("DC"));
    }
    setCustomerData(useChangedRows.getValue("customerrecno"));
    setDcNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));

    var items = useChangedRows.getValue("items");
    if (items) {
      setRowData(items);
    }
  }, [showFilterModal]);

  useEffect(() => {

    if (useChangedRows.getValue("trdate")) {
      setDcDate(useChangedRows.getValue("trdate"));
    } else {
      useChangedRows.updateValue("trdate", auth?.state?.workdate);
      setDcDate(auth?.state?.workdate);
    }
  }, [useChangedRows.getValue("trdate")]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setDcNumber("To Be Allotted");
      setDcDate(auth?.state?.userdata?.workdate);
      setStatusOptions([
        {
          label: "Save",
          value: pageDetails?.defaultStatus,
        },
        {
          label: "Proceed",
          value: pageDetails?.nextStatus,
        },
      ]);
      setstatus(true);
      setSelectedQty(" ");
      setPurchaseRate(" ");
      setlocation(null);
      setDatatoSupplierdata(null);
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("DC"));
      setModalCount((p) => p + 1);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);

  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        freeqty,
      } = rowData[i];

      console.log("Packaging", rowData[i]);
      if (
        (packingqty === "0" && packingextraqty === "0" && freeqty == "0") ||
        (packingqty == "" && packingextraqty == "" && freeqty == "") ||
        (packingqty === 0 && packingextraqty === "0" && freeqty == 0) ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0 && freeqty == null) ||
        (rowData[i]?.itemasbatch != 0 &&
          Number(rowData[i]?.qty ? rowData[i]?.qty : 0) +
            Number(rowData[i]?.freeqty ? rowData[i]?.freeqty : 0) !==
            rowData[i]?.itembatcharray?.length)
      ) {
        isButtonDisabled = true;
        console.log("isButtonDisabled:122", isButtonDisabled);
        break;
      } else if (
        ((packingqty === "0" && packingextraqty === "0" && freeqty == "0") ||
          (packingqty == "" && packingextraqty == "" && freeqty == "") ||
          (packingqty === 0 && packingextraqty === "0" && freeqty == 0) ||
          (packingqty === "0" && packingextraqty === 0) ||
          (packingqty === 0 && packingextraqty === 0 && freeqty == null) ||
          manufacturedate == null ||
          expdate == null ||
          itembatchno == null ||
          itembatchno == "") &&
        rowData[i]?.wantbatchno == 1
      ) {
        isButtonDisabled = true;

        break;
      } else if (!customerData) {
        isButtonDisabled = true;
        break;
      }
      console.log(
        "isButtonDisabled:",
        isButtonDisabled,
        packingqty,
        packingextraqty
      );
    }

    useChangedRows.updateValue(
      "validated",
      isButtonDisabled ? "false" : "true"
    );
  }, [rowData]);
  // useEffect(() => {
  //   if (getSelectedItem) {
  //     var checkIfItemExists = rowData?.filter(
  //       (item) => item.itemrecno == getSelectedItem.itemrecno
  //     );
  //     console.log("Get Selected Items", getSelectedItem);

  //     if (checkIfItemExists?.length > 0) {
  //       alert("Item Already Exists");
  //     } else {
  //       addItemBatchWiseToTable();
  //     }
  //   }
  // }, [getSelectedItem]);
  useEffect(() => {
    if (getSelectedItem && !getSelectedItem?.itemasbatch) {
      const checkIfItemExists = rowData?.filter(
        (item) => item.itemrecno === getSelectedItem.itemrecno
      );

      if (checkIfItemExists?.length > 0 && !apidata) {
        //alert("Item Already Exists");
        // Assuming handleRemoveBatch correctly removes the previous item
        // handleRemoveBatch(checkIfItemExists[0].itemrecno, setRowData, rowData);
      }

      addItemBatchWiseToTable();
    }
  }, [getSelectedItem, apidata]);

  const addItemBatchWiseToTable = () => {
    let batchDetails = getSelectedItem?.batchdetails;
    for (let i = 0; i < batchDetails?.length; i++) {
      let itemrecno = getSelectedItem.itemrecno;
      let itemdescn = getSelectedItem.itemdescn;
      let igstrate = getSelectedItem.igstrate;
      let cgstrate = getSelectedItem.cgstrate;
      let sgstrate = getSelectedItem.sgstrate;
      let mrp = getSelectedItem.mrp;
      let conversionfactor = getSelectedItem?.conversionfactor;
      //{Pradeep task id -337, added packingextrarecno variable}

      let totalSelectedQuantity =
        batchDetails[i]?.selectedQty || batchDetails[i]?.selectedBoxQty
          ? Number(batchDetails[i].selectedQty) +
            Number(batchDetails[i].selectedBoxQty) *
              getSelectedItem.conversionfactor
          : 0;
      let totalFreeQuantity = batchDetails[i]?.selectedFreeQty
        ? Number(batchDetails[i]?.selectedFreeQty)
        : 0;
      let packingextrarecno = getSelectedItem?.packingextrarecno;

      let selectedBoxQty = convertToBoxAndPieces(
        totalSelectedQuantity,
        conversionfactor,
        packingextrarecno //{Pradeep task id -337, added packingextrarecno variable}
      )[0];

      let selectedPcsQty = convertToBoxAndPieces(
        totalSelectedQuantity,
        conversionfactor,
        packingextrarecno //{Pradeep task id -337, adde packingextrarecno variable}
      )[1];

      let selectedFreeBoxQty = convertToBoxAndPieces(
        totalFreeQuantity,
        conversionfactor
      )[0];
      let selectedFreePcsQty = convertToBoxAndPieces(
        totalFreeQuantity,
        conversionfactor
      )[1];
      let batchNo = batchDetails[i].itembatchno;
      let itemSerialNo = batchDetails[i].serialno;
      let salerate = batchDetails[i].salerate;
      let expdate = batchDetails[i].expdate;
      let manudate = batchDetails[i].manudate;

      if (totalSelectedQuantity > 0 || totalFreeQuantity > 0) {
        handleAdd(
          getSelectedItem,
          setRowData,
          igstrate > 0 ? true : false,
          setSelectedItem,
          shortguid,
          salerate,
          selectedPcsQty,
          selectedBoxQty,
          selectedFreeBoxQty,
          selectedFreePcsQty,
          batchNo,
          expdate,
          0,
          0,
          manudate
        );
      }
      // }
    }
  };

  //----------multiple selection item start------------------------------------------------

  const options = [
    {
      id: 1,
      optionName: "Sale Order",
      apiurl: "filtersaleorder",
      customerrecno: customerData?.customerrecno,
      doctype: "SO",
    },
    {
      id: 1,
      optionName: "Sale Invoice",
      apiurl: "filtersalebill",
      customerrecno: customerData?.customerrecno,
      doctype: "SI",
    },
  ];

  //-----------------------------end-----------------------------
  //// end///////
  /// logic for itembatchselection////
  const FetchingBtach = async () => {
    //console.log("tradyaT11", customerData?.customerrecno);
    var url = getdomaincustomeritemlist;
    var body = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.domainuserrecno,
      userrolerecno: auth.state.userdata.domainuserrecno,
      noofrecords: 20,
      forflag: "S",
      customerrecno: customerData?.customerrecno,
      batchdetails: true,
      details: true,
      itemrecno: itemForSelection?.itemrecno,
    };
    const resp = await axios
      .post(url, body)
      .then((resp) => {
        setApiData(
          resp?.data?.Message?.filter(
            (item) => item.itemrecno == itemForSelection?.itemrecno
          )[0]
        );
      })
      .catch((err) => {
        console.log(err);
      });

    // setItemForSelection(resp?.data);
    //let x=resp?.data?.Message?.filter((item)=>item.itemrecno==itemForSelection?.itemrecno)
  };

  // useEffect will call FetchingBtach only when opnenBatch changes
  useEffect(() => {
    FetchingBtach();
  }, [opnenBatch == true]);

  return (
    <div>
      <Modal
        onOk={() => {
          setopenBatch(false);
          setItemForSelection(null);
          // Here we need to send back the Selection of Batch Details to the parent
          // if (returnValue) {
          //   returnValue={setSelectedItem}
          // }
          //handleRemove(itemForSelection,setRowData,rowData)
        }}
        onCancel={() => {
          setItemForSelection(null);
          setopenBatch(false);
        }}
        open={opnenBatch}
        width={1200}
      >
        <ItemSelectionModal
          item={apidata}
          //forFlag={"S"}
          pageDetails={pageDetails}
          // selectedItemBatchDetails={getSelectedItemBatchDetails}
          // setterFunction={setSelectedItemBatchDetails}
          returnValue={setSelectedItem}
          forPage={"S"}
        />
      </Modal>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "message",
            document.getElementById("message").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Cust</p>
          <Input
            id={"message"}
            placeholder="Enter Message for Cust"
            defaultValue={useChangedRows.getValue("message")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      <Modal
        open={displayPP}
        onCancel={() => {
          setDisplayPP(!displayPP);
        }}
        onOk={() => {
          setDisplayPP(!displayPP);
        }}
      >
        <div>
          Select Items from Pending Purchase Orders
          <Collapse
            collapsible="header"
            defaultActiveKey={["0"]}
            style={{ height: 300, overflowX: "scroll" }}
          >
            {previousPOList?.Message?.map((item, index) => {
              return (
                <Panel
                  header={`Purchase Order : ${item?.ponumber}`}
                  key={index}
                >
                  <List
                    size="small"
                    itemLayout="horizontal"
                    bordered
                    dataSource={item?.items}
                    renderItem={(it, ind) => {
                      it.selectedQty = it?.selectedQty
                        ? it.selectedQty
                        : it?.qty;
                      return (
                        <List.Item>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {it?.itemdescn} <br></br>Quantity : {it?.qty}
                            </div>
                            <input
                              className="tr001Input"
                              style={{ width: 80 }}
                              type={"number"}
                              defaultValue={it?.selectedQty}
                              onChange={(e) => {
                                it.selectedQty = e.target.value;
                              }}
                            ></input>
                            <Button
                              icon={<FcAddDatabase />}
                              onClick={() => {
                                console.log("Changed Object is ", it);
                                setInfoFromPO((p) => [...p, it]);
                              }}
                            ></Button>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={countRender}
      >
        <SearchFilterByBillNo
          fastpath="RP107"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>

      {/* ===========modal multi select================ */}
      <Modal
        key={count}
        width={620}
        open={displayPrevPO}
        onCancel={() => {
          setDisplayPrevPO(!displayPrevPO);
          setCount((p) => p + 1);
        }}
        onOk={() => {
          let newArray = returnData?.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor),
              pageDetails?.wantpackingextra
            );

            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              var existingItem = rowData.filter(
                (it) => it && it.itemrecno === item.itemrecno
              );

              if (existingItem.length === 0) {
                // Add item to the list
                handleAdd(
                  item,
                  setRowData,
                  item?.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item?.salerate ? item.salerate : item?.rate,
                  pcsBox[1],
                  pcsBox[0],
                  0,
                  item.freeQuantity,
                  null,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  item?.amount > 0 ? item.amount : 0,
                  item?.taxableamount > 0 ? item.taxableamount : 0
                );
                setreturnData([]);
              } else {
                // var totalBoxQty = existingItem.reduce((acc, el) => {
                //   console.log("Item Existing Array is ", item, el)

                //   (acc += Number(el?.packingextraqty))
                // }, 0) +  Number(pcsBox[0])
                // var totalPcsQty = existingItem.reduce((acc, el) => (acc += Number(el?.packingqty)), 0) +  Number(pcsBox[1])

                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item?.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item?.salerate ? item.salerate : item?.rate,
                    pcsBox[1],
                    pcsBox[0],
                    0,
                    item.freeQuantity,
                    null,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    item?.amount > 0 ? item.amount : 0,
                    item?.taxableamount > 0 ? item.taxableamount : 0
                  );
                  setreturnData([]);
                }, 200);
              }
            }
          });
          setDisplayPrevPO(!displayPrevPO);
          setCount((count) => count + 1);
        }}
      >
        <MultipleSelectionItem
          options={options}
          selectedItemsArray={returnData}
          //added forflag for fetching data in previous doc
          forFlag={"dc"}
          returnValue={(e) => {
            setreturnData(e);
          }}
          todate={dcDate}
        />
      </Modal>
      {/* ===========modal multi select end================ */}

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div>
            <form
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "40px", // Adjust the column gap as needed
                rowGap: "15px",
                marginTop: "5px",
              }}
            >
              {/* Pradeep task id -398 Shifted Print Dc checkbox in top of the right screen */}

              {/* Select Supplier */}

              {/* <div style={{ zIndex:10 }}>
                <SaveDropdown
                  displayLabel="Domain Locations"
                  alignment="column"
                  apiurl={getDomainLocationUrl}
                  apikey="locationrecno"
                  selectionLabel="descn"
                  selectionValue="recno"
                  body={customerData}
                />
              </div> */}
              {/* <CustomerLocationList setlocation={setlocation} defaultSelection={location} /> */}
              <div
                style={{
                  zIndex: 11,
                  position: "relative",
                  pointerEvents:
                    rowData && rowData.length > 0 ? "none" : "auto",
                  opacity: rowData && rowData.length > 0 ? 0.5 : 1,
                  marginTop: "10px",
                }}
                onKeyDown={enterPress}
              >
                <SaveDropdown
                  defaultSelected={customerData}
                  disabled={rowData && rowData.length > 0 ? 1 : 0}
                  displayLabel="Cust"
                  placeholder="Cust"
                  //{Pradeep task id-398 }/// added isrequired property
                  isrequired
                  alignment="column"
                  apikey="customerrecno"
                  apiurl={getDomaincustomerlist}
                  selectionLabel="customerdescn"
                  selectionValue="customerrecno"
                  returnValue={(event) => setCustomerData(event)}
                  key={modalcount}
                />
                <p>{customerData ? customerData?.remarks : ""}</p>
              </div>
              <div
                style={{ zIndex: 12, position: "relative", marginTop: "10px" }}
                onKeyDown={enterPress}
              >
                {/* <SaveDropdown
                  displayLabel="Customer Location"
                  alignment="column"
                  apiurl="domaincustomerlocationlist"
                  apikey="locationrecno"
                  selectionLabel="descn"
                  selectionValue="recno"
                  body={customerData}
                /> */}
                <SaveDropdown
                  //rishi - 239
                  //after editing dc the location is not coming after this change it is coming
                  //{Pradeep task id -dc}
                  defaultSelected={location}
                  displayLabel="Cust Locn"
                  placeholder="Cust Locn"
                  alignment="column"
                  apiurl="domaincustomerlocationlist"
                  apikey="customerlocationrecno"
                  selectionLabel="descn"
                  selectionValue="customerlocationrecno"
                  returnValue={() => setlocation()}
                  body={{ customerrecno: customerData?.customerrecno }}
                  key={modalcount}
                />
              </div>
              {/* Surgeon And Salesman DropDown Commented for Oneip If need it will come from Backend additional details panel */}
              {/* <div>
                <SaveDropdown
                  displayLabel="Salesman"
                  //{Pradeep task id -dc}
                  defaultSelected={salesman}
                  alignment="column"
                  //apiurl="getdomaincustomersalesman"
                  apiurl={getDomainCustomerSalesman}
                  apikey="salesmanrecno"
                  selectionLabel="customerdescn"
                  selectionValue="customerrecno"
                  //{Pradeep task id -dc}
                  returnValue={() => setsalesman()}
                  body={{ customerrecno: customerData?.customerrecno }}
                  key={modalcount}
                />
              </div>
              <div>
                <SaveDropdown
                  displayLabel="Surgeon"
                  //{Pradeep task id -dc}
                  defaultSelected={surgeon}
                  alignment="column"
                  apiurl="getdomaincustomerrefby"
                  apikey="refbyrecno"
                  selectionLabel="refbydescn"
                  selectionValue="refbyrecno"
                  //{Pradeep task id -dc}
                  returnValue={() => setsurgeon()}
                  body={{ customerrecno: customerData?.customerrecno }}
                  key={modalcount}
                />
              </div> */}
              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <div style={{ position: "relative" }}>
                  <label
                    // htmlFor={id}
                    className={`label ${"focused"}`}
                    style={{
                      zIndex: 1000,
                      whiteSpace: "nowrap",
                      wordWrap: "unset",
                      position: "absolute",
                      top: "-19px",
                      left: "12px",
                      //backgroundColor: "white",
                      padding: "0 4px",
                      fontSize: "12px",
                      //color: "black",
                      pointerEvents: "none",
                      transform: "translateY(0)",
                      transition:
                        "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                    }}
                  >
                    DC No.
                  </label>
                  <input
                    style={{ ...poNumberStyle, marginTop: "0px" }}
                    placeholder={dcNumber ? dcNumber : "To Be Allotted"}
                    disabled
                  />
                </div>
              </div>
              {/* =============================multiple selection=============================================== */}

              {/* =============================multiple selection end=============================================== */}

              <div
                style={{
                  float: "left",
                  zIndex: 3,
                  position: "relative",
                  marginLeft: -12,
                  marginTop: "10px",
                  ...(rowData && rowData.length > 0
                    ? { pointerEvents: "none", opacity: 0.5 }
                    : {}),
                }}
                onKeyDown={enterPress}
              >
                <SaveDatePicker
                  displayLabel="DC Date"
                  defaultValue={dcDate}
                  // disabled={fastPath=="TR014"?true:false}
                  disabled={rowData && rowData.length > 0 ? 1 : 0}
                  apiKey="trdate"
                  id={"tr014-dc-dcdate"}
                  dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                  postAlertMsg={`DC Date cannot be greater than Today's Date or less than Opening date`}
                  dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New Date Validation
                  returnValue={(e) => {
                    setDcDate(e);
                    //let tempCheck = document.getElementById("tr014-dc-dcdate");
                    // if (tempCheck.value === "") {
                    //   document.getElementById("tr014-dc-dcdate").focus();
                    //   return true;
                    // }
                    // return ;
                  }}
                  key={modalcount}
                />
              </div>

              {/* Status */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onKeyDown={enterPress}
              >
                <div style={{ position: "relative" }}>
                  {/* <label
                    className={`label ${"focused"}`}
                    style={{
                      zIndex: 1000,
                      whiteSpace: "nowrap",
                      wordWrap: "unset",
                      position: "absolute",
                      top: "-12px",
                      left: "12px",
                      //backgroundColor: "white",
                      padding: "0 4px",
                      fontSize: "12px",
                      color: "black",
                      pointerEvents: "none",
                      transform: "translateY(0)",
                      transition:
                        "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                    }}
                  >
                    Status
                  </label>
                  <div
                    onKeyDown={enterPress}
                    className="saveproceed"
                    style={{
                      padding: "8.7px",
                      marginTop: "4px",
                    }}
                  >
                    <SaveRadioBtn
                      apikey={"status"}
                      key={modalcount}
                      // defaultValue={pageDetails?.defaultStatus}
                      options={statusOptions}
                    />
                  </div> */}
                  <SaveCheckbox
                    displayLabel={"Proceed"}
                    defaultValue={status}
                    editable
                    apikey={"status"}
                    returnValue={(e) => setstatus(e)}
                  />
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <AdditionalDetails />
              </div>

              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <SaveTextInput
                  id="tr014-drivername"
                  pageIndex={pageIndex}
                  placeholder="Driver Name"
                  style={{ width: "100%" }}
                  apikey="drivername"
                  change={true}
                  displayLabel="Driver Name"
                  // returnValue={() => setdrivername()}
                  // onChange={() => setdrivername()}
                  ///{ Pradeep task id-dc }} to clear driver name input field
                  key={modalcount}
                />
              </div>

              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <SaveTextInput
                  id="tr014-vehicleno"
                  placeholder="Vehicle No."
                  pageIndex={pageIndex}
                  style={{ width: "100%" }}
                  apikey="vehicleno"
                  // apiKey="vehicleno"
                  change={true}
                  ///{Pradeep task id-dc}  to clear Vehicle Number input field
                  key={modalcount}
                  displayLabel="Vehicle No."
                  // returnValue={() => setvehiclenumber()}
                />
              </div>
              {/* amol-temp - changed in style */}
              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <Button
                  className="btn"
                  //style={{ ...buttonStyle }}
                  onClick={() => {
                    setDisplayPrevPO(!displayPrevPO);
                    setCount((count) => count + 1);
                  }}
                >
                  Previous Documents
                </Button>
              </div>
              {/* amol-temp - changed in style */}
              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <Button
                  //style={{ ...buttonStyle }}
                  className="btn"
                  type="default"
                  onClick={() => {
                    setshowFilterModal(true);
                    setcountRender((p) => p + 1);
                  }}
                >
                  {/* ///{ Start   Pradeep task id-dc  change the button name to search billno to search Dcno caption changed start } */}
                  Search DC No.
                </Button>
              </div>
              {/* amol-temp - changed in style and removed type="dashed" */}
              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <Button
                  className="btn"
                  //style={{ ...buttonStyle }}
                  onClick={(e) => setDisplayMessages(!displayMessages)}
                >
                  Add / View Messages
                </Button>
              </div>
              {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
              {/* =============================//*Edit Transaction*\\=================================== */}

              {/* <Button
                  style={{ float: "left", marginTop: "5%", marginLeft: "1%" }}
                  type="dashed"
                  onClick={() => setDisplayPrevPO(!displayPrevPO)}
                >
                  Select Invoice
                </Button> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onKeyDown={enterPress}
              >
                <SaveCheckbox
                  apikey={"isPrint"}
                  displayLabel="Print DC"
                  editable
                  key={modalcount} /// clear after save butoon date 27jun 2023
                />
              </div>
            </form>
          </div>
        </Panel>

        <Panel header="Items Details " key="3">
          <Space size="large">
            <div style={{ position: "relative", zIndex: 2 }}>
              {/* <ItemSearch
                itemSearchRef={itemSearch}
                forFlag={"S"}
                batchdetails={true}
                returnValue={setSelectedItem}
                supplierdata={customerData?.customerrecno}
                dropdown
                defaultSelectedItem={getSelectedItem}
                selection
                forPage={"DC"}
                barcodeReturnValue={(e) => {
                  // Write a function to add to existing Items and check only if it has serial number
                  if (e?.itemasbatch == 1) {
                    fetchItems
                      .addBarcodeScannedItemToTable(
                        rowData,
                        e,
                        "DCF",
                        igstappl,
                        pageDetails
                      )
                      .then((res) => {
                        console.log("New ROws Data", res);
                        setRowData(res);
                        setChange(change + 1);
                      })
                      .catch((err) => alert(err));
                  } else {
                    setSelectedItem(e);
                  }
                }}
              /> */}

              <ItemSearch
                itemSearchRef={itemSearch}
                forFlag={"S"}
                batchdetails={true}
                returnValue={setSelectedItem}
                supplierdata={customerData?.customerrecno}
                dropdown
                defaultSelectedItem={getSelectedItem}
                selection
                forPage={"DC"}
                pageDetails={pageDetails}
                barcodeReturnValue={(e) => {
                  const bill = e?.itembatcharrayfound[0];
                  const sidate = bill?.inwarddate;
                  const dcdate = bill?.dcbilldate;

                  console.log("itemsearch e", e);

                  // Check the conditions for serial number and stock availability
                  if (e?.serialnofound === false) {
                    notification.error({
                      message: `Error`,
                      duration: 1,
                      description: `Serial number not exist in system`,
                      style: { background: "#efa7a7" },
                    });
                    return;
                  } else if (
                    e?.serialnofound === true &&
                    e?.itembatcharrayfound?.length === 0
                  ) {
                    notification.error({
                      message: `Error`,
                      duration: 1,
                      description: `Stock not available`,
                      style: { background: "#efa7a7" },
                    });
                    return;
                  }

                  if (bill) {
                    if (sidate && sidate > Number(dcDate)) {
                      notification.error({
                        message: `Error`,
                        duration: 1,
                        description: `DC date less than PI date`,
                        style: { background: "#efa7a7" },
                      });
                    } else {
                      // Proceed with the existing logic
                      if (e?.itemasbatch == 1) {
                        fetchItems
                          .addBarcodeScannedItemToTable(
                            rowData,
                            e,
                            "DCF",
                            igstappl,
                            pageDetails
                          )
                          .then((res) => {
                           
                            setRowData(res);
                            setChange(change + 1);
                          })
                          .catch((err) => {
                            notification.error({
                              message: `Error`,
                              description: err.toString(),
                              style: { background: "#efa7a7" },
                            });
                          });
                      } else {
                        setSelectedItem(e);
                      }
                    }
                  } else {
                    notification.error({
                      message: `Error`,
                      duration: 1,
                      description: `Stock Not Available`,
                      style: { background: "#efa7a7" },
                    });
                  }
                }}
              />
            </div>
            <div>
              {getSelectedItem?.wantbatchno == 0 &&
              getSelectedItem?.itemasbatch == 0 &&
              getSelectedItem?.isservice == 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <p style={{ marginTop: "20px", padding: "5px" }}>
                    Available Stock:{getSelectedItem?.availablestock}
                  </p>
                  <div onKeyDown={enterPress}>
                    <p>
                      Pieces<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      ref={piecesRef}
                      style={{ ...inputStyles }}
                      // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                      value={selectedQty}
                      type="number"
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setSelectedQty(value);
                        }
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Button
                      style={{ ...btnStyles }}
                      className="btn item-search-btn"
                      icon={<IoAperture size="1.2rem" />}
                      size="large"
                      onClick={() => {
                        addItemstoTable();
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
            {getSelectedItem?.itemasbatch ? (
              <div style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                {getSelectedItem?.packingextrarecno > 0 &&
                pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress}>
                    <p>
                      Box <span style={{ color: "red" }}>*</span>
                      {getSelectedItem?.conversionfactor &&
                        +getSelectedItem?.conversionfactor}
                    </p>
                    <input
                      //  disabled={getSelectedItem?.packingextrarecno>0? false:true}
                      ref={boxRef}
                      style={{ ...inputStyles }}
                      value={box}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setBox(value);
                        }
                      }}
                      type="number"
                    />
                  </div>
                ) : null}
                <div onKeyDown={enterPress}>
                  <p>
                    Pieces<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    ref={piecesRef}
                    style={{ ...inputStyles }}
                    // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                    value={selectedQty}
                    type="number"
                    onChange={(e) => {
                      const regex = /^[0-9\b]+$/;
                      const value = e.target.value;
                      if (value === "" || regex.test(value)) {
                        setSelectedQty(value);
                      }
                    }}
                  />
                </div>

                {pageDetails?.wantfreeqty ? (
                  <>
                    {getSelectedItem?.packingextrarecno > 0 ? (
                      <div onKeyDown={enterPress}>
                        <p>Free Boxes</p>
                        <input
                          ref={piecesRef}
                          style={{ ...inputStyles }}
                          // disabled={getSelectedItem?.packingextrarecno>0? false:true}
                          value={freeBox}
                          type="number"
                          onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            const value = e.target.value;
                            if (value === "" || regex.test(value)) {
                              setFreeBox(value);
                            }
                          }}
                        />
                      </div>
                    ) : null}
                    <div onKeyDown={enterPress}>
                      <p>Free Pieces</p>
                      <input
                        ref={piecesRef}
                        style={{ ...inputStyles }}
                        // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                        value={freeQty}
                        type="number"
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          const value = e.target.value;
                          if (value === "" || regex.test(value)) {
                            setFreeQty(value);
                          }
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {getSelectedItem?.itemasbatch ? (
                  <>
                    {" "}
                    <div onKeyDown={enterPress}>
                      <p>
                        Batch No.<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        ref={piecesRef}
                        style={{
                          ...inputStyles,
                          width: "160px",
                          textAlign: "left",
                        }}
                        value={batchNo}
                        onChange={(e) => setBatchNo(e.target.value)}
                        onBlur={(e) => {
                          /// batch validation if itemsbatch is true or false if batch is true it should not asked for batch again
                          if (getSelectedItem?.wantbatchno) {
                            console.log("Batch No", getSelectedItem);
                            if (!e.target.value) {
                              alert("Batch Cannot Be Empty");
                            }
                            return;
                          }
                        }}
                      />
                    </div>
                    {pageDetails?.wantmanudate > 0 ? (
                      <div onKeyDown={enterPress}>
                        <p>
                          Mfg. Date <span style={{ color: "red" }}>*</span>
                        </p>
                        <CallbackDateInput
                          defaultValue={
                            getSelectedItem?.wantbatchno == 0 &&
                            getSelectedItem?.manudatecompulsory == 0
                              ? auth?.state?.userdata?.workdate
                              : manuDate
                          }
                          id={"tr002-manufacture-date-callback"}
                          // displayLabel={"Expiry Date"}
                          apiKey={"manudate"}
                          returnValue={(e) => {
                            console.log("!manuDate", manuDate, e);
                            if (!manuDate && !e) {
                              {
                                /*Kaushik : Added !e to check if response is coming */
                              }

                              setManuDate(auth?.state?.userdata?.workdate);
                            } else {
                              setManuDate(e);
                            }
                            console.log(
                              "Expiry Date should be greater than Mfg. Date",
                              e
                            );
                            document
                              .getElementById("tr002-exp-date-callback")
                              .focus();
                          }}
                          initial
                          // dateLessthan={}
                          key={modalcount}
                        />
                      </div>
                    ) : null}
                    {pageDetails?.wantexpdate > 0 ? (
                      <div onKeyDown={enterPress}>
                        <p>
                          Expiry Date <span style={{ color: "red" }}>*</span>
                        </p>
                        <CallbackDateInput
                          defaultValue={
                            getSelectedItem?.wantbatchno == 0 &&
                            getSelectedItem?.expdatecompulsory == 0
                              ? "99991231"
                              : expDate
                          }
                          id={"tr002-exp-date-callback"}
                          // displayLabel={"Expiry Date"}
                          apiKey={"manudate"}
                          initial
                          dateGreaterthan={manuDate} //! If this is false, postAlertMsg will trigger
                          postAlertMsg="Expiry Date should not be lesser than Mfg. Date"
                          returnValue={(e) => {
                            if (e <= manuDate) {
                              document.getElementById(
                                "po-manufacture-date-callback"
                              ).style.color = "red";
                              setExpDate(0);
                            } else {
                              setExpDate(e);
                            }
                          }}
                          key={modalcount}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}

                <div>
                  <Button
                    //style={{ ...btnStyles }}
                    className="btn item-search-btn"
                    icon={<IoAperture size="1.2rem" />}
                    size="large"
                    onClick={() => {
                      addItemstoTable();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            ) : null}
          </Space>

          {/* Table for Selection  */}
          <div
            style={{ zIndex: 0, position: "relative", margin: "24px auto 0" }}
          >
            {rowData.length > 0 && (
              <Table
                scroll={{
                  y: 500,
                  x: 500,
                }}
                size="small"
                className="tr012-salesOrder-itemsTable"
                bordered
                pagination={false}
                columns={piColumns(
                  igstappl,
                  setRowData,
                  rowData,
                  scandata,
                  setScandata,
                  setOpenItem,
                  dcDate,
                  pageDetails,
                  change,
                  tabStore,
                  setItemForSelection,
                  setopenBatch
                )?.filter((item) => !item.hidden)}
                dataSource={rowData}
                onChange={(e) => {}}
                summary={(pageData) => {
                  let qty = 0;
                  let free = 0;
                  let packingextraqty = 0;
                  let packingqty = 0;
                  let freepackingextraqty = 0;
                  let freepackingqty = 0;

                  var existingObject = useChangedRows.getValue();
                  console.log("Existing Object is", existingObject);
                  pageData.forEach((item) => {
                    qty = Number(qty) + Number(item?.qty);
                    free = Number(free) + Number(item?.free);
                    packingextraqty =
                      Number(packingextraqty) + Number(item?.packingextraqty);
                    packingqty = Number(packingqty) + Number(item?.packingqty);
                    freepackingextraqty =
                      Number(freepackingextraqty) +
                      Number(item?.freepackingextraqty);
                    freepackingqty =
                      Number(freepackingqty) + Number(item?.freepackingqty);
                  });

                  return (
                    <Table.Summary fixed className="tr013-items-footer">
                      {!igstappl ? (
                        <Table.Summary.Row>
                          {/* Pradeep task id -398 added extra cell here */}
                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            {/* Pradeep task id-391  *added style for making Total as bold  */}
                            <p style={{ ...pStyles, textAlign: "left" }}>
                              Total{" "}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {/* <Table.Summary.Cell></Table.Summary.Cell>
                       <Table.Summary.Cell></Table.Summary.Cell>
                       <Table.Summary.Cell></Table.Summary.Cell> */}

                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              {packingextraqty ? packingextraqty : 0}
                            </Table.Summary.Cell>
                          ) : null}

                          <Table.Summary.Cell>
                            {packingqty ? packingqty : 0}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {qty ? qty : 0}
                          </Table.Summary.Cell>

                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>

                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>

                          <Table.Summary.Cell></Table.Summary.Cell>

                          {/* <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {existingObject?.taxableamount}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {existingObject?.sgstamt}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {existingObject?.cgstamt}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {existingObject?.amount}
                      </Table.Summary.Cell> */}
                        </Table.Summary.Row>
                      ) : (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1}>
                            {/* Pradeep task id-391  *added style for making Total as bold  */}
                            <p style={{ ...pStyles, textAlign: "left" }}>
                              Total :{" "}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {packingextraqty}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{packingqty}</Table.Summary.Cell>
                          <Table.Summary.Cell>{qty}</Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {freepackingextraqty}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {freepackingqty}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{free}</Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {existingObject?.taxableamount}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {existingObject?.igstamt}
                          </Table.Summary.Cell>

                          <Table.Summary.Cell>
                            {existingObject?.amount}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    </Table.Summary>
                  );
                }}
              />
            )}
          </div>
          {/* Pradeep task id -398  */}
        </Panel>
      </Collapse>
      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          forFlag="S"
          supplierrecno={customerData?.customerrecno}
          pageDetails={pageDetails}
          pageIndex={pageIndex}
        />
      )}
    </div>
  );
}

export default DeliveryChallan;
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "10px",
  paddingTop: "10px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "80px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "80px",
  borderRadius: "8px",
  marginTop: "6px",
};

// amol-temp - change the values
const poNumberStyle = {
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  height: "39px",
};
{
  /* Pradeep task id-391  *added pStyles variable*/
}
const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

// amol-temp - added buttonStyle
const buttonStyle = {
  marginTop: "7px",
  textAlign: "right",
  border: "1px solid #dfdfdf",
  height: "36px",
};
