import React, { useContext, useState, useEffect } from "react";

import LogsMainScreen from "../Screens/Logs/LogMainScreen/LogsMainScreen";

import CreateCounterBill from "../Screens/CounterOperations/CreateCounterBill";
import DomainSupplierItemList from "../Screens/DomainSupplierItemList";
import UserPermission from "../Screens/UserPermission/UserPermission";
import PurchaseOrder from "../Screens/PurchaseOrder/PurchaseOrder";
import DomainPara from "../Screens/DomainParam/DomainPara";
import DomainLocation from "../Screens/DomainLocation/DomainLocation";
import ItemReceipt from "../Screens/TR 29/ItemReceipt";
import DomainItemAccountCategory from "../Screens/DomainItemCategory/DomainItemAccountCategory";
import DomainSale from "../Screens/DomailSale/DomainSale";
import TenantItemMainScreen from "../Screens/TenantItem/TenantItemMainScreen";
import AddSurveyQuestions from "../Screens/Survey/AddSurveyQuestions";
import DomainLicense from "../Screens/DomainLicenses/DomainLicenses";
import ExecuteComponentTesting from "../CommonControllers/Components/ExecuteComponents/ExecuteComponentTesting";
import AssignSurveyMainScreen from "../Screens/AssignSurvey/AssignSurveyMainScreen";
import ItemIssue from "../Screens/ItemIssue/ItemIssue";
import SurveyView from "../Screens/Survey/ViewSurvey/SurveyView";
import PDCEntry from "../Screens/Accounts/Transactions/PDCEntry";
import ChequeBounceEntry from "../Screens/Accounts/Transactions/ChequeBounceEntry";
import AddSingleSurveyQuestion from "../Screens/Survey/AddSingleSurveyQuestion";
import StockVerificationMainScreen from "../Screens/StockVerification/StockVerificationMainScreen";
import TestTables from "../CommonControllers/Components/Tables/TestTables";
import CommonMaster from "../Screens/CommonMaster";
import CommonMasterwithDynamicModal from "../Screens/CommonMasterwithModal/CommonMasterwithDynalicModal";
import PurchaseInvoice from "../Screens/PurchaseInvoice/PurchaseInvoice";
import DepositePDCEntry from "../Screens/Accounts/Transactions/DepositePDCEntry";

import SalesInvoiceRegister from "../Screens/SalesInvoiceRegister/SalesInvoiceRegister";

import Test from "../Screens/Survey/Test";
import Client from "../Screens/CommonComponentMaster/EditForm";
import ManufactureLicence from "../Screens/ManufactureLicense/ManufactureLicence";
import CommonMasterwithSelection from "../Screens/CommonMasterwithSelection";
import JVScreen from "../Screens/Accounting/JV/JVScreen";
import TransactionUI from "../Screens/Accounting/Transaction/TransactionUI";
import Table from "../CommonControllers/CustomTable/Table";
import SaleOrder from "../Screens/SalesOrder/SalesOrder";
import SalesInvoice from "../Screens/SalesInvoice/SalesInvoice";
import PurchaseReturn from "../Screens/PurchaseReturn/PurchaseReturn";
import SaleReturn from "../Screens/SaleReturn/SaleReturn";
import GrnPurchaseOrder from "../Screens/GRN/GrnPurchaseOrder";
import DebitNoteScreen from "../Screens/Accounting/DebitNote/DebitNoteScreen";
import CreditNoteScreen from "../Screens/Accounting/CreditNote/CreditNoteScreen";
import DebitNoteVoucherScreen from "../Screens/Accounting/DebitNoteVoucher/DebitNoteVoucherScreen";
import CreditNoteVoucherScreen from "../Screens/Accounting/CreditNoteVoucher/CreditNoteVoucherScreen";

import DeliveryChallan from "../Screens/DeliveryChallan/DeliveryChallan";
import { RcTransactionUI } from "../Screens/Accounting/ReceiptCorrection/RcTransactionUI";
import ItemReceipts from "../Screens/ItemReceipt/ItremReceipts";

// import DeliveryChallan from "../Screens/DeliveryChallan/DeliveryChallan";

import MaterialIssue from "../Screens/ItemIssue/MaterialIssue";
import ContraEntry from "../Screens/Accounting/ContraEntry/ContraEntry";
import Visualization from "../Screens/WMS/Visualization";
import WMS4 from "../Screens/WMS/WMS4";
import ToolETL from "../Screens/ETL/ToolETL";
import LinearChart from "../Screens/Graphs/Chart";
import Chart from "../Screens/Graphs/Chart";
import { MultipletableValidate } from "../Screens/ETL/MultipletableValidate";
import StockExchange from "../Screens/StockExchange/StockExchange";
import ItemExchange from "../Screens/ItemExchange/ItemExchange";
import SkuStocking from "../Screens/SkuStacking/SkuStocking";
import SaleItemWisereport from "../Screens/SaleItemwisereport/SaleItemWisereport";
import TestPage from "../Screens/TestPage";
import PasswordChanges from "../Screens/PasswordChange/Passwordchanges";
import PrintingFormat from "../Screens/PrintingFormat/ViewSurvey/SurveyView";
import AddBox from "../Screens/Printing/AddBox";
import DeliveryChallanOpening from "../Screens/OpeningTransactions/DeliveryChallanOpening";
import SalesInvoiceOpening from "../Screens/OpeningTransactions/SalesInvoiceOpening";
import PurchaseInvoiceOpening from "../Screens/OpeningTransactions/PurchaseInvoiceOpening";
import GRNWMS from "../Screens/WMS/GRN/GRNWMS";
import GDNWMS from "../Screens/WMS/GDN/GDNWMS";
import ReturnableDC from "../Screens/WMS/Returnable DC/ReturnableDC";
import WmsBilling from "../Screens/WMS/WmsBilling/WmsBilling";
import WMSLogsMainScreen from "../CommonControllers/WMS/Logs/WMSLogMainScreen/WMSLogsMainScreen";

import TaskValor from "../ThirdParty/TaskValor";
import MultipleSalesReturn from "../Screens/SaleReturn/MultipleSalesReturn/MultipleSalesReturn";
import ItemRecieptwithDC from "../Screens/ETL/ItemRecieptwithDC";
import PurchaseInvoiceETL from "../Screens/ETL/PurchaseInvoiceETL";
import SurveyEtl from "../Screens/ETL/SurveyEtl";
import MobileUserPermission from "../Screens/MobileUser/MobileUserPermission";
import LinkingPage from "../Screens/Linking/LinkingPage";
import DemandWithPo from "../Screens/DemandWithPo/DemandWithPO";
import UserPermissionNew from "../Screens/UserPermission/UserPermissionNew";
import UsserRolePermission from "../Screens/UserPermission/UsserRolePermission";
import{ETLopening} from "../Screens/ETLopening/ETLopening"
import VerifyJsonResponses from "../VerificationsTesting/VerifyJsonResponses";
import MasterScreen from "../Screens/RoutMasterCMDS/MasterScreen";
import { CreditPoints } from "../Screens/Survey/CreditPoints/CreditPoints";
import { IssuePoints } from "../Screens/Survey/IssuePoints/IssuePoints";
import ApiLogs from "../Screens/ApiLogs/ApiLogs";
import StockExchageNew from "../Screens/StockExchange/StockExchageNew";
import DownloadExcel from "../Screens/DowloadExcel/DownloadExcel";
import DeleteTransaction from "../Screens/DeleteTransaction/DeleteTransaction";
import MultiplePurchaseReturn from "../Screens/PurchaseReturn/MultipleSalesReturn/MultipleSalesReturn";
export default function Viewscreen({ name }) {
  const [item, setItem] = useState("Loading Please Wait");
  // const TabsStore= useContext(TabsStore)

  // const x = [];

  // useEffect(() => {
  //   getObject(name);
  // }, [name]);

  // function getObject(name) {
  //   x.filter((item) => {
  //     if (item.type.name === name) {
  //       setItem(item);
  //     }
  //   });
  // }

  return (
    <div>
      {/* smk 6-1-2023 commonmasterparams-codeauto*/}
      {name === "MS000" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS001" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS002" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS003" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS004" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS005" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS006" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS007" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS008" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS009" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS010" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS011" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS012" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS013" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS014" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS015" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS016" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS017" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* 17-1-2023 imagetype */}
      {name === "MS018" ? <null fastPath={name} /> : null}
      {name === "MS019" ? <null fastPath={name} /> : null}
      {/* 10-1-2023 location type */}
      {name === "MS020" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS021" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS022" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS023" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS024" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS025" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS026" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS027" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS028" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS029" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS030" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS031" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS032" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS033" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS034" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS035" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS036" ? <null fastPath={name} /> : null}
      {/* composition group which is removed so this fastpath is empty now */}
      {name === "MS037" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS038" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* composition duration */}
      {name === "MS039" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS040" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS041" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* smk 21-2-2023 tenant item group1 */}
      {name === "MS042" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* smk 21-2-2023 tenant item group2 */}
      {name === "MS043" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* smk 21-2-2023 tenant item group3 */}
      {name === "MS044" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS045" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* smk 21-2-2023 tenant item group4 */}
      {/* ITEM RELATED */}
      {name === "MS051" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS052" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS053" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS054" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS055" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS056" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS057" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS058" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS059" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS060" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS061" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS062" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS063" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS064" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS065" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS066" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS067" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS068" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* other */}
      {name === "MS081" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS082" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS083" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS084" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS085" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* partygroup1 */}
      {name === "MS086" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* partygroup2 */}
      {name === "MS087" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* partygroup3 */}
      {name === "MS088" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* partygroup4 */}
      {name === "MS089" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* partygroup5 */}
      {name === "MS090" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* etl type */}
      {name === "MS091" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS092" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS093" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS095" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS096" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS100" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* Domain supllier */}
      {/* Domain supllier */}
      {name === "MS101" ? <CommonMaster fastPath={name} /> : null}
      {name === "MS102" ? <CommonMaster fastPath={name} /> : null}
      {/* smk 2-2-2023 CommonConnectionList */}
      {name === "MS103" ? <CommonMaster fastPath={name} /> : null}
      {/* smk 2-2-2023 CommonConnectionList */}
      {name === "MS104" ? <CommonMaster fastPath={name} /> : null}
      {/* smk 2-2-2023 CommonConnectionList */}
      {/* smk 7-1-2023 supplieritem */}
      {name === "MS105" ? <CommonMaster fastPath={name} /> : null}
      {/**DomainSupplierItemList */}
      {/* smk 7-1-2023 customeritem */}
      {name === "MS106" ? <CommonMaster fastPath={name} /> : null}
      {/**DomainSupplierItemList */}
      {/* smk 7-1-2023 supplieritem */}
      {/* smk 7-1-2023 customeritem */}
      {name === "MS107" ? <CommonMaster fastPath={name} /> : null}
      {/**DomainSupplierItemList */}
      {/* smk 7-1-2023 salesmancustomeritem */}
      {name === "MS108" ? <CommonMaster fastPath={name} /> : null}
      {/* smk 2-2-2023 CommonConnectionList */}
      {name === "MS109" ? <CommonMaster fastPath={name} /> : null}
      {name === "MS110" ? <CommonMaster fastPath={name} /> : null}
      {/* DomainSupplierItemList DomainSalesmancustomer*/}
      {name === "MS094" ? <CommonMasterwithDynamicModal /> : null}
      {name === "MS111" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS112" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* domain licenses*/}
      {name === "MS113" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* domain images*/}
      {name === "MS114" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* domain logos*/}
      {name === "MS115" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* DomainLocation domain sub locations*/}
      {name === "MS116" ? (
        <CommonMaster fastPath={name} />
      ) : null}
      {/* domain item billing category*/}
      {name === "MS117" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* smk 20-2-2023 domain pos machines*/}
      {name === "MS118" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* domain supplier banks*/}
      {name === "MS119" ? (
        <CommonMaster fastPath={name} />
      ) : null}
      {/* {/<DomainItemAccountCategory /> : null/} */}
      {name === "MS120" ? <CommonMaster fastPath={name} /> : null}
      {/* smk 19-2-2023 billformat */}
      {name === "MS121" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* smk 16-2-2023 domaincustomerlocations */}
      {name === "MS122" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* smk 16-1-2023 accounting parameter */}
      {name === "MS123" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* barcode formula */}
      {name === "MS124" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* 18-2-2023 domainitem rate cards */}
      {name === "MS125" ? <CommonMaster fastPath={name} /> : null}
      {/* rate chart */}
      {name === "MS126" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* user role */}
      {/* {name === "MS127" ? <UserPermissionNew fastPath={name} /> : null}  */}
      {/* use wise menu */}
      {name === "MS127" ? <UsserRolePermission fastPath={name} /> : null}
      {name === "MS128" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* party licenses*/}
      {name === "MS129" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* domain users*/}
      {name === "MS130" ? <DomainPara fastPath={name} /> : null}
      {/* domain parameters*/}
      {name === "MS132" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS133" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* smk 21-2-2023 domainitemlocations*/}
      {name === "MS200" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* subaccounts */}
      {name === "MS131" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS134" ? <CommonMaster fastPath={name} /> : null}
      {name === "MS136" ? <DomainPara fastPath={name} /> : null}
      {name === "MS137" ? <CommonMaster fastPath={name} /> : null}
      {/* smk 21-2-2023 domain customer banks*/}
      {name === "MS201" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* majid 13-1-2023 tenantitem */}
      {name === "MS204" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* accounting schedule */}
      {name === "MS205" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* accounting costcenter */}
      {name === "MS206" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* accounting budget */}
      {name === "MS298" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* ManufactureLicence manufacture license */}
      {name === "MS299" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* manufacturer  */}
      {name === "MS300" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* marketeer  */}
      {name === "MS301" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS302" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS303" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS304" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS305" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS308" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS310" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* 13-3-2023 fieldtab update for other than report Manage Master Fields*/}
      {name === "MS898" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS899" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "MS900" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* general ledger  */}
      {name === "MS999" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/*  */}
      {name === "MS135" ? <CommonMaster fastPath={name} /> : null}
      {/* Survey Masters */}
      {name === "SV001" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* surveyer */}
      {name === "SV002" ? <AddSurveyQuestions fastPath={name} /> : null}
      {/*AddSurveyQuestions  */}
      {/* AddSurveyQuestions*/}
      {name === "SV003" ? <SurveyView fastPath={name} /> : null}
      {name === "MS306" ? <PrintingFormat fastPath={name} /> : null}
      {name === "MS307" ? <AddBox fastPath={name} /> : null}
      {/* {name === "SV004" ? <AssignSurveyMainScreen /> fastPath={name} : null} */}
      {name === "SV004" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {name === "SV005" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* assign survey */}
      {/* survey type  */}
      {name === "RP001" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* po register*/}
      {name === "RP002" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* pi register*/}
      {name === "RP003" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* grn register*/}
      {name === "RP004" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* purchase return register*/}
      {name === "RP011" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* sales order register*/}
      {/* {name === "RP012" ? <LogsMainScreen /> fastPath={name} : null} */}
      {name === "RP012" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* sales invoice register*/}
      {name === "RP013" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* dc register*/}
      {name === "RP014" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* sales return register*/}
      {name === "RP015" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* ref wise payables register*/}
      {name === "RP021" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* cashbook*/}
      {name === "RP022" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* scroll CashBook*/}
      {name === "RP023" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* balancing*/}
      {name === "RP024" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* glrectpayt*/}
      {name === "RP031" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* trial balance*/}
      {name === "RP032" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* balance sheet*/}
      {name === "RP033" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* pl*/}
      {name === "RP034" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* gl statement*/}
      {name === "RP035" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/*AccountStatement */}
      {name === "RP036" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* ref wise receivables register*/}
      {name === "RP037" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* chq bounce regisetr*/}
      {name === "RP038" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* pdc regisetr*/}
      {name === "RP039" ? <SalesInvoiceRegister fastPath={name} /> : null}
            {/* item regisetr*/}
            {name === "RP039A" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* item ledger*/}
      {name === "RP040" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* item statement*/}
      {name === "RP041" ? <null fastPath={name} /> : null}
      {/* gstr1*/}
      {name === "RP042" ? <null fastPath={name} /> : null}
      {/* gstr2*/}
      {name === "RP043" ? <null fastPath={name} /> : null}
      {/* gstr31b*/}
      {name === "RP044" ? <null fastPath={name} /> : null}
      {name === "RP045" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP046" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP047" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* Rp048 added date 04 july 2023*/}
      {name === "RP048" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP049" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* gstr31c*/}
      {name === "RP050" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* recon statement*/}
      {name === "RP051" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* demand register*/}
      {name === "RP052" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* agewise payables*/}
      {name === "RP053" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* agewise receivables*/}
      {name === "RP054" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP057" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP058" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP059" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/*Stock Exchange Report By Serial No. */}
      {name === "RP060" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP101" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP102" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP103" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP104" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP105" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP106" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP107" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP108" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP109" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP110" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP111" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {/* For Printing of Documents */}
      {name === "PR101" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR102" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR103" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR104" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR105" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR106" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR107" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR108" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR109" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "PR110" ? <LogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "RP114" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP115" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "RP116" ? (
        <LogsMainScreen dontEdit={false} fastPath={name} />
      ) : null}
      {name === "TR001" ? <PurchaseOrder fastPath={name} /> : null}
      {name === "TR002" ? <PurchaseInvoice fastPath={name} /> : null}
    
      {/* purchase bill*/}
      {name === "TR003" ? <GrnPurchaseOrder fastPath={name} /> : null}
      {name === "TR003A" ? <GrnPurchaseOrder fastPath={name} /> : null}
      {/* gen*/}
      {name === "TR004" ? <PurchaseReturn fastPath={name} /> : null}
      {/* back date */}
      {name === "TR004A" ? <PurchaseReturn fastPath={name} /> : null}
      {/* purchase return*/}
      {/* {name === "TR005" ? <DemandWithPo fastPath={name} /> : null} */}
      {name === "TR005" ? <CommonMaster fastPath={name} /> : null}
      {name === "TR006" ? <SkuStocking fastPath={name} /> : null}
      {/* demand to PO*/}
      {/* {name === "TR011" ? <CreateCounterBill fastPath={name} /> : null} */}{" "}
      {/*here data is cunter Bill  */}
      {name === "TR011" ? <SalesInvoice fastPath={name} /> : null}
      {name === "TR012" ? <SaleOrder fastPath={name} /> : null}
      {/* sale order*/}
      {name === "TR013" ? <SalesInvoice fastPath={name} /> : null}
      {/* back date  */}
      {name === "TR013A" ? <SalesInvoice fastPath={name} /> : null}
      {/* kaushik 20-2-2023 sale bill*/}
      {name === "TR014" ? <DeliveryChallan fastPath={name} /> : null}
      {name === "TR014A" ? <DeliveryChallan fastPath={name} /> : null}
      {/* delivery challan*/}
      {name === "TR015" ? <SaleReturn fastPath={name} /> : null}
      {/* back date  */}
      {name === "TR015A" ? <SaleReturn fastPath={name} /> : null}
      {/* rajendra 22-2-2023 sale return*/}
      {name === "TR056" ? <MultipleSalesReturn fastPath={name} /> : null}
      {name === "TR066" ? <MultiplePurchaseReturn fastPath={name} /> : null}
      {/* Multiple Sales Return */}
      {name === "TR021" ? (
        <TransactionUI vouchertype="RECEIPT" fastPath={name} />
      ) : null}
      {name === "TR121" ? (
        <RcTransactionUI vouchertype="RECEIPT" fastPath={name} />
      ) : null}
      {/* payment*/}
      {name === "TR022" ? (
        <TransactionUI vouchertype="PAYMENT" fastPath={name} />
      ) : null}
      {/* receipt*/}
      {name === "TR023" ? <JVScreen fastPath={name} /> : null}
      {/* jv*/}
      {name === "TR024" ? <ContraEntry fastPath={name} /> : null}
      {/* contra entry*/}
      {name === "TR025" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : null}
      {/* pdcentry*/}
      {name === "TR026" ? <ChequeBounceEntry fastPath={name} /> : null}
      {/* chequebunce*/}
      {name === "TR027" ? <CreditNoteScreen fastPath={name} /> : null}
      {/* credit note*/}
      {name === "TR028" ? <DebitNoteScreen fastPath={name} /> : null}
      {/* debit note*/}
      {name === "TR054" ? <CreditNoteVoucherScreen fastPath={name} /> : null}
      {/* credit note*/}
      {name === "TR055" ? <DebitNoteVoucherScreen fastPath={name} /> : null}
      {name === "TR057" ? <ItemRecieptwithDC fastPath={name} /> : null}
      {name === "TR058" ? <PurchaseInvoiceETL fastPath={name} /> : null}
      {name === "TR059" ? <LinkingPage fastPath={name} /> : null}
      {name === "TR029" ? <ItemReceipts fastPath={name} /> : null}
      {/* it was for single item ItemReceipt ETLopening */}
      {/* item receipt*/}
      {name === "TR030" ? <MaterialIssue fastPath={name} /> : null}
      {/* it was for single item ItemIssue */}
      {name === "TR061" ? <ETLopening fastPath={name} /> : null}
      {name === "TR031" ? (
        <StockVerificationMainScreen fastPath={name} />
      ) : null}
      {name === "TR033" ? <null /> : null}
      {/* item stock verification*/}
      {name === "TR051" ? <null /> : null}
      {/* recon entry*/}
      {name === "TR052" ? <null /> : null}
      {/* recon matching*/}
      {/* Deposite PDC Entry */}
      {name == "TR053" ? <DepositePDCEntry fastPath={name} /> : <null />}
      {/* Eway Bill */}
      {name === "TR016" ? <LogsMainScreen fastPath={name} /> : null}
      {/* {name === "TR035" ? <LogsMainScreen dontEdit fastPath={name} /> : null} */}
      {/* Componnets Testing and Other Testing */}
      {name === "TEST" ? <ExecuteComponentTesting fastPath={name} /> : null}
      {name === "TEST1" ? <ItemIssue fastPath={name} /> : <null />}
      {name === "TEST2" ? <TestTables fastPath={name} /> : <null />}
      {name === "TEST" ? <ExecuteComponentTesting fastPath={name} /> : null}
      {name === "TEST1" ? (
        <ExecuteComponentTesting fastPath={name} />
      ) : (
        <null />
      )}
      {name === "TEST2" ? <TestTables fastPath={name} /> : <null />}
      {name === "TEST1" ? (
        <ExecuteComponentTesting fastPath={name} />
      ) : (
        <null />
      )}
      {name === "TEST2" ? <TestTables fastPath={name} /> : <null />}
      {/* WMS project  */}
      {/* Screens */}
      {name === "WMS2" ? <GRNWMS fastPath={name} /> : <null />}
      {name === "WMS3" ? <ReturnableDC fastPath={name} /> : <null />}
      {name === "WMS4" ? <GDNWMS fastPath={name} /> : <null />}
      {/* WMS Billings */}
      {name === "WMS5" ? <WmsBilling fastPath={name} /> : null}
      {/* WMS GRN Log */}
      {name === "RP112" ? (
        <WMSLogsMainScreen dontEdit={false} fastPath={name} />
      ) : (
        <null />
      )}
      {/* WMS GDN Log */}
      {name === "RP113" ? (
        <WMSLogsMainScreen dontEdit={false} fastPath={name} />
      ) : (
        <null />
      )}
      {/* WMS Billing  Log */}
      {name === "RP119" ? (
        <WMSLogsMainScreen dontEdit={false} fastPath={name} />
      ) : (
        <null />
      )}
      {/* WMS Register - Area occupied Report */}
      {name === "RP117" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* WMS Register - Item Statement */}
      {name === "RP118" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* WMS- GRN Print */}
      {name === "PR112" ? <WMSLogsMainScreen dontEdit fastPath={name} /> : null}
      {/* WMS- GDN Print */}
      {name === "PR113" ? <WMSLogsMainScreen dontEdit fastPath={name} /> : null}
      {/* WMS- Billing Print */}
      {name === "PR119" ? <WMSLogsMainScreen dontEdit fastPath={name} /> : null}
      {name === "WMS1" ? <Visualization fastPath={name} /> : <null />}
      {name === "DB" ? <Chart fastPath={name} /> : <null />}
      {name === "DW" ? <WMS4 fastPath={name} /> : <null />}
      {/* ELT */}
      {name === "TR017" ? <ToolETL fastPath={name} /> : <null />}
      {name === "TR018" ? <MultipletableValidate fastPath={name} /> : <null />}
      {name === "FIND" ? (
        <CommonMasterwithDynamicModal fastPath={name} />
      ) : (
        <null />
      )}
      {name === "TR034" ? <ItemExchange fastPath={name} /> : null}
      {name === "TR035" ? <StockExchageNew fastPath={name} /> : null}
      {/* TESTING FOR ITEMWIASE REPORT */}
      {name === "RP016" ? <SaleItemWisereport fastPath={name} /> : null}
      {name === "RP006" ? <SaleItemWisereport fastPath={name} /> : null}
      {name === "TR999" ? <PasswordChanges fastPath={name} /> : null}
      {/* fastpath added as per suggestion */}
      {name === "RP007" ? <SaleItemWisereport fastPath={name} /> : null}
      {name === "RP017" ? <SaleItemWisereport fastPath={name} /> : null}
      {/* Opening transaction */}
      {name === "TR996" ? <DeliveryChallanOpening fastPath={name} /> : null}
      {name === "TR997" ? <SalesInvoiceOpening fastPath={name} /> : null}
      {name === "TR998" ? <PurchaseInvoiceOpening fastPath={name} /> : null}
      {/* ERR001 er002  pages added */}
      {name === "ER002" ? <ApiLogs fastPath={name} /> : null}
      {name === "ER001" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {/* ERR001 er002  pages added  DemandWithPo*/}
      {/* {name === "ER002" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "ER001" ? <SalesInvoiceRegister fastPath={name} /> : null} */}
      {name === "MS097" ? <MobileUserPermission fastPath={name} /> : null}


      {name === "CHK00" ? <VerifyJsonResponses fastPath={name} /> : null}

      

      {name === "TR994" ? <MasterScreen fastPath={name} /> : null}
      {name === "SV006" ? <CreditPoints fastPath={name} /> : null}

      {name === "SV007" ? <IssuePoints fastPath={name} /> : null}

      {/* excel download 'RP001A', 'RP002A', 'RP003A', 'RP004A', 'RP011A', 'RP012A', 'RP013A', 'RP014A' */}
      {name === "RP001A" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP002A" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP003A" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP004A" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP011A" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP012A" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP013A" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP014A" ? <SalesInvoiceRegister fastPath={name} /> : null}  

      {name === "RP117" ? <DownloadExcel fastPath={name} /> : null} 
 {/* stock transfer */}
      {name === "RP061" ? <SalesInvoiceRegister fastPath={name} /> : null}
      {name === "RP062" ? <SalesInvoiceRegister fastPath={name} /> : null}

      {name === "RP041" ? <SalesInvoiceRegister fastPath={name} /> : null}  
      {name === "RP042" ? <SalesInvoiceRegister fastPath={name} /> : null}  
      {name === "RP043" ? <SalesInvoiceRegister fastPath={name} /> : null}  
      {name === "RP044" ? <SalesInvoiceRegister fastPath={name} /> : null}  
      {name === "TR063" ? <DeleteTransaction fastPath={name} /> : null}  



    </div>
  );
}
