import React, { useContext, useEffect, useState } from "react";
import Log from "./Log";
import { Button, Tooltip } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { TabStore } from "../../../TabsStructure/TabsStore";
import { currentTabData } from "../../../CommonControllers/Variable";
import { isArray } from "../../../CommonControllers/AppFunction";
import noData from "../../../Assets/noData.png";


const logComponentFrame = {
  width: "fit-content",
  margin: "auto",
};

const LogComponent = ({ data, columns, itemsColumn, setshowFilterModal, showFilterModal, statusC, setstatusC, forFlag,dontEdit,pageDetails }) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const tabs = useContext(TabStore);
const [data1,setData1]=useState([]);

const[modified,setModified]=useState([])
 

console.log("DATA IS", data,pageDetails)


  const handleShowAllItems = () => {
    setShowAllItems((prev) => !prev);
  };
 useEffect(()=>{
  if (columns && isArray(columns)) {

    setModified(columns.filter((it)=>it.isview))
  }
 },[])

 
 

  return (
    <>
        {
          isArray(data) ? <div style={{ ...logComponentFrame }}>


    
          <div>
            <Tooltip
              placement="right"
              color="#1677ff"
              title={showAllItems ? "Collapse All" : "Expand All"}
            >
  
              <Button
                type="primary"
                size="large"
                shape="circle"
                onClick={handleShowAllItems}
                icon={<UnorderedListOutlined />}
              ></Button>
              <Button>Total Records : {data?.length} </Button>
            </Tooltip>
          </div>
          <div>
  
            {  data?.map((elem, i) => (
              <Log
                dontEdit={dontEdit}
                key={i}
                logData={elem}
                showAllItems={showAllItems}
                columns={modified}
                itemsColumn={itemsColumn}
                setshowFilterModal={setshowFilterModal}
                showFilterModal={showFilterModal}
                statusC={statusC}
                setstatusC={setstatusC}
                forFlag={forFlag}
                // setRowData={setRowData}
                pageDetails={pageDetails}
              />
            ))}
          </div>
        </div> :  <div className="noDataFound">
                        <h1>Oops! No Records Found</h1>
                        <img src={noData} />
                    </div>
        }
      
    </>
  );
};

export default LogComponent;
