import React, { useState, useEffect, useContext, useRef } from "react";

import {
  handleAdd,
  piColumns,
  handleRemove,
  handleRemovePrev,
} from "./DeliveryChallanFunc.js";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import {
  Button,
  Checkbox,
  Collapse,
  Input,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";

import { IoAperture } from "react-icons/io5";

import { Divider, List } from "antd";
import { FcAddDatabase } from "react-icons/fc";

import {
  apiCallwithBaseUrl,
  getfastpathapi,
} from "../../CommonControllers/ApiCalls.js";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo.js";

import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox.js";

import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker.js";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails.js";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput.js";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch.js";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable.js";
import { currentTabData } from "../../CommonControllers/Variable.js";
import {
  getDomainCustomerSalesman,
  getDomaincustomerlist,
} from "../../CommonControllers/Urls.js";
import {
  convertToBoxAndPieces,
  getToday,
} from "../../CommonControllers/AppFunction.js";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems.js";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid.js";
import { TabStore } from "../../TabsStructure/TabsStore.js";
import { AuthStore } from "../../Auth/AuthStore.js";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows.js";
import SaveDropDown from "../../CommonControllers/SaveComponents/SaveDropDown.js";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem.js";
function DeliveryChallanOpening() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [drivername, setdrivername] = useState(null);
  const [vehiclenumber, setvehiclenumber] = useState(null);

  console.log("drivername", drivername, " vehiclenumber", vehiclenumber);
  // const [displayPrevPO, setDisplayPrevPO] = useState(false);
  // const [previousPOList, setPreviousPOList] = useState([]);
  const fetchItems = useFetchItem();

  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const { Panel } = Collapse;
  const [pageIndex, setPageIndex] = useState();
  const [change, setChange] = useState(0);
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR996") {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  // console.log( "tabStore data", tabStore)
  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  // render
  const [countRender, setcountRender] = useState(0);
  const [dcNumber, setDcNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const itemSearch = useRef(null);
  const btnRef = useRef(null);

  const [box, setBox] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [purchaseRate, setPurchaseRate] = useState(getSelectedItem?.purrate);
  const [selectedQty, setSelectedQty] = useState(0);
  const [itemInformation, setItemInformation] = useState();
  const [acctualQty, setAcctualQty] = useState(); // Qty as in pieces in
  const [freeBox, setFreeBox] = useState(0);
  const [freeQty, setFreeQty] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPer, setDiscountPer] = useState(0);
  const [batchNo, setBatchNo] = useState(null);
  const [expDate, setExpDate] = useState();
  const [manuDate, setManuDate] = useState();
  const [showTable, setShowTable] = useState(true);
  const [getInfoFromPO, setInfoFromPO] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [salesman, setsalesman] = useState({});
  const [surgeon, setsurgeon] = useState({});
  const [dcDate, setDcDate] = useState(auth.state.userdata.openingbaldate);

  //scan table

  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);

  // -------------multiple selecton-----------------
  const [previousPOList, setPreviousPOList] = useState([]);
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [displayPP, setDisplayPP] = useState(false);
  const [returnData, setreturnData] = useState([]); //To Get the data from Selection Component of Miltiple Selection
  const [count, setCount] = useState(0);

  //------------------end------------------------------------

  // console.log("Scandata", scandata)
  // console.log("getSelectedItem",getSelectedItem)
  /// tr014 the  validation function

  // scan table validation rules

  // Getting Page Details
  const [pageDetails, setPageDetails] = useState();
  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
      }
    });
  }, []);

  const [validator, setValidator] = useState(0);
  const [totalQty, setTotalQty] = useState(0);

  console.log("setSelectedItem in DC page", getSelectedItem);
  console.log("customerData", customerData);
  console.log("tabStore", tabStore);
  ///////rendering/////
  const [modalcount, setModalCount] = useState(0);
  console.log("modalcount", modalcount);
  console.log("customerdata++++", customerData);

  // useEffect(() => {
  //   if(customerData!=""){
  //     setModalCount((modalcount)=>modalcount+1)
  //   }
  // }, [customerData]);

  useEffect(() => {
    setlocation("");
    setsurgeon("");
    setsalesman("");
  }, [customerData]);

  useEffect(() => {
    if (getSelectedItem) {
      setPurchaseRate(Number(getSelectedItem?.purrate).toFixed(2));
      console.log("setSelectedItem222", getSelectedItem);
    }
  }, [getSelectedItem]);
  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [rowData?.length, change]);
  let grossAmt =
    (Number(box) * 10 + Number(selectedQty)) *
      (Number(purchaseRate) - Number(discount)) || "00";

  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  const enterPress = (e) => {
    console.log("Enter Key is Preseed in Table");

    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
    }
  };

  useEffect(() => {
    console.log("getSelectedItem", getSelectedItem);
    setAcctualQty(
      Number(box) * Number(getSelectedItem?.conversionfactor) +
        Number(selectedQty)
    );
  }, [box, selectedQty]);

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);

    // Get Suppliers Purchase Orders where status == "C"
    if (e) {
      getPurchaseOrdersForSupplier(e?.supplierrecno);
    }
  }

  const getPurchaseOrdersForSupplier = async (supplierdescn) => {
    const res = await apiCallwithBaseUrl("POST", "/filterpurchaseorder/", {
      supplierrecno: supplierdescn,
      withitems: true,
      status: "P",
    });
    console.log("Resposne of Filter PO is", res);
    setPreviousPOList(res);
  };

  const addItemstoTable = () => {
    if (!getSelectedItem.itemasbatch) {
      if (!batchNo) {
        alert("Batch Cannot be Null");
        return;
      }
    }

    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      console.log("existingItem?.length", existingItem?.length);
      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");

        setSelectedQty("");
        // setPurchaseRate("");

        setFreeBox(0);
        setFreeQty(0);
        setDiscount(0);
        setDiscountPer(0);
        setBatchNo("");
        setExpDate(null);
        setManuDate("");
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          selectedQty,
          box,
          freeBox,
          freeQty,
          batchNo,
          expDate,
          discount,
          discountPer,
          manuDate
        );
        itemSearch.current.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch.current.focus();
      }
    }
  };

  useEffect(() => {
    if (supplierdata?.igstappl) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    var status = "P";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    // var shortguid;i

    amount = rowData?.reduce((total, item) => total + Number(item.amount), 0);
    console.log("Amount is  : ", amount);
    taxableamount = rowData?.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = rowData?.reduce(
      (total, item) => total + Number(item?.cgstamt),
      0
    );
    sgstamt = rowData?.reduce(
      (total, item) => total + Number(item?.sgstamt),
      0
    );
    igstamt = rowData?.reduce(
      (total, item) => total + Number(item?.igstamt),
      0
    );
    // shortguid = existingObject.shortguid ? (existingObject.shortguid) : (shortguid.getShortguid("DC"));
    // console.log("new/old shortguid is", shortguid)

    existingObject = {
      ...existingObject,
      cgstamt: Number(cgstamt).toFixed(2),
      igstamt: Number(igstamt).toFixed(2),
      sgstamt: Number(sgstamt).toFixed(2),
      taxableamount: Number(taxableamount).toFixed(2),
      amount: Number(amount).toFixed(2),
      // shortguid: shortguid,
      items: rowData,
      podate: getToday(),
      status: "9",
    };

    if (existingObject?.customerrecno && existingObject?.items?.length > 0) {
      useChangedRows.updateValue(false, existingObject);
    }
  }, [rowData]);
  console.log(
    "When Trying to chec the Row Data",
    tabStore.state.tabs[pageIndex]
  );
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data```
    var currentData = useChangedRows.getValue();
    console.log("Current", currentData.customerrecno);
    if (currentData.customerrecno) {
      if (!currentData?.shortguid) {
        useChangedRows.updateValue("shortguid", shortguid.getShortguid("DC"));
      }

      if (rowData?.length == 0) {
        var items = useChangedRows.getValue("items");
        if (items) {
          setRowData(items);
        }
      }
    }
  }, []);

  console.log("Rows Data is", scandata);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("DC"));
    }
    setCustomerData(useChangedRows.getValue("customerrecno"));
    setDcNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));

    var items = useChangedRows.getValue("items");
    if (items) {
      setRowData(items);
    }
  }, [showFilterModal]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setDcNumber("TBA");
      setDcDate(auth.state.userdata.openingbaldate)
      setSelectedQty(" ");
      setPurchaseRate(" ");
      setlocation(null);
      setDatatoSupplierdata(null);

      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("DC"));
      setModalCount((p) => p + 1);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);

  // console.log("customerData", customerData?.customerrecno);
  ///// validatiob //////
  // useEffect(() => {
  //   let validateData = "true";

  //   for (let i = 0; i < rowData?.length; i++) {
  //     console.log("RowData", rowData[i]);
  //     if (
  //       rowData[i]?.itemasbatch &&
  //       Number(rowData[i]?.qty) +
  //         Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
  //         rowData[i]?.itembatcharray?.length
  //     ) {
  //       // console.log("VLAIDATIONS APPLICATION", rowData[i]?.itemasbatch,
  //       // Number(rowData[i]?.qty) +
  //       //   Number(rowData[i]?.free ? rowData[i]?.free : 0),
  //       //   rowData[i]?.itembatcharray?.length)
  //       console.log("Validations are True")
  //       validateData = "false";
  //     }
  //   }
  //   console.log("Validated IS", validateData)
  //   useChangedRows.updateValue("validated", validateData);
  // }, [rowData])
  //// validation for  save button
  // useEffect(() => {
  //   let validateData = "false";

  //   for (let i = 0; i < rowData?.length; i++) {
  //     console.log("RowData", rowData[i]);
  //     if (
  //       rowData[i]?.itemasbatch &&
  //       Number(rowData[i]?.qty) +
  //         Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
  //         rowData[i]?.itembatcharray?.length
  //     ) {
  //       // console.log("VLAIDATIONS APPLICATION", rowData[i]?.itemasbatch,
  //       // Number(rowData[i]?.qty) +
  //       //   Number(rowData[i]?.free ? rowData[i]?.free : 0),
  //       //   rowData[i]?.itembatcharray?.length)
  //       console.log("Validations are True");
  //       validateData = "true";
  //     }
  //   }
  //   console.log("Validated IS", validateData);
  //   useChangedRows.updateValue("validated", validateData);
  // }, [rowData]);
  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData?.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        freeqty,
      } = rowData[i];

      console.log("Packaging", rowData[i]);
      if (
        (packingqty === "0" && packingextraqty === "0" && freeqty == "0") ||
        (packingqty == "" && packingextraqty == "" && freeqty == "") ||
        (packingqty === 0 && packingextraqty === "0" && freeqty == 0) ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0 && freeqty == null) ||
        (rowData[i]?.wantbatchno == 0 &&
          Number(rowData[i]?.qty ? rowData[i]?.qty : 0) +
            Number(rowData[i]?.freeqty ? rowData[i]?.freeqty : 0) !==
            rowData[i]?.itembatcharray?.length)
      ) {
        isButtonDisabled = true;
        console.log("isButtonDisabled:122", isButtonDisabled);
        break;
      } else if (
        ((packingqty === "0" && packingextraqty === "0" && freeqty == "0") ||
          (packingqty == "" && packingextraqty == "" && freeqty == "") ||
          (packingqty === 0 && packingextraqty === "0" && freeqty == 0) ||
          (packingqty === "0" && packingextraqty === 0) ||
          (packingqty === 0 && packingextraqty === 0 && freeqty == null) ||
          manufacturedate == null ||
          expdate == null ||
          itembatchno == null ||
          itembatchno == "") &&
        rowData[i]?.wantbatchno == 1
      ) {
        isButtonDisabled = true;

        break;
      }
      console.log(
        "isButtonDisabled:",
        isButtonDisabled,
        packingqty,
        packingextraqty
      );
    }

    useChangedRows.updateValue(
      "validated",
      isButtonDisabled ? "false" : "true"
    );
  }, [rowData]);
  useEffect(() => {
    if (getSelectedItem) {
      var checkIfItemExists = rowData?.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );
      console.log("Get Selected Items", getSelectedItem);

      if (checkIfItemExists?.length > 0) {
        alert("Item Already Exists");
      } else {
        addItemBatchWiseToTable();
      }
    }
  }, [getSelectedItem]);

  const addItemBatchWiseToTable = () => {
    // Get getSelectedItems
    // if (getSelectedItem?.itembasbatch) {
    //   //Added s at the end remove later
    //   // Here we need to add the from the Quantity,
    // } else {
    // Here we select form the selected Items
    let batchDetails = getSelectedItem?.batchdetails;
    for (let i = 0; i < batchDetails?.length; i++) {
      let itemrecno = getSelectedItem.itemrecno;
      let itemdescn = getSelectedItem.itemdescn;
      let igstrate = getSelectedItem.igstrate;
      let cgstrate = getSelectedItem.cgstrate;
      let sgstrate = getSelectedItem.sgstrate;
      let mrp = getSelectedItem.mrp;
      let conversionfactor = getSelectedItem?.conversionfactor;
      //{Pradeep task id -337, added packingextrarecno variable}
      let packingextrarecno = getSelectedItem?.packingextrarecno;
      let totalSelectedQuantity = batchDetails[i].selectedQty;
      let totalFreeQuantity = batchDetails[i].selectedFreeQty;
      let selectedBoxQty = convertToBoxAndPieces(
        totalSelectedQuantity,
        conversionfactor,
        packingextrarecno //{Pradeep task id -337, added packingextrarecno variable}
      )[0];
      console.log("selectedBoxQty", selectedBoxQty);
      let selectedPcsQty = convertToBoxAndPieces(
        totalSelectedQuantity,
        conversionfactor,
        packingextrarecno //{Pradeep task id -337, adde packingextrarecno variable}
      )[1];
      console.log("selectedPcsQty", selectedPcsQty);
      let selectedFreeBoxQty = convertToBoxAndPieces(
        totalFreeQuantity,
        conversionfactor
      )[0];
      let selectedFreePcsQty = convertToBoxAndPieces(
        totalFreeQuantity,
        conversionfactor
      )[1];
      let batchNo = batchDetails[i].itembatchno;
      let itemSerialNo = batchDetails[i].serialno;
      let salerate = batchDetails[i].salerate;
      let expdate = batchDetails[i].expdate;
      let manudate = batchDetails[i].manudate;

      if (totalSelectedQuantity > 0 || totalFreeQuantity > 0) {
        handleAdd(
          getSelectedItem,
          setRowData,
          igstrate > 0 ? true : false,
          setSelectedItem,
          shortguid,
          salerate,
          selectedPcsQty,
          selectedBoxQty,
          selectedFreeBoxQty,
          selectedFreePcsQty,
          batchNo,
          expdate,
          0,
          0,
          manudate
        );
      }
      // }
    }
  };

  //----------multiple selection item start------------------------------------------------

  const options = [
    // {
    //   id: 1,
    //   optionName: "Sale Invoice",
    //   apiurl: "filtersalebill",
    //   customerrecno: customerData?.customerrecno,
    //   doctype: "SI",
    // },
    {
      id: 1,
      optionName: "Sale Order",
      apiurl: "filtersaleorder",
      customerrecno: customerData?.customerrecno,
      doctype: "SO",
    },
  ];
  //-----------------------------end-----------------------------

  //// validation for  save button
  // useEffect(()=>{
  //   setValidator((p)=>p+validator)

  //   const total=rowData.reduce((acc,item)=> (item.qty+item.free)+acc,0)
  //   console.log("RAjendra",validator,total)
  //   setTotalQty(total)
  //     },[openItem])

  console.log("RowData:===== 000");
  return (
    <div>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "message",
            document.getElementById("message").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Cust</p>
          <Input
            id={"message"}
            placeholder="Enter Message for Cust"
            defaultValue={useChangedRows.getValue("message")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      <Modal
        open={displayPP}
        onCancel={() => {
          setDisplayPP(!displayPP);
        }}
        onOk={() => {
          setDisplayPP(!displayPP);
        }}
      >
        <div>
          Select Items from Pending Purchase Orders
          <Collapse
            collapsible="header"
            defaultActiveKey={["0"]}
            style={{ height: 300, overflowX: "scroll" }}
          >
            {previousPOList?.Message?.map((item, index) => {
              return (
                <Panel
                  header={`Purchase Order : ${item?.ponumber}`}
                  key={index}
                >
                  <List
                    size="small"
                    itemLayout="horizontal"
                    bordered
                    dataSource={item?.items}
                    renderItem={(it, ind) => {
                      it.selectedQty = it?.selectedQty
                        ? it.selectedQty
                        : it?.qty;
                      return (
                        <List.Item>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {it?.itemdescn} <br></br>Quantity : {it?.qty}
                            </div>
                            <input
                              className="tr001Input"
                              style={{ width: 80 }}
                              type={"number"}
                              defaultValue={it?.selectedQty}
                              onChange={(e) => {
                                it.selectedQty = e.target.value;
                              }}
                            ></input>
                            <Button
                              icon={<FcAddDatabase />}
                              onClick={() => {
                                console.log("Changed Object is ", it);
                                setInfoFromPO((p) => [...p, it]);
                              }}
                            ></Button>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={countRender}
      >
        <SearchFilterByBillNo
          fastpath="RP107"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>

      {/* ===========modal multi select================ */}
      <Modal
        key={count}
        width={620}
        open={displayPrevPO}
        onCancel={() => {
          setDisplayPrevPO(!displayPrevPO);
          setCount((p) => p + 1);
        }}
        onOk={() => {
          console.log(
            "Display Previos Items from Different Locations",
            returnData
          );
          let newArray = returnData?.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor)
            );
            console.log("item?.conversionFactor", rowData);
            console.log("PCS BOX", Number(item?.conversionfactor));

            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              var existingItem = rowData.filter(
                (it) => it && it.itemrecno === item.itemrecno
              );

              if (existingItem?.length === 0) {
                // Add item to the list
                handleAdd(
                  item,
                  setRowData,
                  item?.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item?.salerate ? item.salerate : item?.rate,
                  pcsBox[1],
                  pcsBox[0],
                  0,
                  item.freeQuantity,
                  null,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  item?.amount > 0 ? item.amount : 0,
                  item?.taxableamount > 0 ? item.taxableamount : 0
                );
                setreturnData([]);
              } else {
                console.log("Existing Item Array is", existingItem);
                // var totalBoxQty = existingItem.reduce((acc, el) => {
                //   console.log("Item Existing Array is ", item, el)

                //   (acc += Number(el?.packingextraqty))
                // }, 0) +  Number(pcsBox[0])
                // var totalPcsQty = existingItem.reduce((acc, el) => (acc += Number(el?.packingqty)), 0) +  Number(pcsBox[1])

                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item?.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item?.salerate ? item.salerate : item?.rate,
                    pcsBox[1],
                    pcsBox[0],
                    0,
                    item.freeQuantity,
                    null,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    item?.amount > 0 ? item.amount : 0,
                    item?.taxableamount > 0 ? item.taxableamount : 0
                  );
                  setreturnData([]);
                }, 200);
              }
            }
          });
          setDisplayPrevPO(!displayPrevPO);
          setCount((count) => count + 1);
        }}
      >
        <MultipleSelectionItem
          options={options}
          selectedItemsArray={returnData}
          //added forflag for fetching data in previous doc
          forFlag={"dc"}
          returnValue={(e) => {
            setreturnData(e);
          }}
          todate={dcDate}
        />
      </Modal>
      {/* ===========modal multi select end================ */}

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div>
            {/* Pradeep task id -398 Shifted Print Dc checkbox in top of the right screen */}

            <Space>
              <div>
                <form
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: "40px", // Adjust the column gap as needed
                    rowGap: "15px",
                    marginTop:"5px"
                  }}
                >
                  <div
                    style={{
                      zIndex: 4,
                      position: "relative",
                      pointerEvents: rowData?.length > 0 ? "none" : "auto",
                    }}
                    onKeyDown={enterPress}
                  >
                    <SaveDropdown
                      defaultSelected={customerData}
                      disabled={rowData && rowData.length > 0?1:0}
                      displayLabel="Cust"
                      //{Pradeep task id-398 }/// added isrequired property
                      placeholder={"Select Cust"}
                      isrequired
                      alignment="column"
                      apikey="customerrecno"
                      apiurl={getDomaincustomerlist}
                      selectionLabel="customerdescn"
                      selectionValue="customerrecno"
                      returnValue={(event) => setCustomerData(event)}
                      key={modalcount}
                    />
                    <p>{customerData ? customerData?.remarks : ""}</p>
                  </div>
                  <div
                    style={{ zIndex: 12, position: "relative" }}
                    onKeyDown={enterPress}
                  >
                    <SaveDropdown
                      //rishi - 239
                      //after editing dc the location is not coming after this change it is coming
                      //{Pradeep task id -dc}
                      defaultSelected={location}
                      placeholder={"Select Cust Locn"}
                      displayLabel="Cust Locn"
                      alignment="column"
                      apiurl="domaincustomerlocationlist"
                      apikey="customerlocationrecno"
                      selectionLabel="descn"
                      selectionValue="customerlocationrecno"
                      returnValue={() => setlocation()}
                      body={{ customerrecno: customerData?.customerrecno }}
                      key={modalcount}
                    />
                  </div>
                  <div onKeyDown={enterPress} style={{ zIndex: 1000 }}>
                    <SaveDropdown
                      displayLabel="Salesman"
                      //{Pradeep task id -dc}
                      defaultSelected={salesman}
                      placeholder={"Select Salesman"}
                      alignment="column"
                      //apiurl="getdomaincustomersalesman"
                      apiurl={getDomainCustomerSalesman}
                      apikey="salesmanrecno"
                      selectionLabel="customerdescn"
                      selectionValue="customerrecno"
                      //{Pradeep task id -dc}
                      returnValue={() => setsalesman()}
                      body={{ customerrecno: customerData?.customerrecno }}
                      key={modalcount}
                    />
                  </div>
                  {/* <div onKeyDown={enterPress}>
                    <SaveDropdown
                      displayLabel="Surgeon"
                      //{Pradeep task id -dc}
                      defaultSelected={surgeon}
                      placeholder={"Select Surgeon"}
                      alignment="column"
                      apiurl="getdomaincustomerrefby"
                      apikey="refbyrecno"
                      selectionLabel="refbydescn"
                      selectionValue="refbyrecno"
                      //{Pradeep task id -dc}
                      returnValue={() => setsurgeon()}
                      body={{ customerrecno: customerData?.customerrecno }}
                      key={modalcount}
                    />
                  </div> */}

                  <div
                    // style={{
                    //   marginTop: "1%",
                    //   width: "106%",
                    //   display: "flex",
                    //   gap: "20px",
                    // }}
                    onKeyDown={enterPress}
                  >
                    {/* <p>DC Number</p> */}
                    <SaveTextInput
                    pageIndex={pageIndex}
                      alignment="column"
                      displayLabel={"DC No."}
                      placeholder={"Enter DC No."}
                      apikey={"billno"}
                      // defaultValue={elem?.defaultValue}
                      change
                      // returnValue={(e) => {
                      //   console.log("FC", e);
                      //   setterFunction(e, elem);
                      //   storeData(e, elem?.apiKey);
                      // }}
                      key={modalcount}
                      isrequired
                    />
                  </div>

                  <div
                    style={{
                      float: "left",
                      zIndex: 3,
                      position: "relative",
                      marginLeft: -12,
                      marginTop: 5,
                    }}
                    onKeyDown={enterPress}
                  >
                    <SaveDatePicker
                      displayLabel="DC Date"
                      defaultValue={dcDate}
                      placeholder={"DC Date"}
                      apiKey="trdate"
                      id={"tr014-dc-dcdate"}
                      disabled={rowData && rowData.length > 0?1:0}
                      dateLessthan={auth.state.userdata.openingbaldate}
                      postAlertMsg={`DC Date cannot be greater than Opening Date`}
                      returnValue={(e) => {
                        setDcDate(e);
                        //let tempCheck = document.getElementById("tr014-dc-dcdate");
                        // if (tempCheck.value === "") {
                        //   document.getElementById("tr014-dc-dcdate").focus();
                        //   return true;
                        // }
                        // return ;
                      }}
                      key={modalcount}
                    />
                  </div>

                  <div onKeyDown={enterPress}>
                    <AdditionalDetails />
                  </div>

                  <div onKeyDown={enterPress}>
                    <SaveTextInput
                    pageIndex={pageIndex}
                      id="tr014-drivername"
                      placeholder="Driver Name"
                      style={{ width: "100%" }}
                      apikey="drivername"
                      change={true}
                      displayLabel="Driver Name"
                      // returnValue={() => setdrivername()}
                      // onChange={() => setdrivername()}
                      ///{ Pradeep task id-dc }} to clear driver name input field
                      key={modalcount}
                    />
                  </div>

                  <div onKeyDown={enterPress}>
                    <SaveTextInput
                      id="tr014-vehicleno"
                      pageIndex={pageIndex}
                      placeholder="Vehicle No."
                      style={{ width: "100%" }}
                      apikey="vehicleno"
                      // apiKey="vehicleno"
                      change={true}
                      ///{Pradeep task id-dc}  to clear Vehicle Number input field
                      key={modalcount}
                      displayLabel="Vehicle No."
                      // returnValue={() => setvehiclenumber()}
                    />
                  </div>
                  {/* amol-temp - changed in style */}
                  <div onKeyDown={enterPress}>
                    <Button
                      className="btn"
                      //style={{ ...buttonStyle }}
                      onClick={() => {
                        setDisplayPrevPO(!displayPrevPO);
                        setCount((count) => count + 1);
                      }}
                    >
                      Previous Documents
                    </Button>
                  </div>
                  {/* amol-temp - changed in style */}
                  <div onKeyDown={enterPress}>
                    <Button
                      //style={{ ...buttonStyle }}
                      className="btn"
                      type="default"
                      onClick={() => {
                        setshowFilterModal(true);
                        setcountRender((p) => p + 1);
                      }}
                    >
                      {/* ///{ Start   Pradeep task id-dc  change the button name to search billno to search Dcno caption changed start } */}
                      Search DC No.
                    </Button>
                  </div>
                  {/* amol-temp - changed in style and removed type="dashed" */}
                  <div onKeyDown={enterPress}>
                    <Button
                      className="btn"
                      //style={{ ...buttonStyle }}
                      onClick={(e) => setDisplayMessages(!displayMessages)}
                    >
                      Add / View Messages
                    </Button>
                  </div>
                  <div style={{ textAlign: "right" }} onKeyDown={enterPress}>
                    {" "}
                    <SaveCheckbox
                      apikey={"isPrint"}
                      displayLabel="Print DC"
                      editable
                      key={modalcount} /// clear after save butoon date 27jun 2023
                    />{" "}
                  </div>
                </form>
              </div>
            </Space>
            <br />
          </div>
        </Panel>
      </Collapse>

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Items Details" key="2">
          <div>
            <Space size="large" style={{ alignItems: "end" }}>
              <div style={{ position: "relative", zIndex: 2 }}>
                <ItemSearch
                  itemSearchRef={itemSearch}
                  forFlag={"S"}
                  batchdetails={true}
                  returnValue={setSelectedItem}
                  supplierdata={customerData?.customerrecno}
                  dropdown
                  defaultSelectedItem={getSelectedItem}
                  selection
                  forPage={"openingdc"}
                  barcodeReturnValue={(e) => {
                    if(e?.serialnofound === false){
                    // Write a function to add to existing Items and check only if it has serial number
                    if (e?.itemasbatch == 1) {
                      fetchItems
                        .addBarcodeScannedItemToTable(
                          rowData,
                          e,
                          "SIF",
                          igstappl,
                          pageDetails,
                          "openingdc"
                        )
                        .then((res) => {
                          console.log("New ROws Data", res);
                          setRowData(res);
                          setChange(change + 1);
                        })
                        .catch((err) => alert(err));
                    } else {
                      setSelectedItem(e);
                    }
                  } else {
                    alert("Item Already Exists in System")
                  }
                }
                }
                />
              </div>
            </Space>
          </div>

          {/* Table for Selection  */}
          <div
            style={{ zIndex: 0, position: "relative", margin: "24px auto 0" }}
          >
            {rowData?.length > 0 && (
              <Table
                scroll={{
                  y: 500,
                  x: 500,
                }}
                size="small"
                className="tr012-salesOrder-itemsTable"
                bordered
                pagination={false}
                columns={piColumns(
                  igstappl,
                  setRowData,
                  rowData,
                  scandata,
                  setScandata,
                  setOpenItem,
                  dcDate,
                  pageDetails,
                  change
                )?.filter((item) => !item.hidden)}
                dataSource={rowData}
                onChange={(e) => {}}
                summary={(pageData) => {
                  let qty = 0;
                  let free = 0;
                  let packingextraqty = 0;
                  let packingqty = 0;
                  let freepackingextraqty = 0;
                  let freepackingqty = 0;

                  var existingObject = useChangedRows.getValue();
                  console.log("Existing Object is", existingObject);
                  pageData.forEach((item) => {
                    qty = Number(qty) + Number(item?.qty);
                    free = Number(free) + Number(item?.free);
                    packingextraqty =
                      Number(packingextraqty) + Number(item?.packingextraqty);
                    packingqty = Number(packingqty) + Number(item?.packingqty);
                    freepackingextraqty =
                      Number(freepackingextraqty) +
                      Number(item?.freepackingextraqty);
                    freepackingqty =
                      Number(freepackingqty) + Number(item?.freepackingqty);
                  });

                  return (
                    <Table.Summary fixed className="tr013-items-footer">
                      {!igstappl ? (
                        <Table.Summary.Row>
                          {/* Pradeep task id -398 added extra cell here */}
                          <Table.Summary.Cell index={0}></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles, textAlign: "left" }}>
                              Total :{" "}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>

                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{packingextraqty}</p>
                            </Table.Summary.Cell>
                          ) : null}
                          {/* amol-temp - added p tag and applied style to p tag...
                          for packingqty and qty*/}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{packingqty}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{qty}</p>
                          </Table.Summary.Cell>

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>
                                {freepackingextraqty}
                              </p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty &&
                          pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{freepackingqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              {" "}
                              <p style={{ ...pStyles }}>{free}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantbatchno ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantmanudate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantexpdate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {/* <Table.Summary.Cell></Table.Summary.Cell> */}

                          {/* <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>

                          <Table.Summary.Cell></Table.Summary.Cell> */}

                          {/* <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {existingObject?.taxableamount}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {existingObject?.sgstamt}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {existingObject?.cgstamt}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {existingObject?.amount}
                      </Table.Summary.Cell> */}
                        </Table.Summary.Row>
                      ) : (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles, textAlign: "left" }}>
                              Total :{" "}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>

                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{packingextraqty}</p>
                            </Table.Summary.Cell>
                          ) : null}
                          {/* amol-temp - added p tag and applied style to p tag...
                          for packingqty and qty*/}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{packingqty}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{qty}</p>
                          </Table.Summary.Cell>

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>
                                {freepackingextraqty}
                              </p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty &&
                          pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{freepackingqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              {" "}
                              <p style={{ ...pStyles }}>{free}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantbatchno ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantmanudate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantexpdate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {/* <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell> */}
                          {/* <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell> */}
                          {/* <Table.Summary.Cell>
                          <p style={{ ...pStyles }}>{existingObject?.taxableamount}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                          <p style={{ ...pStyles }}>{existingObject?.igstamt}</p>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell>
                          <p style={{ ...pStyles }}>{existingObject?.amount}</p>
                          </Table.Summary.Cell> */}
                        </Table.Summary.Row>
                      )}
                    </Table.Summary>
                  );
                }}
              />
            )}
          </div>
          {/* Pradeep task id -398  */}
        </Panel>
      </Collapse>
      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          forFlag="S"
          supplierrecno={customerData?.customerrecno}
          pageDetails={pageDetails}
        />
      )}
    </div>
  );
}

export default DeliveryChallanOpening;
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "10px",
  paddingTop: "10px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "80px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "80px",
  borderRadius: "8px",
  marginTop: "6px",
};

// amol-temp - change the values
const poNumberStyle = {
  marginTop: "8px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
};
{
  /* Pradeep task id-391  *added pStyles variable*/
}
const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

// amol-temp - added buttonStyle
const buttonStyle = {
  marginTop: "7px",
  textAlign: "right",
  border: "1px solid #dfdfdf",
  height: "36px",
};
