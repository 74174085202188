// Nohit Patel 20/02/2023
import { Button, Collapse, Input, Modal, Space, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getToday,
  dateFormatter,
  enterPress,
  convertToBoxAndPieces,
} from "../../CommonControllers/AppFunction";
import BillingTable from "../../CommonControllers/Components/BillingTableComponents/BillingTable";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import { handleAdd, prColumns } from "./PurchaseReturnBillingTableColumns";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import SaveDropDown from "../../CommonControllers/SaveComponents/SaveDropDown";
import {
  domainLocationURL,
  getDomainSupplier,
  getdomainlocationlistactive,
  getpostatuslistactive,
} from "../../CommonControllers/Urls";
import "../../CommonControllers/global.css";
import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import { IoAperture } from "react-icons/io5";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import ItemsTable from "./ItemsTable";
import { useRef } from "react";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import {
  getBillreturn,
  getPibill,
  getPurchasebillOpening,
  getfastpathapi,
  getpurchasebill,
} from "../../CommonControllers/ApiCalls";
import { v4 as uuidv4 } from "uuid";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import Purchasesalebillnumber from "./Purchasesalebillnumber";
import { currentTabData } from "../../CommonControllers/Variable";
import axios from "axios";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import CallbackDropdown from "../../CommonControllers/Components/CallbackComponents/CallbackDropdown";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import { handleRemovePrev } from "../SalesInvoice/SalesInvoiceFunc";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";
import { notification } from 'antd';
import MultipleSelectionItemPendingDocs from "../PurchaseInvoice/MultipleSelectionItemPendingDocs";
function PurchaseReturn({ fastPath }) {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [purchaseRate, setPurchaseRate] = useState(" ");
  const [selectedQty, setSelectedQty] = useState(" "); // Qty as in pieces in UI, total = pieces * rate
  const [box, setBox] = useState("");
  const [purchaseshortguid, setpurchaseshortguid] = useState("");
  const [batch, setbatch] = useState(" ");
  const [expDate, setexpDate] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const { Panel } = Collapse;
  const auth = useContext(AuthStore);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const [countRender, setcountRender] = useState(0);
  const [statusOptions, setStatusOptions] = useState([]);
  const [transactionEdit, setTransactionEdit] = useState(false);
  const [status, setstatus] = useState(true);
  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);

  const [showFilterModalOpening, setshowFilterModalOpening] = useState(false);
  // render
  const [count, setCount] = useState(0);
  const [prNumber, setPrNumber] = useState();
  const [PiNumber, setPiNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------
  const [supplierdata1, setSupplierdata1] = useState(null);
  const [location1, setlocation1] = useState();
  const tabstore = useContext(TabStore);

  const batchRef = useRef(null);
  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const rateRef = useRef(null);
  const btnRef = useRef(null);

  const itemSearch = useRef(null);
  const fetchItems = useFetchItem();
  const [barcodeValue, setbarcodeValue] = useState();

  //
  ///  new model state for search invoice
  const [model, setModel] = useState(false);
  const [modelOpening, setModelOpening] = useState(false);

  const [counter, Setcounter] = useState("");
  const [statusC, setstatusC] = useState(false);

  const [modalcount, setModalcount] = useState(0);

  const changerow = tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows;

  console.log("changerow in PR", changerow);
  ///-----------------------------------------PI--------------------------------
  const [pi, setPino] = useState(null);
  const [pibillno, setPibillno] = useState("");
  const [dropstatus, setDropStatus] = useState("P");

  /// page details is added
  const [pageDetails, setPageDetails] = useState();
  const [pageIndex, setPageIndex] = useState();

  /// here pic from PI
  const [customerData, setCustomerData] = useState({}); //~
  const [displayPrevDC, setDisplayPrevDC] = useState(false);
  const [returnData, setreturnData] = useState([]);
  // const [modalcount, setModalCount] = useState(0);

  /// here Date Validation
  const [prdate, setPrdate] = useState(auth?.state?.userdata?.workdate);
  const payld = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    userrolerecno: auth.state.userdata.userrolerecno,
    fastpath: currentTabData(tabStore)?.name,
  };

  //// here Date 29 jun rajendra
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payld).then((res) => {
      console.log("PR getbilll", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        console.log("Resposnse of Fast Path API", res.Message);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
        setstatus(res.Message?.defaultStatus);
      }
    });
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    setPrdate(formattedDate);
  }, []);

  const payload = {
    ...changerow,
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth.state.userdata.userrolerecno,
  };

 
  async function handleAdditem() {
    if (pi !== null && pi !== "" && pi !== 0) {
      try {
        let res = await getpurchasebill(payload);
        console.log("RRRRR", res);

        if (res.Success && res?.Message[0]?.items.length > 0) {
          // setRowData(res?.Message[0]?.items);
          console.log("aappii data", res?.Message[0]?.items);
          const newdata = res?.Message[0]?.items.map((i) => {
            return { ...i, itembatcharray: [], shortguid: uuidv4(), boxpurrate: Number(i?.purrate) * Number(i?.conversionfactor) };
          });
          setRowData(newdata);
          setSupplierdata(useChangedRows.getValue("supplierrecno"));

          setlocation(useChangedRows.getValue("locationrecno"));
          setPiNumber(useChangedRows.getValue("billno"));
          // var items = useChangedRows.getValue("items");
          setPibillno(useChangedRows.getValue("pibillno"));
          var newShortGuid = shortguid.getShortguid("PR");
          setpurchaseshortguid(res.Message[0]?.shortguid);
          console.log("Shortguid is for the Purchase Bill", newShortGuid);
          useChangedRows.updateValue("shortguid", newShortGuid);
          setigstappl(res?.Message[0]?.items[0]?.igstrate);
        } else {
          auth.state.contextHolder.info({
            message: `An Error has Occured`,
            description: "BIll No is not found",
            duration: 3,
            style: { background: "#efa7a7" },
          });
        }
      } catch (err) {
        auth.state.contextHolder.info({
          message: `An Error has Occured`,
          description: String(err),
          duration: 3,
          style: { background: "#efa7a7" },
        });
      }
    }
  }

  async function handleAdditemOPeningTransaction() {
    if (pi !== null && pi !== "" && pi !== 0) {
      try {
        let res = await getPurchasebillOpening(payload);

        if (res.Success && res?.Message[0]?.items.length > 0) {
          var newShortGuid = shortguid.getShortguid("PR");
          setpurchaseshortguid(res.Message[0]?.shortguid);
          useChangedRows.updateValue("shortguid", newShortGuid);
          const newdata = res?.Message[0]?.items.map((i) => {
            return { ...i, itembatcharray: [], shortguid: uuidv4(), boxpurrate: Number(i?.purrate) * Number(i?.conversionfactor) };
          });
          setRowData(newdata);
          setSupplierdata(useChangedRows.getValue("supplierrecno"));

          setlocation(useChangedRows.getValue("locationrecno"));
          setPiNumber(useChangedRows.getValue("billno"));
          setPibillno(useChangedRows.getValue("pibillno"));
          setigstappl(res?.Message[0]?.items[0]?.igstrate);
        }
        // else {
        //   auth.state.contextHolder.info({   //// the alert message has been commented out as per suggestion
        //     message: `An Error has Occured`,
        //     description: "Bill not present",
        //     duration: 3,
        //     style: { background: "#efa7a7" },
        //   });
        // }
      } catch (err) {
        auth.state.contextHolder.info({
          message: `An Error has Occured`,
          description: String(err),
          duration: 3,
          style: { background: "#efa7a7" },
        });
      }
    }
  }

  let grossAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
      Number(purchaseRate) || "00";

  useEffect(() => {
    getSelectedItem?.purrate !== undefined
      ? setPurchaseRate(getSelectedItem?.purrate)
      : setPurchaseRate("");
  }, [getSelectedItem?.purrate]);

  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  function dateSetter() {
    //~# date setter logic here

    const expDays = supplierdata?.expdeliverydays;

    let day = workdate?.slice(0, 2);
    let month = workdate?.slice(3, 5);
    let year = workdate?.slice(-4);

    setExpDeliveryDays(`${1 + Number(day)}/${month}/${year}`);
  }

  function setDatatoSupplierdata(e) {
    setSupplierdata1(e);
    setlocation1(e);
  }

  function handleBatchChange(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setbatch(event.target.value);
    }
  }

  function handleBoxChange(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setBox(event.target.value);
    }
  }

  function handlePiecesChange(event) {
    const regex = /^[0-9\b]+$/;
    const value = event.target.value;
    if (value === "" || regex.test(value)) {
      // let modulo = Number(value) % 10;
      // let carryOver = Number(value) / 10;
      // carryOver = Math.floor(carryOver);
      // setBox((prev) => Number(prev) + carryOver);

      setSelectedQty(value);
    }
  }

  const handleRateChange = (event) => {
    //~# Regex checking for decimal upto two places
    if (box == 0 && selectedQty == 0) {
      alert("Enter Box or pieces");
      box == 0 ? boxRef.current.focus() : piecesRef.current.focus();
      return;
    }
    const regex = /^\d*(\.\d{0,2})?$/;
    const value = event.target.value;

    if (value === "" || regex.test(value)) {
      setPurchaseRate(value);
    }
  };

  const addItemstoTable = () => {
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // clearing all input values
        setbatch("");
        setBox("");
        setSelectedQty("");
        setPurchaseRate("");
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          selectedQty,
          box,
          batch,
          expDate
        );
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
      }
    }
  };

  // useEffect(() => {
  //   var tabsArray = tabStore.state.tabs;
  //   for (let i = 0; i < tabsArray?.length; i++) {
  //     if (tabsArray[i].name == "TR004") {
  //       setPageIndex(i);
  //       break;
  //     }
  //   }
  // }, []);

  /// here initially the igstappl not comming from supplierdata
  useEffect(() => {
    if (supplierdata?.igstappl) {
      setigstappl(true);
      dateSetter();
    }
    // else {
    //   setigstappl(false);
    //   dateSetter();
    // }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    //console.log("Rajendra nali status", rowData);
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    // var status = "P";
    var trdate = "workdate";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    var shortguidN;
    var supplierrecno

    amount = rowData?.reduce((total, item) => total + Number(item?.amount), 0);
    taxableamount = rowData?.reduce(
      (total, item) => total + Number(item?.taxableamount),
      0
    );
    cgstamt = rowData?.reduce(
      (total, item) => total + Number(item?.cgstamt),
      0
    );
    sgstamt = rowData?.reduce(
      (total, item) => total + Number(item?.sgstamt),
      0
    );
    igstamt = rowData?.reduce(
      (total, item) => total + Number(item?.igstamt),
      0
    );
    shortguidN =  existingObject.shortguid ? (existingObject.shortguid):( shortguid.getShortguid("PR") );
    // console.log("new/old shortguid is",shortguid)
    supplierrecno = existingObject?.supplierrecno ? existingObject?.supplierrecno : supplierdata?.supplierrecno

    existingObject = {
      ...existingObject,
      cgstamt: cgstamt,
      igstamt: igstamt,
      sgstamt: sgstamt,
      taxableamount: taxableamount,
      shortguid: shortguidN,
      amount: amount,
      items: rowData?.map((it) => {
        if (it.itemasbatch === 0) {
          return { ...it, itembatcharray: [] };
        } else {
          return it;
        }
      }),
      supplierrecno: supplierrecno,
      trdate: prdate ? prdate : auth?.state?.workdate,
      // trdate: getToday(),
      purchaseshortguid: purchaseshortguid,
      // status: dropstatus ? dropstatus : "", // status has been changed from "C" to "P"
      status: status=="0" ? statusOptions[0]?.value :status? statusOptions[1]?.value:statusOptions[0]?.value,
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData, dropstatus, status]);
  // useEffect(() => {
  //   if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
  //     if (tabStore.state.tabs[pageIndex]?.changedRows?.items.length > 0) {
  //       setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
  //     }
  //     setTransactionEdit(true)
  //     var logData = tabStore.state.tabs[pageIndex]?.changedRows;

  //     logData.dataFromEdit = false;

  //     tabStore.dispatch({
  //       type: "UPDATE_TABS_ARRAY",
  //       changedRowsValue: logData,
  //       tabsFastPath: "TR004",
  //       keyName: "changedRows",
  //     });
  //   }
  // }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      // setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
      const newdata = tabStore.state.tabs[pageIndex]?.changedRows?.items.map((i) => {
        return { ...i, boxpurrate: Number(i?.purrate) * Number(i?.conversionfactor) };
      });
      setRowData(newdata);
      setTransactionEdit(true);
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
      setstatus(tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus);
    
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items && rowData?.length == 0) {
        setTransactionEdit(true);

        setRowData(items);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (useChangedRows.getValue("trdate")) {
  //     setPrdate(useChangedRows.getValue("trdate"));
  //   } else {
  //     useChangedRows.updateValue("trdate", auth?.state?.workdate);
  //     setPrdate(auth?.state?.workdate);
  //   }
  // }, [useChangedRows.getValue("trdate")]);


  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    } else if (
      currentData.shortguid.includes("PI") ||
      currentData.shortguid.includes("SR")
    ) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    }
    setSupplierdata(useChangedRows.getValue("supplierrecno"));
    setPrNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));
    setPiNumber(useChangedRows.getValue("billno"));
    setPibillno(useChangedRows.getValue("pibillno"));
    setPrdate(currentData.trdate)
    var items = useChangedRows.getValue("items");

    if (items) {
      setRowData(items);
    }
  }, [showFilterModal, showFilterModalOpening]);

  // useEffect(() => {
  //   // When Row Data Changes we Need to store that data to the tabsStore with Header Data
  //   var currentData = useChangedRows.getValue();
  //   if (!currentData?.shortguid) {
  //     useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
  //   } else if (
  //     currentData.shortguid.includes("PI") ||
  //     currentData.shortguid.includes("SR")
  //   ) {
  //     useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
  //   }
  //   setSupplierdata(useChangedRows.getValue("supplierrecno"));

  //   setlocation(useChangedRows.getValue("locationrecno"));
  //   setPiNumber(useChangedRows.getValue("billno"));
  //   var items = useChangedRows.getValue("items");
  //   setPibillno(useChangedRows.getValue("pibillno"));
  //   console.log("items", items);
  //   if (items) {
  //     setRowData(items);
  //   }
  // }, [model, modelOpening]);

  useEffect(() => {
    if (tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus == "Success") {
      setTransactionEdit(false);
      setStatusOptions([
        {
          label: "Save",
          value: pageDetails?.defaultStatus,
        },
        {
          label: "Proceed",
          value: pageDetails?.nextStatus,
        },
      ]);
      setstatus(true);
      setPrdate(auth?.state?.userdata?.workdate);
      setSupplierdata();
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setSelectedQty(" ");
      setPurchaseRate(" ");
      setPrNumber("To Be Allotted");
      setModalcount((p) => p + 1);

      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
      setPrNumber("");
    }
  }, [tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus]);

  /// the pi date  validation
  const setPi = (e) => {
    console.log("setPI", e);
    setPino(e);
  };

  useEffect(() => {
    setPi();
  }, [pi]);

  useEffect(() => {
    let _temp = changerow?.billno;
 
    if (_temp?.length > 0) {
      handleAdditem();
    }
  }, [model, counter]);

  useEffect(() => {
    let _temp = changerow?.billno;

    if (_temp?.length > 0) {
      handleAdditemOPeningTransaction();
    }
  }, [modelOpening, changerow?.opening]);
  ////// validation for save button changes /////
  useEffect(() => {
    let isButtonDisabled = false;
    let x = rowData?.reduce((acc, item) => acc + item?.currentreturnqty, 0);

    for (let i = 0; i < rowData?.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
        currentreturnqty,
        currentreturnpackingqty,
      } = rowData[i];

      //console.log("RAJENDRA DATAT",x, rowData[i])
      if (
        rowData[i]?.itemasbatch &&
        Number(rowData[i]?.currentreturnqty) +
          Number(rowData[i]?.returnfree ? rowData[i]?.returnfree : 0) !==
          rowData[i]?.itembatcharray?.length &&
        currentreturnpackingqty != 0
      ) {
        isButtonDisabled = true;

        break;
      }
      // else if ((wantbatchno && (!itembatchno || itembatchno == "" || itembatchno == null || itembatchno == undefined))||  (packingqty === "0" && packingextraqty === "0") || (packingqty == "" && packingextraqty == "") || (packingqty === 0 && packingextraqty === "0") || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0)|| Number(purrate) == 0) {
      //   console.log("Custermer891",Number(rowData[i]?.qty) +
      //   Number(rowData[i]?.free ? rowData[i]?.free : 0) ,
      //   rowData[i]?.itembatcharray?.length)
      //   isButtonDisabled = true;
      //   console.log("isButtonDisabled:122", isButtonDisabled);
      //   break;
      // }
      if (x == 0) {
        isButtonDisabled = true;
      }
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);

  //// PR Search return Logic here

  useEffect(() => {
    if (prNumber) {
      getfetchbill();
    }
  }, [prNumber, showFilterModal, showFilterModalOpening]);

  const getfetchbill = async () => {
    if (prNumber) {
      let payloadpi = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        domainuserrecno: auth?.state?.userdata?.recno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        // withoutstatus: "C",
        billno: pibillno,
      };
      let payloadpr = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        domainuserrecno: auth?.state?.userdata?.recno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        withoutstatus: "C",
        billno: PiNumber,
      };

      var pibill = await getPibill(payloadpi);

      if (changerow?.opening) {
        pibill = await getPurchasebillOpening(payloadpi);
      }

      let prbill = await getBillreturn(payloadpr);

      let itemsArrayForReturn = pibill?.Message[0]?.items.map((piItem) => {
        var purReturnItem = prbill.Message[0]?.items.filter(
          (item) => item.itemrecno == piItem.itemrecno
        );
        piItem.itembatcharray = [];
        if (purReturnItem && purReturnItem?.length > 0) {
          piItem.currentreturnpackingqty = purReturnItem[0]?.packingqty;
          piItem.currentreturnqty = purReturnItem[0]?.qty;
          piItem.returnableqty =
            Number(piItem.returnableqty) + Number(purReturnItem[0]?.qty);
          piItem.itembatcharray = purReturnItem[0]?.itembatcharray;
          piItem.shortguid = purReturnItem[0]?.shortguid;
          piItem.discount = purReturnItem[0]?.discount;
          piItem.discountamt = purReturnItem[0]?.discountamt;
          piItem.currentreturncgstamt = purReturnItem[0]?.cgstamt;
          piItem.currentreturnsgstamt = purReturnItem[0]?.sgstamt;
          piItem.currentreturnigstamt = purReturnItem[0]?.igstamt;
          piItem.currentreturnamount = purReturnItem[0]?.amount;
          piItem.currentreturntaxableamount = purReturnItem[0]?.taxableamount;
          // Add Rest Parameters here
        } else {
          piItem.shortguid = uuidv4();
        }

        return piItem;
      });

      var currentData = useChangedRows.getValue();
      if (!currentData?.shortguid) {
        useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
      }
      // setPrNumber(prbill.Message[0].billno);
      // useChangedRows.updateValue("bill", prbill.Message[0].billno);

      setRowData(itemsArrayForReturn);

      //       for (let i = 0; i < prbill?.Message?.length; i++) {
      //         let item = prbill?.Message[i].items;
      //         // console.log("formatedrowdata2", item);

      //         let modified = item?.map((item) => {
      //           let modifiedarr = pibill?.Message?.map((it) =>
      //             it.items.filter((subItem) => subItem.itemrecno === item.itemrecno)
      //           );
      //           console.log("modifiedarrto:", modifiedarr, item);
      //           //console.log("modifiedarr", modifiedarr);
      //           if (modifiedarr?.length > 0) {
      //             item.returnableqty = modifiedarr[0][0]?.returnableqty;
      //             item.returnedqty = modifiedarr[0][0]?.returnableqty;
      //             item.currentreturnpackingqty = modifiedarr[0][0].returnqty;
      //             item.currentreturncgstamt = modifiedarr[0][0].cgstamt;
      //             item.currentreturnsgstamt = modifiedarr[0][0].sgstamt;
      //             item.currentreturnamount = modifiedarr[0][0].netamount;
      //             item.currentreturntaxableamount = item.taxableamount;
      //             item.manufacturedate = modifiedarr[0][0].manufacturedate;
      //             item.expdate = modifiedarr[0][0].expdate;
      //             item.packingqty = modifiedarr[0][0].qty;

      //             item.shortguid = modifiedarr[0][0]?.shortguid;
      //             item.currentreturnigstamt = modifiedarr[0][0].igstamt;
      //             item.currentreturnqty = modifiedarr[0][0].returnqty;
      //             // console.log("itembatcharray",item.itembatcharray)
      //             // item.itembatcharray = modifiedarr[0][0].batcharray;
      //             // item.itembatcharray = [];
      //             item.itembatcharray =  item.itembatcharray     // modifiedarr getting null
      //             return item;
      //           }
      //         });

      //         modifiedarrto = modifiedarrto.concat(modified);
      //       }
      // console.log("modifiedarrto111111",modifiedarrto)
      //       setRowData(modifiedarrto);
    }
  };

  // useEffect(() => {
  //   if(barcodeValue){
  //     getBillFromBarcode(barcodeValue)
  //   }
  // }, [barcodeValue])

  //// status changes with dropdown
  const getBillFromBarcode = async (barcodeValue) => {
    if (
      barcodeValue?.itembatcharrayfound[0]?.billsupplierrecno ==
      supplierdata?.supplierrecno
    ) {
      // if(!barcodeValue?.serialnofound){
      //   alert("Scanned item does not belongs to this Supplier");
      // }
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        // domainuserrecno: auth?.state?.userdata?.recno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        billno: barcodeValue?.itembatcharrayfound[0]?.billno,
        supplierrecno: supplierdata?.supplierrecno,
      };

      let response = await getPibill(payload);

      if (barcodeValue?.itembatcharrayfound[0]?.opening == 1) {
        response = await getPurchasebillOpening(payload);
      }

      if (response.Success && response.Message.length > 0) {
        const newdata = response?.Message[0]?.items.map((i) => {
          return { ...i, itembatcharray: [], shortguid: uuidv4() };
        });
        setRowData(newdata);
        var newShortGuid = shortguid.getShortguid("PR");
        setpurchaseshortguid(response.Message[0]?.shortguid);
        useChangedRows.updateValue("shortguid", newShortGuid);
      }
    } else {
      // alert("Scanned item does not belongs to this Supplier");
      auth.state.contextHolder.info({
        message: `An Error has Occured`,
        description: "Scanned item does not belongs to selected Supplier",
        duration: 3,
        style: { background: "#efa7a7" },
      });
    }
  };
  const optionsforDC = [
    {
      id: 1,
      optionName: "PI",
      apiurl: "filterpurchasebilllogs",
      supplierrecno: supplierdata?.supplierrecno,
      doctype: "DC",
    },
  ];

  return (
    <div style={{ ...mainFrame }}>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "message",
            document.getElementById("message").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Supl</p>
          <Input
            id={"message"}
            placeholder="Enter Message for Supl"
            defaultValue={useChangedRows.getValue("message")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search PR No"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setCount((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setCount((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={modalcount}
      >
        <SearchFilterByBillNo
          fastpath="RP104"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>

      {/* //new model  of the search purchase invoice hare  // */}

      <Modal
        title="Search Invoice"
        open={model}
        onOk={() => {
          setModel(false);
          Setcounter((p) => {
            return p + 1;
          });
        }}
        onCancel={() => {
          setModel(false);
          Setcounter((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={model}
      >
        <Purchasesalebillnumber
          fastpath="RP102"
          setshowFilterModal={setModel}
          showFilterModal={model}
          statusC={statusC}
          setstatusC={setstatusC}
          forFlag={"PR"}
          forreturn={true} /// this key is added so that it was required for payload
        />
      </Modal>

      <Modal
        title="Search Opening Invoice"
        open={modelOpening}
        onOk={() => {
          setModelOpening(false);
          Setcounter((p) => {
            console.log("p", p);
            return p + 1;
          });
        }}
        onCancel={() => {
          setModelOpening(false);
          Setcounter((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={counter}
      >
        <Purchasesalebillnumber
          fastpath="RP114"
          setshowFilterModal={setModelOpening}
          showFilterModal={modelOpening}
          statusC={statusC}
          setstatusC={setstatusC}
          forFlag={"PR"}
          forreturn={true} /// this key is added so that it was required for payload
        />
      </Modal>
      {/* here pic from PI */}
      <Modal
        key={displayPrevDC} /// modal count value changes
        width={620}
        open={displayPrevDC}
        onCancel={() => {
          console.log("Set Prev DC CaA in side cancel Button", displayPrevDC);
          setDisplayPrevDC(false);
          setModalcount((p) => p + 1);
          setreturnData([]);
        }}
        onOk={() => {
          console.log("Set Prev DCCCC", returnData);

          setreturnData(JSON.parse(JSON.stringify(returnData)));

          let newArray = returnData?.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor),
              pageDetails?.wantpackingextra
            );


            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              console.log("Set Prev DC444");
              var existingItem = rowData.filter(
                (it) => it && it.itemrecno === item.itemrecno
              );

              if (existingItem.length === 0) {
                console.log("Set Prev DC333");
                // Add item to the list

                console.log(
                  "Purchase Invoice : ",
                  item?.packingqty,
                  " ",
                  item?.packingextraqty
                );
                handleAdd(
                  item,
                  setRowData,
                  item?.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item?.purrate ? item.purrate : item?.rate,
                  //Made Changes to Add Box Pieces from Previous Documents
                  pcsBox[1],
                  pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                  item.freeQuantity,
                  0,
                  item?.itembatchno,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  // item?.amount > 0 ? item.amount : 0,
                  // item?.taxableamount > 0 ? item.taxableamount : 0,
                  // rowData,
                  auth?.state?.userdata?.onedconeinvoice
                );

                ///// commented this line
                // setreturnData([]);
                /////
              } else {
                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item?.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item?.salerate ? item.salerate : item?.rate,
                    //Made Changes to Add Box Pieces from Previous Documents
                    pcsBox[1],
                    pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                    0,
                    item.freeQuantity,
                    item?.itembatchno,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    // item?.amount > 0 ? item.amount : 0,
                    // item?.taxableamount > 0 ? item.taxableamount : 0,
                    // rowData,
                    auth?.state?.userdata?.onedconeinvoice
                  );
                  console.log("setreturnData1");
                  setreturnData([]);
                }, 200);
              }
            }
          });
          console.log("Set Prev DC");
          setDisplayPrevDC(false);
          setModalcount((p) => p + 1);
        }}
      >
        <MultipleSelectionItemPendingDocs
          options={optionsforDC}
          selectedItemsArray={returnData}
          // selectOnlyOneDoc={auth?.state?.userdata?.onedconeinvoice}
          selectOnlyOneDoc={1}
          returnValue={(e) => {
            console.log("seleted DC chalan eee", e);
            // setreturnData([...e]);
            setreturnData(e);
          }}
          forFlag={"pr"} //KMK:31/03/23 Dont Change the place of this Key Value
          todate={prdate}
          setpurchaseshortguid={setpurchaseshortguid}
        />
      </Modal>

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div>
            <form
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "40px", // Adjust the column gap as needed
                rowGap: "10px",
                // marginTop: "5px",
              }}
            >
              <div style={{ ...topRowFilters }}>
                <div
                  style={{
                    pointerEvents: rowData?.length > 0 ? "none" : "auto",
                  }}
                  // task id-278 rajendra   start-end here rowData.length >0 change to rowData.length >=0   ////
                  onKeyDown={enterPress}
                >
                 
                  <SaveDropdown
                    defaultSelected={supplierdata}
                    apikey={"supplierrecno"}
                    minInput={0}
                    inputValue={20}
                    displayLabel={"Supl"}
                    placeholder={"Select Supl"}
                    //{Pradeep task id-398 }/// added isrequired prop
                    isrequired
                    key={modalcount}
                    //className={style.drop}
                    apiurl={getDomainSupplier}
                    selectionLabel={"supplierdescn"}
                    selectionValue={"supplierrecno"}
                    returnValue={setSupplierdata}
                    width="250px"
                    alignment="column"
                    zIndex={1000}
                    disabled={rowData?.length > 0 ? true : false}
                  />
                </div>
                <div style={{ marginTop: "50px", zIndex: 9,position:"relative" }}>
              

                  <ItemSearch
                    itemSearchRef={itemSearch}
                    forFlag={"PR"}
                    returnValue={setSelectedItem}
                    supplierdata={supplierdata?.supplierrecno}
                    dropdown={1}
                    selection={1}
                    barcodeReturnValue={(e) => {
                      

                      const bill = e?.itembatcharrayfound[0];
                      const sidate = bill?.billdate;
                      const dcdate = bill?.inwarddate;
                      // const prdate = new Date()
                      //   .toISOString()
                      //   .slice(0, 10)
                      //   .replace(/-/g, ""); // Assuming prdate is in YYYYMMDD format

                      // Check the conditions for serial number and stock availability
                      console.log("itemsearch e ZCT2250070KGJ00001841255", e,prdate,sidate,sidate && sidate > Number(prdate));
                      if (e?.serialnofound === false) {
                        notification.error({
                          message: `Error`,
                          duration: 1,
                          description: `Serial number not exist in system`,
                          style: { background: "#efa7a7" },
                        });
                        return;
                      } else if (
                        e?.serialnofound === true &&
                        e?.itembatcharrayfound?.length === 0
                      ) {
                        notification.error({
                          message: `Error`,
                          duration: 1,
                          description: `Stock not available`,
                          style: { background: "#efa7a7" },
                        });
                        return;
                      }

                     else if (bill) {
                        if (
                          (sidate && sidate > Number(prdate)) ||
                          (dcdate && dcdate > Number(prdate))
                        ) {
                          notification.error({
                            message: `Error`,
                            duration: 1,
                            description: `PR date less than PI/Latest Inward date`,
                            style: { background: "#efa7a7" },
                          });
                          return 
                        } else {
                          getBillFromBarcode(e);
                        }
                      } else {
                        notification.error({
                          message: `Error`,
                          duration: 1,
                          description: `Stock Not Available`,
                          style: { background: "#efa7a7" },
                        });
                      }
                    }}
                  />
                </div>
                <div onKeyDown={enterPress}>
                  <Button
                    //style={{ ...button }}
                    className="btn"
                    type="default"
                    onClick={() => {
                      setModel(true);
                      Setcounter((p) => p + 1);
                      setstatusC(true);
                      /////////////// Added for deleting data from Tabstore for TR004 tab
                      tabstore.dispatch({
                        type: "UPDATE_TABS_ARRAY",
                        changedRowsValue: {},
                        tabsFastPath: "TR004",
                        keyName: "changedRows",
                      });
                      /////////////////////////////////////////////////////
                    }}
                  >
                    Search Purchase Invoice
                  </Button>
                </div>

                <div onKeyDown={enterPress}>
                  <Button
                    //style={{ ...button }}
                    className="btn"
                    type="default"
                    onClick={() => {
                      setModelOpening(true);
                      Setcounter((p) => p + 1);
                      setstatusC(true);
                      /////////////// Added for deleting data from Tabstore for TR004 tab
                      tabstore.dispatch({
                        type: "UPDATE_TABS_ARRAY",
                        changedRowsValue: {},
                        tabsFastPath: "TR004",
                        keyName: "changedRows",
                      });
                      /////////////////////////////////////////////////////
                    }}
                  >
                    Search Opening Purchase Invoice
                  </Button>
                </div>
                <div
                  onKeyDown={enterPress}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    //style={{ ...buttonStyle }}
                    className="btn"
                    disabled={
                      auth?.state?.userdata?.onedconeinvoice &&
                      rowData.length > 0 &&
                      (rowData[0].doctype == "DC" ||
                        rowData[0].doctype == "SO" ||
                        !rowData[0]?.doctype)
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      if (supplierdata) {
                        setDisplayPrevDC(true);
                        setreturnData([]);
                      } else {
                        alert("Select Supplier");
                      }
                    }}
                  >
                    Pick from PI
                  </Button>
                </div>

                {/* Select Supplier */}
                {/* // task id-278 rajendra   start //// */}
                <div
                  // style={{
                  //   pointerEvents: rowData?.length >= 0 ? "none" : "auto",
                  // }}
                  onKeyDown={enterPress}
                >
                  {/* <UserLocationList
                    setlocation={setlocation}
                    defaultSelection={location}
                    key={modalcount}
                  /> */}

                  <SaveDropdown
                    defaultValue={location}
                    apikey={"locationrecno"}
                    minInput={0}
                    inputValue={20}
                    displayLabel={"Locn"}
                    placeholder={"Select Locn"}
                    //className={style.drop}
                    apiurl={getdomainlocationlistactive} // getdomainlocationlistactive is added from 2nd Dec
                    selectionLabel={"descn"}
                    selectionValue={"locationrecno"} //# previous key was recno, changed to locationrecno as per network response
                    setDataToParent={setlocation}
                    width={"250px"}
                    alignment="column"
                  />
                </div>
                {/* // task id-278 rajendra   end //// */}

                {/* <Button type="dashed">PO Number : TBA</Button> */}
                {/* <div>
                <p>PI Number</p>
                <input
                  style={{ ...poNumberStyle }}
                  // placeholder={prNumber ? prNumber : "TBA"}
                  disabled
                  value={PiNumber}
                  //defaultValue={changerow.billno}  // date 25 april TBA is required as per PM suggestion 
                  // defaultValue={"TBA"}
                />
              </div> */}

                {/* <div onKeyDown={enterPress}>
                  <p>PR Number</p>
                  <input
                    style={{ ...poNumberStyle }}
                    placeholder={prNumber ? prNumber : "TBA"}
                    disabled
                    // defaultValue={changerow.billno ? changerow.billno : "TBA"}  // date 25 april TBA is required as per PM suggestion
                  />
                </div> */}

                <div onKeyDown={enterPress}>
                  <div style={{ position: "relative" }}>
                    <label
                      // htmlFor={id}
                      className={`label ${"focused"}`}
                      style={{
                        // zIndex: 1000,
                        whiteSpace: "nowrap",
                        wordWrap: "unset",
                        position: "absolute",
                        top: "-19px",
                        left: "12px",
                        //backgroundColor: "white",
                        padding: "0 4px",
                        fontSize: "12px",
                        color: "black",
                        pointerEvents: "none",
                        transform: "translateY(0)",
                        transition:
                          "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                      }}
                    >
                      PR Number
                    </label>
                    <input
                      style={{ ...poNumberStyle }}
                      placeholder={prNumber ? prNumber : "To Be Allotted"}
                      disabled
                      // defaultValue={changerow.billno ? changerow.billno : "TBA"}  // date 25 april TBA is required as per PM suggestion
                    />
                  </div>
                </div>
                {/* <Button type="dashed">PO Date : {workdate}</Button> */}
                <div
                  onKeyDown={enterPress}
                  style={
                    rowData && rowData.length > 0
                      ? { pointerEvents: "none", opacity: 0.5 }
                      : {}
                  }
                >
                  {/* <p>PR Date</p>
                <input style={{ ...poNumberStyle }} value={workdate} disabled /> */}
                  <SaveDatePicker
                    displayLabel={"PR Date"}
                    // dateLessthan={workdate}
                    // disabled={fastPath=="TR004"?true:false}
                    disabled={rowData && rowData.length > 0?1:0}
                    placeholder={"Select PR Date"}
                    key={modalcount}
                    apiKey={"trdate"}
                    defaultValue={prdate}
                    id={"pr-date"}
                    dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                    postAlertMsg={`PR Date cannot be greater than Today's Date or less than Opening date`}
                    dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New Date Validation
                    returnValue={(e) => {
                      setPrdate(e);
                      return true;
                    }}
                  />
                </div>

                {/* Status */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onKeyDown={enterPress}
                >
                  <div style={{ position: "relative" }}>
                    {/* <label
                      className={`label ${"focused"}`}
                      style={{
                        // zIndex: 1000,
                        whiteSpace: "nowrap",
                        wordWrap: "unset",
                        position: "absolute",
                        top: "-12px",
                        left: "12px",
                        //backgroundColor: "white",
                        padding: "0 4px",
                        fontSize: "12px",
                        color: "black",
                        pointerEvents: "none",
                        transform: "translateY(0)",
                        transition:
                          "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                      }}
                    >
                      Status
                    </label>
                    <div
                      onKeyDown={enterPress}
                      className="saveproceed"
                      style={{
                        padding: "8.7px",
                        marginTop: "4px",
                      }}
                    >
                      <SaveRadioBtn
                      key={modalcount}
                        apikey={"status"}
                        // defaultValue={pageDetails?.defaultStatus}
                        options={statusOptions}
                        returnValue={(e) => setstatus(e)}
                      />
                    </div> */}
                    <SaveCheckbox
                      displayLabel={"Proceed"}
                      defaultValue={status}
                      editable
                      apikey={"status"}
                      returnValue={(e) => setstatus(e)}
                    />
                  </div>
                </div>

                {/* <div>
                <SaveTextInput
                pageIndex={pageIndex}
                  id={"purchase-return-no"}
                  displayLabel={"PI Number"}
                  defaultValue={null}
                  style={{ width: "150px" }}
                  type="text"
                  apikey={"billno"}
                  returnValue={setPi}
                />
              </div> */}

                {/* <div
              style={{
                border: "1px dashed #dddd",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <SaveDatePicker
                defaultValue={supplierdata?.supplierrecno && expDeliveryDays}
                id={"po-exp-devlivery-date"}
                displayLabel={"Expected Delivery Date"}
                apiKey={"expecteddeliverydate"}
              />
            </div> */}
                {/* <Button
                style={{ ...btnStyles }}
                size="large"
                onClick={handleAdditem}
              >
                Submit
              </Button> */}

                {/* =============================//*Edit Transaction*\\=================================== */}

                {/* <div>
                <Button
                  style={{ ...button }}
                  type="default"
                  onClick={() => {
                    setshowFilterModal(true);
                    setCount((p) => p + 1);
                  }}
                >
                  Search Purchase Return
                </Button>
              </div> */}
                <div onKeyDown={enterPress}>
                  <Button
                    // style={{ ...button }}
                    className="btn"
                    type="default"
                    onClick={(e) => setDisplayMessages(!displayMessages)}
                  >
                    Add / View Messages
                  </Button>
                </div>
                <div onKeyDown={enterPress}>
                  <Button
                    // amol-temp - change in style
                    // style={{ ...button }}
                    className="btn"
                    type="default"
                    onClick={() => {
                      setshowFilterModal(true);
                      setcountRender((p) => p + 1);
                      setModalcount((p) => p + 1);
                    }}
                  >
                    Search PR No.
                  </Button>
                </div>
                {/* <Button
                // amol-temp - change in style
                style={{ ...button }}
                type="default"
                onClick={() => {
                  setshowFilterModal(true);
                  setcountRender((p) => p + 1);
                  setModalcount((p) => p + 1);
                }}
              >
                Search Opening PR No.
              </Button> */}
                {/* amol-temp - added alignment="column"  */}
                {/* <div onKeyDown={enterPress}>
                  <CallbackDropdown
                    displayLabel={"Status"}
                    alignment="column"
                    placeholder={"Select Status"}
                    apiurl={getpostatuslistactive}
                    selectionValue={"code"}
                    selectionLabel={"descn"}
                    returnValue={(e) => {
                      setDropStatus(e?.code);
                    }}
                    defaultSelected={dropstatus}
                    //apiKey="status"
                    key={modalcount}
                    width={"150px"}
                  />
                </div> */}
              </div>
              {/* <Space size="large"> */}
              {/* <div style={{ zIndex: 2, position: "relative" }}>
                <ItemSearch
                  forFlag={"P"}
                  returnValue={setSelectedItem}
                  supplierdata={supplierdata?.supplierrecno}
                  selection
                  dropdown
                />
              </div> */}
              {/* <div style={{ ...callbackTextInputStyles }}> */}
              {/* <CallbackTextInput
                displayLabel="Box"
                id="tr001-box"
                defaultValue={box}
                returnValue={(e) => setBox(e)}
                alignment="column"
                width="100px"
              />
              <CallbackTextInput
                displayLabel="Pieces"
                id="tr001-pieces"
                returnValue={(e) => setSelectedQty(e)}
                defaultValue={selectedQty}
                alignment="column"
                width="100px"
              />
              <CallbackTextInput
                displayLabel="Rate"
                id="tr001-rate"
                returnValue={(e) => setPurchaseRate(e)}
                defaultValue={purchaseRate}
                alignment="column"
                width="100px"
              /> */}
              {/* <div>
                  <p>Batch</p>
                  <input
                    style={{ ...inputStyles }}
                    value={batch}
                    id="tr004-input-batch"
                    onChange={handleBatchChange}
                    type="number"
                  />
                </div>
                <div>
                  <CallbackDateInput
                    id={"exp-date"}
                    displayLabel={"Expiry Date"}
                    apiKey={"expirydate"}
                    returnValue={setexpDate}
                  />
                </div>
                <div>
                  <p>Boxes</p>
                  <input
                    ref={boxRef}
                    style={{ ...inputStyles }}
                    value={box}
                    id="tr004-input-box"
                    onChange={handleBoxChange}
                    type="number"
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.target.value.length > 0 && piecesRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div>
                <p>Pieces</p>
                  <input
                    ref={piecesRef}
                    style={{ ...inputStyles }}
                    value={selectedQty}
                    id="tr004-input-pieces"
                    type="number"
                    onChange={handlePiecesChange}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.target.value.length > 0 && rateRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div>
                  <p>Rate</p>
                  <input
                    ref={rateRef}
                    type="number"
                    style={{ ...inputStyles }}
                    value={purchaseRate}
                    id="tr004-input-rate"
                    onChange={handleRateChange}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        if (event.target.value != 0 && event.target.value.length > 0) {
                          if (box == 0 && selectedQty == 0) {
                            alert("Enter non-zero values in Box / Pieces");
                            boxRef.current.focus();
                          } else {
                            btnRef.current.focus();
                          }
                        } else {
                          alert("Cannot enter 0 as rate");
                        }
                      }
                    }}
                  />
                </div>
                <div>
                  <p>Gross Amount</p>
                  <div style={{ ...grossAmtStyle }}>{Number(grossAmt).toFixed(2)}</div>
                </div>
                <Button
                  ref={btnRef}
                  style={{ ...btnStyles }}
                  icon={<IoAperture size="1.2rem" />}
                  size="large"
                  onClick={() => {
                    if (purchaseRate == 0 || purchaseRate == undefined) {
                      alert("Enter Rate");
                      rateRef.current.focus();
                      return;
                    }
                    addItemstoTable();
                  }}
                >
                  Add
                </Button> */}

              {/* </div> */}
              {/* </Space> */}
              {/* <div style={{ padding: "10px" }}>
              <SaveDropdown
                displayLabel={"Status"}
                apiurl={getpostatuslistactive}
                selectionValue={"code"}
                selectionLabel={"descn"}
                // returnValue={(e)=>{
                  
                //   setDropStatus(e?.code)
                // }}
                apiKey="status"
               // key={modalcount}
              />
            </div> */}
            </form>
          </div>
        </Panel>
        <Panel header="Items Details" key="2">
          <div style={{ margin: "24px auto 0" }}>
            {rowData?.length > 0 && (
              <>
                <ItemsTable
                  // rowData={rowData.filter((item) => item.returnableqty !== 0)}
                  rowData={rowData}
                  setRowData={setRowData}
                  // igstappl={igstappl} // setDatapayload={setDatapayload}  igstappl and igstrate validation
                  igstappl={
                    typeof igstappl === "number" ||
                    (typeof igstappl === "string" && parseFloat(igstappl) > 0)
                      ? true
                      : typeof igstappl === "boolean"
                      ? igstappl
                      : false
                  } /// here the igstapp getting string vale its converted to Number
                  pageDetails={pageDetails}
                  changerow={changerow}
                  trdate={prdate}
                />
              </>
            )}
            {/* <>
<ItemsTable
  rowData={rowData}
  setRowData={setRowData}
  // igstappl={igstappl}
  igstappl={igstappl > 0 ? true : false}
  pageDetails={pageDetails}
/>
</> */}
          </div>
        </Panel>
      </Collapse>

      {/* Table for Selection  */}
    </div>
  );
}

export default PurchaseReturn;
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "100w",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  // amol-temp - added flexWrap
  flexWrap: "wrap",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "36px",
  paddingTop: "38px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "10px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "120px",
  borderRadius: "8px",
  marginTop: "6px",
};

// amol-temp - value changes
const poNumberStyle = {
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  height: "39px",
};
// amol-temp -  commented width and borderRadius and height changed to 36px from 40px
const button = {
  marginTop: "27px",
  textAlign: "right",
  border: "1px solid #dfdfdf",
  height: "36px",
  // borderRadius: "8px",
  // width: "200px",
};

//~# getdomainsupplieritemlist api endpoint for Item name => pass payload as { supplierrecno : 78123 } for item name
//~# conversionfactor = boxes * conversionfactor + pieces =
