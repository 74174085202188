// // Kaushik Koparkar 6/01/2023
import { Button, Collapse, Input, Modal, Space, Table, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getToday,
  dateFormatter,
  addDays,
  convertToBoxAndPieces,
  enterPress,
} from "../../CommonControllers/AppFunction";
import BillingTable from "../../CommonControllers/Components/BillingTableComponents/BillingTable";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";

import { handleAdd, poColumns } from "./PurchaseOrderBillingTableColumns";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import SaveDropDown from "../../CommonControllers/SaveComponents/SaveDropDown";
import {
  domainLocationURL,
  getDomainSupplier,
  userlocationlist,
} from "../../CommonControllers/Urls";
import "../../CommonControllers/global.css";
import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import { IoAperture } from "react-icons/io5";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import { currentTabData } from "../../CommonControllers/Variable";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import ItemsTable from "./ItemsTable";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";

import NewSaveDatePicker from "../../CommonControllers/NewComponents/SaveComponents/NewSaveDatePicker";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";

function PurchaseOrder() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [purchaseRate, setPurchaseRate] = useState(" ");
  const [boxpurchaseRate, setBoxPurchaseRate] = useState(" "); 

  const [selectedQty, setSelectedQty] = useState(" "); // Qty as in pieces in UI, total = pieces * rate
  const auth = useContext(AuthStore);

  const [box, setBox] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [expDeliveryDate, setExpDeliveryDate] = useState(0); // (useChangedRows.getValue("expecteddeliverydate") ? useChangedRows.getValue("expecteddeliverydate") : auth.state.userdata.workdate);

  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  // render
  const [countRender, setcountRender] = useState(0);
  const [poNumber, setPoNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------

  const { getShortguid } = useShortguid();

  //~# State variable for Po date comparision
  const [expDelDate, setExpDelDate] = useState(0);

  const { Panel } = Collapse;
  const [poDate, setPoDate] = useState();
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const rateRef = useRef(null);
  const btnRef = useRef(null);

  const searchref = useRef(null);
  const [pageDetails, setPageDetails] = useState();
  const [status, setstatus] = useState(true);

  const [transactionEdit, setTransactionEdit] = useState(false);

  console.log("Transaction Edit is", pageDetails);

  const [pageIndex, setPageIndex] = useState();
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR001") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    getFastPathDetails();
  }, [pageIndex]);

  // Function to Add FastPathDetails
  function getFastPathDetails() {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      domainuserrecno: auth.state.userdata.recno,
      fastpath: tabStore.state.tabs[pageIndex]?.name,
    };
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
      }
    });
  }

  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    console.log(
      "tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit",
      tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit
    );
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setTransactionEdit(true);

      // setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
      const newdata = tabStore.state.tabs[pageIndex]?.changedRows?.items.map((i) => {
        return { ...i, boxpurrate: Number(i?.purrate) * Number(i?.conversionfactor) };
      });
      setRowData(newdata);
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  ////dropdown clear//////

  console.log("Data of the Tab Store is ", tabStore.state.tabs[pageIndex]);

  const [modalcount, setModalCount] = useState(0);

  // KMK 06/04/2023 For Narration and Remarks
  const [narration, setNarration] = useState("");
  const [remarks, setRemarks] = useState("");

  // KMK 06/04/2023 for automatically fetching the Narration and Remarks

  useEffect(() => {
    if (!narration) {
      if (useChangedRows.getValue("narration")) {
        setNarration(useChangedRows.getValue("narration"));
      }
    }

    if (!remarks) {
      if (useChangedRows.getValue("remarks")) {
        setRemarks(useChangedRows.getValue("remarks"));
      }
    }
  }, [
    useChangedRows.getValue("remarks"),
    useChangedRows.getValue("narration"),
  ]);

  let grossAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
      Number(purchaseRate) || "00";

  useEffect(() => {
    getSelectedItem?.purrate !== undefined
      ? setPurchaseRate(getSelectedItem?.purrate)
      : setPurchaseRate("");

      getSelectedItem?.purrate !== undefined
      ? setBoxPurchaseRate(getSelectedItem?.purrate * getSelectedItem?.conversionfactor)
      : setBoxPurchaseRate("");
  }, [getSelectedItem?.purrate]);

  console.log("purchaseRate", purchaseRate);
  console.log("tr001 item ln 52 ==>", purchaseRate);
  //~# Setting workdate
  let formattedWorkdate = auth?.state?.workdate;
  formattedWorkdate = JSON.stringify(formattedWorkdate);
  formattedWorkdate = `${formattedWorkdate?.slice(
    -2
  )}/${formattedWorkdate?.slice(4, 6)}/${formattedWorkdate?.slice(0, 4)}`;

  function dateSetter() {
    const expDays = supplierdata?.expdeliverydays;
    // let expdate = dateFormatter(addDays(auth?.state?.workdate, expDays));
    console.log(
      "Po Date in Purchase Order is Use Changed Rows",
      useChangedRows.getValue("expecteddeliverydate"),
      useChangedRows.getValue("trdate"),
      poDate
    );
    var podate = poDate ? poDate : auth?.state?.workdate;
    let expdate = addDays(podate, expDays);

    setExpDeliveryDate(expdate);
    useChangedRows.updateValue("expecteddeliverydate", expdate); // KMK 2nd Apr. For expected Date Validation

    return expdate ?? "NA"; // if undefined return NA
  }
  console.log("PO DATE IS", poDate);
  useEffect(() => {
    // if (useChangedRows.getValue("trdate")) {
    //   setPoDate(useChangedRows.getValue("trdate"));
    // } else {
    //   setPoDate(auth?.state?.workdate);
    //   useChangedRows.updateValue("trdate", auth?.state?.workdate);
    // }

    if (useChangedRows.getValue("expecteddeliverydate")) {
      setExpDeliveryDate(useChangedRows.getValue("expecteddeliverydate"));
    } else {
      setExpDeliveryDate(auth?.state?.workdate);
      useChangedRows.updateValue("expecteddeliverydate", auth?.state?.workdate);
    }
  }, [tabStore.state.tabs[tabStore.state.tabIndex].changedRows?.trdate]);

  useEffect(() => {
    if (useChangedRows.getValue("expecteddeliverydate") < poDate) {
      dateSetter();
    }
  }, [poDate]);

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);
  }

  function handleBoxChange(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setBox(event.target.value);
    }
  }

  function handlePiecesChange(event) {
    const regex = /^[0-9\b]+$/;
    const value = event.target.value;
    if (value === "" || regex.test(value)) {
      setSelectedQty(value);
    }
  }

  const handleRateChange = (event) => {
    console.log("purchaseRate Change", getSelectedItem?.changepurrateinbill);
    if (getSelectedItem?.changepurrateinbill) {
      if (box == 0 && selectedQty == 0) {
        alert("Enter Box or pieces");
        box == 0 ? boxRef.current.focus() : piecesRef.current.focus();
        return;
      }
      const regex = /^\d*(\.\d{0,2})?$/;
      const value = event.target.value;

      if (value == "0.00" || value == "0.0") {
        alert("Cannot enter 0.00 as rate");
        return false;
      }

      if (value === "" || regex.test(value)) {
        setPurchaseRate(value);
      }
    }
    //~# Regex checking for decimal upto two places
  };

  const addItemstoTable = () => {
    console.log("addItemstoTable", getSelectedItem);
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      console.log("existingItem", existingItem);
      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");
        setSelectedQty("");

        // setPurchaseRate("");
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          selectedQty,
          box
        );
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        setBox("");
        setSelectedQty("");

        let items = rowData;
        // Change the Rows Index
        let index = items.findIndex(
          (item) => item.itemrecno === getSelectedItem.itemrecno
        );

        // If the item is already at the top of the array, do nothing
        if (index === 0) {
          return;
        }

        // Remove the item from its current position in the array
        let item = items.splice(index, 1)[0];

        // Insert the item at the beginning of the array
        items.unshift(item);

        setRowData(() => [...items]);
      }
    }
  };

  useEffect(() => {
    if (supplierdata?.igstappl) {
      setigstappl(true);
      // dateSetter();
    } else {
      setigstappl(false);
      // dateSetter();
    }
    if (rowData.length > 0) {
    } else {
      dateSetter();
    }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();
    // Calculate Other Numbers and Inputs and store in the tabStore
    // var status = "P";
    var approvalStatus = "P";

    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    // var shortguid;

    amount = rowData.reduce((total, item) => total + Number(item.amount), 0);
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item?.igstamt), 0);
    console.log("tr001 ln96 changed data ==>11", existingObject, rowData);

    existingObject = {
      ...existingObject,
      cgstamt: cgstamt,
      igstamt: igstamt,
      sgstamt: sgstamt,
      taxableamount: taxableamount,
      // expecteddeliverydate: expDeliveryDate,
      trdate: poDate || auth?.state?.userdata?.workdate, //~# workdate here
      amount: amount,
      items: rowData,
      status: status ? statusOptions[1]?.value : statusOptions[0]?.value,
      shortguid: getShortguid("PO"),
      //~! remove
    };

    //console.log("tr001 ln96 changed data ==>", existingObject);
    useChangedRows.updateValue(false, existingObject);
  }, [rowData, status]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PO"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items?.length > 0) {
        console.log("Items Are Items", items);
        setTransactionEdit(true);
        // setRowData(items);
        const newdata = items.map((i) => {
          return { ...i, boxpurrate: Number(i?.purrate) * Number(i?.conversionfactor) };
        });
        setRowData(newdata);
      }
    }
  }, []);

  useEffect(() => {
    let isButtonDisabled = false;

    if (!supplierdata || rowData.length == 0 || poDate <= 0) {
      isButtonDisabled = true;
    } else {
      for (let i = 0; i < rowData.length; i++) {
        const { packingqty, packingextraqty, purrate } = rowData[i];

        if (
          (packingqty === "0" && packingextraqty === "0") ||
          (packingqty == "" && packingextraqty == "") ||
          purrate == 0 ||
          purrate == "" ||
          (packingqty === 0 && packingextraqty === "0") ||
          (packingqty === "0" && packingextraqty === 0) ||
          (packingqty === 0 && packingextraqty === 0)
        ) {
          isButtonDisabled = true;
          console.log("isButtonDisabled:122", isButtonDisabled);
          break;
        }
      }
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
    useChangedRows.updateValue("dataFromEdit", false);
  }, [rowData, supplierdata, poDate]);

  useEffect(() => {
    useChangedRows.updateValue("dataFromEdit", false);
  }, [rowData]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PO"));
    }
    setSupplierdata(useChangedRows.getValue("supplierrecno"));
    setPoNumber(useChangedRows.getValue("ponumber"));
    setlocation(useChangedRows.getValue("locationrecno"));
    setExpDeliveryDate(useChangedRows.getValue("expecteddeliverydate"));
    setPoDate(useChangedRows.getValue("trdate"));

    var items = useChangedRows.getValue("items");
    if (items) {
      setRowData(() => items);
    }
  }, [showFilterModal]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setSelectedQty(" ");
      setPurchaseRate(" ");
      setlocation(null);
      setExpDeliveryDate(0);
      setDatatoSupplierdata(null);
      setPoDate(auth.state?.workdate);
      setPoNumber("To Be Allotted");
      setStatusOptions([
        {
          label: "Save",
          value: pageDetails?.defaultStatus,
        },
        {
          label: "Proceed",
          value: pageDetails?.nextStatus,
        },
      ]);
      setstatus(true);
      setTransactionEdit(false);
      // document.getElementById("remarks").value = ""
      // document.getElementById("narration").value = ""
      useChangedRows.updateValue("remarks", "");
      useChangedRows.updateValue("narration", "");

      // To Clear add Data from Additional Options
      setPageDetails();
      getFastPathDetails();
    }
    setModalCount((p) => p + 1);
    setTimeout(
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      }),
      1000
    );
    setTimeout(getFastPathDetails(), 1000);
    // console.log("TR001 changed rows data ?? ==>", expDelDate);
  }, [
    tabStore.state.tabs[pageIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows).length,
  ]);

  // console.log("tr001 ln 206", rowData, poColumns(), supplierdata, expDeliveryDays);

  ////// Tabfunction
  const Tabfunction = (e) => {
    console.log("TR004 changed rows data===", e);
    const allElements = document.querySelectorAll("*");
    const currentIndex = Array.from(allElements).indexOf(e.target);
    let nextIndex = currentIndex + 1;
    let nextElement = allElements[nextIndex];

    if (nextElement && nextElement.focus) {
      nextElement.focus();
    }
  };

  return (
    <div style={{ ...mainFrame }}>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setRemarks(useChangedRows.getValue("remarks"));
          setNarration(useChangedRows.getValue("narration"));
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue("remarks", remarks);
          useChangedRows.updateValue("narration", narration);
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Supl</p>
          <Input
            id={"remarks"}
            placeholder="Enter Message for Supl"
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            value={narration}
            onChange={(e) => {
              setNarration(e.target.value);
            }}
          ></Input>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={countRender}
      >
        <SearchFilterByBillNo
          fastpath="RP101"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        {/* amol-390 - Panel name changes from "selection frame" to "Header details" */}
        <Panel header="Header Details" key="1">
          {/* amol-390 - Alignment of Print checkbox in top right corner of document details panel */}

          <form
            style={{
              // display: "flex",
              // gridTemplateColumns: "repeat(6, 1fr)",
              // columnGap: "24px",
              display: "flex",
              flexWrap: "wrap",
              columnGap: "40px", // Adjust the column gap as needed
              rowGap: "15px",
              marginTop:"5px"
            }}
          >
            {/* Select Supplier */}

            <div
              style={{ zIndex: 1000, marginTop: "10px" }}
              onKeyDown={enterPress}
            >
              <SaveDropdown
                defaultSelected={location}
                //{Pradeep task id-398 }/// added isrequired prop
                placeholder={"Select Locn"}
                key={modalcount}
                zIndex={1000}
                // disabled={rowData?.length > 0 || transactionEdit ? true : false}
                apikey={"locationrecno"}
                minInput={0}
                inputValue={20}
                displayLabel={"Locn"}
                apiurl={userlocationlist}
                selectionLabel={"descn"}
                selectionValue={"locationrecno"} //# previous key was recno, changed to locationrecno as per network response
                returnValue={setlocation}
                width={"250px"}
                alignment="column"
              />
            </div>

            <div
              onKeyDown={enterPress}
              style={{
                pointerEvents: rowData?.length > 0 ? "none" : "auto",
                marginTop: "10px",
              }}
            >
              <SaveDropdown
                defaultSelected={supplierdata}
                apikey={"supplierrecno"}
                minInput={0}
                inputValue={20}
                displayLabel={"Supl"}
                placeholder={"Select Supl"}
                //{Pradeep task id-398 }/// added isrequired prop
                isrequired
                key={modalcount}
                apiurl={getDomainSupplier}
                selectionLabel={"supplierdescn"}
                selectionValue={"supplierrecno"}
                returnValue={setDatatoSupplierdata}
                width="250px"
                alignment="column"
                zIndex={1000}
                disabled={rowData?.length > 0 || transactionEdit ? true : false}
              />
            </div>

            <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
              <div style={{ position: "relative" }}>
                <label
                  // htmlFor={id}
                  className={`label ${"focused"}`}
                  style={{
                    zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-19px",
                    left: "12px",
                    //backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  PO No.
                </label>
                <input
                  style={{ ...poNumberStyle, marginTop: "0px" }}
                  value={poNumber ? poNumber :"To Be Allotted"}
                  disabled
                />
              </div>
            </div>

            <div
              onKeyDown={enterPress}
              style={{
                pointerEvents: transactionEdit ? "none" : "auto",
                marginTop: "10px",
              }}
            >
              <SaveDatePicker
                disabled={(transactionEdit ||rowData.length>0)? true : false}
                defaultValue={poDate}
                placeholder={"Select PO Date"}
                id={"po-date"}
                displayLabel={"PO-Date"}
                apiKey={"trdate"}
                // dateLessthan={auth?.state?.userdata?.workdate || expDelDate} //~^ This func is for date lesser than workdate
                // postAlertMsg={"PO Date cannot be greater than Work Date"}
                returnValue={(e) => {
                  setPoDate(e);
                }}
                dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                postAlertMsg={`PO Date cannot be greater than Today's Date or less than Opening date`}
                dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New Date Validation
              />
              {/* <SupplierSaveDropDown/> */}
            </div>

            {/* Status */}
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }} 
              onKeyDown={enterPress}>
              <div style={{ position: "relative" }}>
                {/* <label
                  className={`label ${"focused"}`}
                  style={{
                    zIndex: 999,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-12px",
                    left: "12px",
                    //backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  Status
                </label>
                <div
                  onKeyDown={enterPress}
                  className="saveproceed"
                  style={{
                    padding: "8.7px",
                    marginTop: "4px",
                  }}
                >
                  <SaveRadioBtn
                    apikey={"status"}
                    key={modalcount}
                    // defaultValue={pageDetails?.defaultStatus}
                    options={statusOptions}
                  />
                </div> */}
                <SaveCheckbox 
                  displayLabel={"Proceed"}
                  defaultValue={status}
                  editable
                  apikey={"status"}
                  returnValue={(e) => setstatus(e) }                    
                />
              </div>
            </div>

            <div
              onKeyDown={enterPress}
              style={{
                textAlign: "right",
                marginTop: "10px",
                // marginLeft: "10px",
              }}
            >
              <AdditionalDetails
                filterOption={pageDetails?.additionalDetails}
              />
            </div>
            <div
              onKeyDown={enterPress}
              style={{ textAlign: "right", marginTop: "8px" }}
            >
              <Button
                // amol-390 - added style
                //style={{ ...button }}
                className="btn"
                type="default"
                onClick={() => {
                  setshowFilterModal(true);
                  setcountRender((p) => p + 1);
                }}
              >
                Search Purchase Order
              </Button>
            </div>
            <div
              onKeyDown={enterPress}
              style={{
                textAlign: "right",
                marginTop: "8px",
                // marginLeft: "10px",
              }}
            >
              <Button
                // amol-390 - added style
                //style={{ ...button }}
                className="btn"
                type="default"
                onClick={(e) => setDisplayMessages(!displayMessages)}
              >
                Add / View Messages
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onKeyDown={enterPress}
            >
              <SaveCheckbox
                apikey={"isPrint"}
                displayLabel="Print PO"
                editable
                defaultValue={false}
                key={modalcount}
              />
            </div>
          </form>
        </Panel>
        {/* amol-wms Created Panel for Additional Details*/}

        {/* amol-wms Created Panel for Items Details*/}
        <Panel header="Items Details" key="3">
          <Space size="large" style={{ alignItems: "end" }}>
            <div style={{ alignItems: "center" }}>
              <form style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                <div
                  style={{
                    zIndex: 2,
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onKeyDown={enterPress}
                >
                  <div>
                    {" "}
                    <div>
                      <ItemSearch
                        forFlag={"P"}
                        returnValue={setSelectedItem}
                        supplierdata={supplierdata?.supplierrecno}
                        selection={false}
                        dropdown
                        trdate={poDate}
                        forPage={"PO"}
                        onKeyDown={(e) => {
                          if (e.keyCode == 13 && getSelectedItem) {
                            boxRef.current.focus();
                          }
                        }}
                        defaultSelectedItem={getSelectedItem}
                        itemSearchRef={searchref}
                      />
                    </div>
                    <div>
                      {getSelectedItem ? (
                        <p>
                          {getSelectedItem?.itemdescn} <br />
                          Available Qty : {getSelectedItem?.availablestock}{" "}
                          &nbsp; Pending PO Qty :{" "}
                          {getSelectedItem?.pendingpoqty} &nbsp; Pending SO Qty
                          : {getSelectedItem?.pendingsoqty}{" "}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                {getSelectedItem?.packingextrarecno > 0 &&
                pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress} style={{ marginTop: "30px" }}>
                    <p>
                      Boxes<span style={{ color: "red" }}>*</span>:{" "}
                      {getSelectedItem?.conversionfactor}
                    </p>
                    <input
                      ref={boxRef}
                      style={{ ...inputStyles }}
                      value={box}
                      id="tr001-input-box"
                      onChange={handleBoxChange}
                      type="number"
                      onKeyDown={(event) => {
                        const { keyCode } = event;
                        if (keyCode == 13) {
                          event.target.value.length > 0 &&
                            piecesRef.current.focus();
                        }
                      }}
                    />
                  </div>
                ) : null}

                <div onKeyDown={enterPress} style={{}}>
                  <p>
                    Pieces <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    ref={piecesRef}
                    style={{ ...inputStyles }}
                    value={selectedQty}
                    id="tr001-input-pieces"
                    type="number"
                    onChange={handlePiecesChange}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.target.value.length > 0 &&
                          rateRef.current.focus();
                      }
                    }}
                  />
                </div>

                {getSelectedItem?.packingextrarecno > 0 &&
                pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress} style={{}}>
                    <p>Box Rate</p>
                    <input
                      ref={rateRef}
                      type="number"
                      style={{ ...inputStyles }}
                      value={boxpurchaseRate}
                      id="tr001-input-rate"
                      disabled={
                        true
                      } 
                    />
                  </div>
                ) : null}

                <div onKeyDown={enterPress} style={{}}>
                  <p>Rate</p>
                  <input
                    ref={rateRef}
                    type="number"
                    style={{ ...inputStyles }}
                    value={purchaseRate}
                    id="tr001-input-rate"
                    disabled={
                      pageDetails?.changepurrateinbill === 0 ? true : false
                    } //  17 Oct 2023
                    onChange={handleRateChange}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        if (
                          (event.target.value != 0 ||
                            event.target.value != "0.00") &&
                          event.target.value.length > 0
                        ) {
                          if (box == 0 && selectedQty == 0) {
                            alert("Enter non-zero values in Box / Pieces");
                            boxRef.current.focus();
                          } else {
                            btnRef.current.focus();
                          }
                        } else {
                          alert("Cannot enter 0 / 0.00 as rate");
                        }
                      }
                    }}
                  />
                </div>

                <div onKeyDown={enterPress} style={{}}>
                  <p>Taxable Amount</p>
                  <input disabled value={Number(grossAmt).toFixed(2)}style={{ ...grossAmtStyle }}/>
                </div>
                <div>
                  <Button
                    ref={btnRef}
                    //style={{ ...btnStyles }}
                    className="btn item-search-btn"
                    icon={<IoAperture size="1.2rem" />}
                    size="large"
                    onClick={() => {
                      if (purchaseRate == 0 || purchaseRate == undefined) {
                        alert("Enter Rate");
                        rateRef.current.focus();
                        return;
                      }

                      addItemstoTable();
                      searchref?.current.focus();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </form>
            </div>
          </Space>

          {/* Table for Selection */}
          <div
            style={{ zIndex: 1, position: "relative", margin: "24px auto 0" }}
          >
            {rowData.length > 0 && (
              <ItemsTable
                rowData={rowData}
                setRowData={setRowData}
                igstappl={igstappl}
                pageDetails={pageDetails}
              />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default PurchaseOrder;
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "36px",
  paddingTop: "38px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  // amol-390 - changes in CSS - marginTop 31px to 27px, gap 8px to 24px
  gap: "24px",
  marginTop: "30px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  // amol-390 - changes in CSS - marginTop 7px to 5px
  // marginTop: "5px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "5px 8px 5px 5px",
  fontSize: "18px",
  width: "120px",
  borderRadius: "8px",
  // amol-390 - changes in CSS - marginTop 11px to 5px
  marginTop: "5px",
};
// amol-temp - value changes
const poNumberStyle = {
  marginTop: "8px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  height: "39px",
};

const button = {
  marginTop: "7px",
  textAlign: "right",
  border: "1px solid #dfdfdfdf",
  height: "37px",
  boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  backgroundColor: "#87CEEB",
};

//~# getdomainsupplieritemlist api endpoint for Item name => pass payload as { supplierrecno : 78123 } for item name
//~# conversionfactor = boxes * conversionfactor + pieces =
