import { useState, useEffect, useContext, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button } from "antd";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import TextInput from "../../../CommonControllers/Components/TextInput/index.tsx";
import { AuthStore } from "../../../Auth/AuthStore";
import { TabStore } from "../../../TabsStructure/TabsStore";

import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";
import SaveDatePicker from "../../../CommonControllers/SaveComponents/SaveDatePicker";
import SaveTextInput from "../../../CommonControllers/Components/SaveComponents/SaveTextInput";

import SaveDropDownAccounts from "./SaveDropDownAccounts";

import PendingInsutrumentsTransaction from "./PendingInstrumentsTransaction";
import {
  VOUCHER_RECEIPT,
  VOUCHER_PAYMENT,
} from "../Transaction/TransactionHelper";

import {
  transformPendingInsutrments,
  generateAccountingEntryPayLoad_TabStore,
  clearAutoAdjustements,
  LoadPaymentVoucherForEdit,
  LoadReceiptVoucherForEdit,
  getSummary,
} from "./TransactionHelper";

import {
  VOUCHER_TYPE_RECEIPT,
  VOUCHER_TYPE_PAYMENT,
  CREDIT,
  DEBIT,
} from "./TransactionHelper";

import {
  useCashTransactionDispatch,
  useTransactionContext,
  useTransactionDispatch,
} from "./TransactionContext";

import { getAccountlist } from "../../../CommonControllers/ApiCalls";
import { addTransactions } from "../../../CommonControllers/ApiCalls";
import { getScroll } from "../../../CommonControllers/ApiCalls";

import { getAccountlistURL } from "../../../CommonControllers/Urls";

import "./style.css";

import { Voucher_Account } from "./TransactionHelper";
import { enterPress } from "../../../CommonControllers/AppFunction.js";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown.js";
import useSaveChangedRows from "../../../CommonControllers/Hooks/useSaveChangedRows.js";

function TransactionScreen(props) {
  const [glclear, setglclear] = useState(false);

  const [referenceclear, setreferenceclear] = useState(false);

  const tabstore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [creditcontrol, setcreditcontrol] = useState(false);
  const [valueTab, setValueTab] = useState(creditcontrol ? 1 : 0);
  const [voucherInProgress, setVoucherInProgress] = useState(false);
  //const [triggerDistribute, settriggerDistribute] = useState(0);
  const useChangedRows = useSaveChangedRows();
  const [account1label, setAccount1label] = useState(null);
  const [account2label, setAccount2label] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [onAccountAmount, setonAccountAmount] = useState(0);
  const [localstate, setlocalState] = useState(0);
  const [amount, setamount] = useState();
  const [account1labelEdit, setAccount1labelEdit] = useState(null);
  const [account2labelEdit, setAccount2labelEdit] = useState(null);
  const [summary, setSummary] = useState(null);
  const [account1, setaccount1] = useState(null);
  const [account2, setaccount2] = useState(null);
  const [narration, setnarration] = useState("")
  const [mode, setmode] = useState("ADD");
  /// here Ternary operator for Checque

  const [cheque, setcheque] = useState(null);

  console.log("TransactionScreen Render", summary);

  //// here initialise the Date component

  const [Vdate, setVdate] = useState(auth.state?.workdate);
  const [pageIndex, setPageIndex] = useState();
  useEffect(() => {
    var tabsArray = tabstore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name ==props?.fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  //console.log(auth?.state?.workdate);


  const gllistbody = {
    gldetailtype: "'B','A'",
    withcashbank: props.vouchertype == "PAYMENT" ? 1 : 2
  };

  useEffect(() => {
    if (
      tabstore?.state?.tabs[pageIndex]?.pageStatus == "Success"
    ) {
      console.log("Page Status Changed");

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "ourreference",
        text: "",
      });
      //Vdate voucher date initialise logic start
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucherdate",
        text: Vdate,
      });
      //Vdate voucher date initialise logic end
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "narration",
        text: "",
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "partyreference",
        text: "",
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "VOUCHER_AMT_UPDATED",
        text: "",
      });
      //Vdate voucher date initialise logic start commented out
      // let voucherdate =
      //   tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
      //     ?.voucherdate;

      // // console.log("voucherdate = ", voucherdate);
      // if (!voucherdate) {
      //   tabstore.dispatch({
      //     type: "UPDATE_CHANGED_DATA",
      //     fieldType: "voucherdate",
      //     text: auth.state?.workdate,
      //   });
      // }
      //Vdate voucher date initialise logic start
      let chequedate =
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.chequedate;

      // console.log("chequedate = ", chequedate);
      if (!chequedate) {
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "chequedate",
          text: auth.state?.workdate,
        });
      }

      generateAPIData();
      console.log("set local state page change");
      setlocalState((prev) => prev + 1);

      tabstore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "trigger_distribute",
        text: "NO",
      });

      if (props.vouchertype == "RECEIPT") {
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "voucher_type",
          text: VOUCHER_TYPE_RECEIPT,
        });
      } else if (props.vouchertype == "PAYMENT") {
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "voucher_type",
          text: VOUCHER_TYPE_PAYMENT,
        });
      }

      document.getElementById("txtamount").value = "";
      setmode("ADD");
      setAccount1labelEdit("");
    }
  }, [tabstore?.state?.tabs[pageIndex]?.pageStatus]);

  useEffect(() => {
    //calculateOnAccountAmount();
    generateAccountingEntryNew();
    setSummary(
      getSummary(
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.pending_instruments,
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.VOUCHER_AMT_UPDATED
      )
    );

  }, [localstate]);
 
  useEffect(() => {
    console.log("Vdate",Vdate)
    let isButtonDisabled = false;
    if(Vdate === "" || Vdate === null){
      //alert("Voucher Date is Compulsory")
      isButtonDisabled = true;
    } else if(account1 === "" || account1 === null){
      isButtonDisabled = true;
    } else if(account2 === "" || account2=== null){
      isButtonDisabled = true;
    } else if(amount < 0 || amount <= 0 || amount === "" || amount === null){
      isButtonDisabled = true;
    } else if(narration === "" || narration === null){
      isButtonDisabled = true;
    }
    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [localstate])
  

  useEffect(() => {
    setlocalState(localstate + 1);
    setSummary(
      getSummary(
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.pending_instruments,
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.VOUCHER_AMT_UPDATED
      )
    );
  }, [tabstore?.state?.tabs[pageIndex]?.changedRows]);

  useEffect(() => {
    console.log("props.vouchertype", props.vouchertype);

    if (props.vouchertype == "RECEIPT") {
      setAccount1label("Received from");
      setAccount2label("Received In");

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucher_type",
        text: VOUCHER_TYPE_RECEIPT,
      });
    } else if (props.vouchertype == "PAYMENT") {
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucher_type",
        text: VOUCHER_TYPE_PAYMENT,
      });

      setAccount1label("Paid to");
      setAccount2label("Paid from");
    }

    let voucherdate =
      tabstore?.state?.tabs[pageIndex]?.changedRows?.voucherdate;

    // console.log("voucherdate = ", voucherdate);
    if (!voucherdate) {
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucherdate",
        text: auth.state?.workdate,
      });
    }

    let chequedate =
      tabstore?.state?.tabs[pageIndex]?.changedRows?.chequedate;

    // console.log("chequedate = ", chequedate);
    if (!chequedate) {
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "chequedate",
        text: auth.state?.workdate,
      });
    }
  }, [props.vouchertype, localstate]);
  //// here localstate is adde as dependency for setting the vaoucher_type after each click of the adjust amount button

  function generateAPIData() {
    /*
    let changedrows =
      tabstore?.state?.tabs[pageIndex]?.changedRows;
    */
    const changedrows =
      tabstore?.state?.tabs[pageIndex]?.changedRows;

    console.log(
      "changedrows4456465",
      !changedrows.hasOwnProperty("narration") ||
      changedrows.hasOwnProperty("narration") == ""
    );
    console.log(changedrows);
    let validations = [];

    // validate data before sending api data to tab store

    if (!changedrows.hasOwnProperty("gl_account_1") && props.vouchertype == "PAYMENT") {
      validations.push("Payment to Party not selected.");
    } else if (!changedrows.hasOwnProperty("gl_account_1") && props.vouchertype == "RECEIPT") {
      validations.push("Receipt From Party not selected.");
    }
    //narration validation added
    if (
      !changedrows.hasOwnProperty("narration") ||
      changedrows["narration"] == ""
    ) {
      validations.push("Narrationn is not Entered.");
    }

    if (!changedrows.hasOwnProperty("gl_account_2") && props.vouchertype == "PAYMENT") {
      validations.push("Paid from not selected.");
    } else if (!changedrows.hasOwnProperty("gl_account_2") && props.vouchertype == "RECEIPT") {
      validations.push("Received in not selected.");
    }

    if (!changedrows.hasOwnProperty("voucherdate")) {
      validations.push("Voucher Date not selected.");
    } else {
      let _tempdate = changedrows.hasOwnProperty("voucherdate");
      if (_tempdate == undefined) {
        validations.push("Invalid Voucher Date.");
      }
      if (_tempdate == null) {
        validations.push("Invalid Voucher Date.");
      }
    }

    if (changedrows.hasOwnProperty("VOUCHER_AMT_UPDATED") === false) {
      validations.push("Amount not entered.");
    } else {
      let amt = 0;
      amt = changedrows.hasOwnProperty("VOUCHER_AMT_UPDATED");
      if (amt <= 0) {
        validations.push("Invalid Vocuher Amount.");
      }
    }

    // Validation on Account Amount Should not be more than Paid / Recived Amt

    let voucher_amt_temp = changedrows?.VOUCHER_AMT_UPDATED;

    const sumTemp = changedrows?.pending_instruments?.reduce(
      (sum, cur) => sum + cur.on_account_amount,
      0
    );

    console.log("sumTemp1", voucher_amt_temp);
    console.log("sumTemp2", sumTemp);
    if (Number(sumTemp) > Number(voucher_amt_temp)) {
      validations.push("Paid Amount Can not more than Voucher Amount");
    }

    // console.log("validations");
    //console.log(validations);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "validations",
      text: validations,
    });

    //

    let _trntype = 0; // tran table

    if (props.vouchertype == "RECEIPT") {
      _trntype = VOUCHER_RECEIPT;
    } else {
      _trntype = VOUCHER_PAYMENT;
    }

    let _tempvoucherno = 0;
    if (mode == "ADD") {
      _tempvoucherno = 0;
    } else {
      _tempvoucherno = changedrows?.edit_voucherno;
    }

    const postdata = generateAccountingEntryPayLoad_TabStore(
      changedrows,
      auth.state.userdata.domainrecno,
      auth.state.userdata.tenantrecno,
      _trntype,
      _tempvoucherno
    );

    console.log("generateAPIData" + JSON.stringify(postdata));

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "data",
      text: postdata,
    });
  }

  const fetchVoucher = async (e) => {
    console.log("fetchVoucher call");

    console.log(
      tabstore?.state?.tabs[pageIndex]?.changedRows
        ?.edit_voucherno
    );

    let _voucherno =
      tabstore?.state?.tabs[pageIndex]?.changedRows
        ?.edit_voucherno;

    let voucher_data = null;
    if (props.vouchertype == "PAYMENT") {
      voucher_data = await LoadPaymentVoucherForEdit(
        auth.state.userdata.domainrecno,
        auth.state.userdata.tenantrecno,
        _voucherno
      );
    }
    if (props.vouchertype == "RECEIPT") {
      voucher_data = await LoadReceiptVoucherForEdit(
        auth.state.userdata.domainrecno,
        auth.state.userdata.tenantrecno,
        _voucherno
      );
    }

    console.log("voucher_data", voucher_data);

    if (voucher_data.voucher_type == null) {
      alert("Voucher Not Found.");
      return;
    }

    let _temp_voucher_type = 0;
    console.log("props.voucher_type", props.vouchertype);

    if (props.vouchertype == "RECEIPT") {
      _temp_voucher_type = VOUCHER_TYPE_RECEIPT;
    } else if (props.vouchertype == "PAYMENT") {
      _temp_voucher_type = VOUCHER_TYPE_PAYMENT;
    }

    // TODO restructrure this
    if (voucher_data.voucher_type == _temp_voucher_type) {
      if (_temp_voucher_type == VOUCHER_TYPE_RECEIPT) {
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pending_instruments",
          text: voucher_data.pending_instruments,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "gl_account_1",
          text: voucher_data.gl_account_1,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "party1",
          text: voucher_data.gl_account_1.code,
        });

        setAccount1labelEdit(voucher_data.gl_account_1.accountname);

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "gl_account_2",
          text: voucher_data.gl_account_2,
        });

        setAccount2labelEdit(voucher_data.gl_account_2.accountname);

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "party2",
          text: voucher_data.gl_account_2.code,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "VOUCHER_AMT_UPDATED",
          text: voucher_data.VOUCHER_AMT_UPDATED,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "narration",
          text: voucher_data?.narration,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "voucherdate",
          text: Number(voucher_data.trdate),
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "chequeno",
          text: Number(voucher_data?.chqno),
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "chequedate",
          text: Number(voucher_data?.chqdate),
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "partyreference",
          text: voucher_data?.partyreference,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "ourreference",
          text: voucher_data?.ourreference,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "voucher_type",
          text: VOUCHER_TYPE_RECEIPT, // receipt
        });

        setmode("EDIT");

        //
      } else if (_temp_voucher_type == VOUCHER_TYPE_PAYMENT) {
        //
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pending_instruments",
          text: voucher_data.pending_instruments,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "gl_account_1",
          text: voucher_data.gl_account_1,
        });

        setAccount1labelEdit(voucher_data.gl_account_1.accountname);

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "gl_account_2",
          text: voucher_data.gl_account_2,
        });

        setAccount2labelEdit(voucher_data.gl_account_2.accountname);

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "party2",
          text: voucher_data?.gl_account_2?.code,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "VOUCHER_AMT_UPDATED",
          text: voucher_data.VOUCHER_AMT_UPDATED,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "narration",
          text: voucher_data.narration,
        });
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "voucherdate",
          text: Number(voucher_data.trdate),
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "chequeno",
          text: Number(voucher_data.chqno),
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "chequedate",
          text: Number(voucher_data.chqdate),
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "partyreference",
          text: voucher_data?.partyreference,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "ourreference",
          text: voucher_data?.ourreference,
        });

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "voucher_type",
          text: VOUCHER_TYPE_PAYMENT, // payment
        });

        setmode("EDIT");
        generateAccountingEntryNew();
      }
    } else {
      alert("Voucher Not Found.");
    }
    setlocalState(localstate + 1);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    console.log("handleChangeTab: " + newValue);

    if (newValue == 1) {
      generateAccountingEntryNew();
    }
  };

  const updateReturnValue = (e) => {
    console.log("updateReturnValue",e);
    setnarration(e);
    setlocalState(localstate + 1);
  };

  const onAccount1Change = (event, type) => {
    console.log("onAccount1Change", event);
    console.log("onAccount1Change", type);
    setaccount1(event)
    if (event?.creditcontrol > 0) {
      setcreditcontrol(true)
    } else {
      setcreditcontrol(false)
    }
    if (type.action == "clear") {
      // clear page state for the account
      setcreditcontrol(false)
      let _instr = [];
      let _vouchers = [];
      let _gl_list = [];
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "pending_instruments",
        text: _instr,
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "accounting_vouchers",
        text: _vouchers,
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "UPDATE_GL_LIST",
        text: _gl_list,
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "gl_account_1",
        text: "",
      });
    } else if (type.action == "select-option") {
      if (event != undefined) {
        let account_1 = new Voucher_Account(
          event.code,
          event.glcode,
          event.gldescn,
          event.accountcode,
          event.descn
        );

        if (mode == "ADD") {
          tabstore.dispatch({
            type: "UPDATE_CHANGED_DATA",
            fieldType: "gl_account_1",
            text: account_1,
          });

          tabstore.dispatch({
            type: "UPDATE_CHANGED_DATA",
            fieldType: "party1",
            text: account_1,
          });
          //
        } else if (mode == "EDIT") {
          let acct1 =
            tabstore?.state?.tabs[pageIndex]?.changedRows
              ?.gl_account_1;
          acct1.code = account_1.code;
          acct1.glcode = account_1.glcode;
          acct1.gldescn = account_1.gldescn;
          acct1.accountcode = account_1.accountcode;
          acct1.descn = account_1.accountname;

          setAccount1labelEdit(acct1.descn);
          tabstore.dispatch({
            type: "UPDATE_CHANGED_DATA",
            fieldType: "gl_account_1",
            text: acct1,
          });
        }

        setSelectedAccount(event.code);
        getpendinginstruments(event.code);
        setlocalState(localstate + 1);
      }
    }
  };

  const onAccount2Change = (e, type) => {
    console.log("onAccount2Change", e);
    console.log("onAccount2Change", tabstore);
    setaccount2(e);
    if(props.vouchertype == "PAYMENT"){
      if (e?.creditcontrol > 0) {
        setcreditcontrol(true)
      } else {
        setcreditcontrol(false)
      }
    }
  };

  const onVoucherAmtUpdated = (e) => {
    console.log("onVoucherAmtUpdated", e);
  };
  function handleGlUpdateNew(event) {
    //console.log("handleGlUpdateNew event", event);
    //console.log(event);
    // if (event != undefined) {
    //   let account_1 = new Voucher_Account(
    //     event.code,
    //     event.glcode,
    //     event.gldescn,
    //     event.accountcode,
    //     event.descn
    //   );
    //   if (mode == "ADD") {
    //     tabstore.dispatch({
    //       type: "UPDATE_CHANGED_DATA",
    //       fieldType: "gl_account_1",
    //       text: account_1,
    //     });
    //   } else if (mode == "EDIT") {
    //     let acct1 =
    //       tabstore?.state?.tabs[pageIndex]?.changedRows
    //         ?.gl_account_1;
    //     acct1.code = account_1.code;
    //     acct1.glcode = account_1.glcode;
    //     acct1.gldescn = account_1.gldescn;
    //     acct1.accountcode = account_1.accountcode;
    //     acct1.descn = account_1.accountname;
    //     setAccount1labelEdit(acct1.descn);
    //     tabstore.dispatch({
    //       type: "UPDATE_CHANGED_DATA",
    //       fieldType: "gl_account_1",
    //       text: acct1,
    //     });
    //   }
    //   setSelectedAccount(event.code);
    //   getpendinginstruments(event.code);
    //   setlocalState(localstate + 1);
    // }
  }

  function handleGlUpdate(event) {
    console.log("handleGlUpdate event", event);

    if (event != undefined) {
      console.log("acct2 event ", event);

      let account_2 = new Voucher_Account(
        event.code,
        event.glcode,
        event.gldescn,
        event.code,
        event.descn
      );

      console.log("acct2", account_2);
      if (mode == "ADD") {
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "gl_account_2",
          text: account_2,
        });
      } else if (mode == "EDIT") {
        let acct2 =
          tabstore?.state?.tabs[pageIndex]?.changedRows
            ?.gl_account_2;

        acct2.code = account_2.code;
        acct2.glcode = account_2.glcode;
        acct2.gldescn = account_2.glname;
        acct2.accountcode = account_2.accountcode;
        acct2.descn = account_2.accountname;
        acct2.accountname = account_2.accountname;

        console.log("acct2", acct2);

        setAccount2labelEdit(acct2.descn);
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "gl_account_2",
          text: acct2,
        });
      }

      setlocalState(localstate + 1);
    }
    setcheque(event?.gldetailtype);
  }
  console.log("setcheque", cheque);
  function handleClearAdjustment(event) {
    let array = [];

    //array = clearAutoAdjustements(state.pending_instruments);

    array = clearAutoAdjustements(
      tabstore?.state?.tabs[pageIndex]?.changedRows
        ?.pending_instruments
    );
    console.log("handleClearAdjustment");
    console.log(array);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "pending_instruments",
      text: array,
    });

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "trigger_distribute",
      text: "NO",
    });

    setlocalState(localstate + 1);
  }

  /**
   * getpendinginstruments for GL / Account 1
   * if GL / Account 1 is selected for Receipt then send cdflag = 1
   * if GL / Account 2 is selected for Payment then send cdflag = 2
   * 16-JAN-2023
   */

  async function getpendinginstruments(_code) {
    var temp_cdflag; //// data payment information which was not able shown

    console.log("fetch getpendinginstruments");

    //

    if (
      tabstore?.state?.tabs[pageIndex]?.changedRows
        ?.voucher_type == VOUCHER_TYPE_PAYMENT
    ) {
      temp_cdflag = 2;
    } else if (
      tabstore?.state?.tabs[pageIndex]?.changedRows
        ?.voucher_type == VOUCHER_TYPE_RECEIPT
    ) {
      temp_cdflag = 1;
    }

    var payload = {
      domainrecno: auth.state.userdata.domainrecno, //508, // Changed for EDIT Testing -- Mandar
      tenantrecno: auth.state.userdata.tenantrecno,
      code: _code,
      fortrn: 1,
      cdflag: temp_cdflag,
      vouno:
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.edit_voucherno,
    };

    getAccountlist(payload).then((data) => {
      // console.log("getpendinginstruments");
      //  console.log(data);

      if (data.Message[0]?.pendinginstruments) {
        const _instr = transformPendingInsutrments(
          data?.Message[0]?.pendinginstruments
        );

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pending_instruments",
          text: _instr,
        });
      } else {
        let _instr = [];
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pending_instruments",
          text: _instr,
        });
      }
    });
  }

  function calculateOnAccountAmount() {
    /** should be copy of PendingInstruments.js TotalRow */
    console.log("called calculateOnAccountAmount");

    let temp_total_pending = 0;
    let temp_total_on_account = 0;
    let temp_total_gl_amount = 0;
    let temp_voucher_amount = 0;
    let temp_balance_on_account_amt = 0;

    temp_voucher_amount =
      tabstore?.state?.tabs[pageIndex]?.changedRows
        ?.VOUCHER_AMT_UPDATED;

    tabstore?.state?.tabs[
      pageIndex
    ]?.changedRows?.pending_instruments?.forEach((item) => {
      temp_total_pending = Number(temp_total_pending) + Number(item.pendingamt);
      if (item.on_account_amount > 0) {
        temp_total_on_account =
          Number(temp_total_on_account) + Number(item.on_account_amount);
      }

      const other_gl_child = item.childrows;
      other_gl_child?.forEach((data) => {
        if (data.amount > 0) {
          temp_total_gl_amount = Number(temp_total_gl_amount);
          Number(data.amount);
        }
      });
    });
    temp_balance_on_account_amt = Number(
      temp_voucher_amount - temp_total_on_account
    );
    temp_total_on_account = temp_total_on_account.toFixed(2);
    temp_total_gl_amount = temp_total_gl_amount.toFixed(2);
    temp_balance_on_account_amt = temp_balance_on_account_amt.toFixed(2);

    if (isNaN(temp_balance_on_account_amt) == true) {
      setonAccountAmount(0);
    } else {
      setonAccountAmount(temp_balance_on_account_amt);
    }
  }

  const onInstrumentsSettlementChanged = (data) => {
    console.log("onInstrumentsSettlementChanged Parent", data);
    setlocalState(localstate + 1);
  };

  const returnValueChanged = (e) => {
    console.log("returnValueChanged", e);
    setamount(e)
    setlocalState(localstate + 1);
  };

  function generateAccountingEntryNew() {
    // setVoucherInProgress(true);
    // generate voucher entry data to be shown on screen
    let temp_array = [];

    let Entry1 = {
      code: "",
      GLcode: "0",
      GLName: "",
      Accountcode: "",
      Accountname: "",
      Debit: 0,
      Credit: 0,
      CDFlag: 0,
      recno: 0,
    };

    let Entry2 = {
      code: "",
      GLcode: "0",
      GLName: "",
      Accountcode: "",
      Accountname: "",
      Debit: 0,
      Credit: 0,
      CDFlag: 0,
      recno: 0,
    };

    if (
      tabstore?.state?.tabs[pageIndex]?.changedRows
        ?.voucher_type == VOUCHER_TYPE_RECEIPT
    ) {
      // gl account code 1 is  credit
      // gl account  code 2 is debit

      let _temp_account_2 =
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.gl_account_2;

      let _temp_account_1 =
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.gl_account_1;

      Entry2.code = _temp_account_2?.code;
      Entry2.GLcode = _temp_account_2?.glcodeglcode;
      Entry2.GLName = _temp_account_2?.glname;
      Entry2.Accountcode = _temp_account_2?.accountcode;
      Entry2.Accountname = _temp_account_2?.accountname;
      Entry2.Debit =
        tabstore?.state?.tabs[
          pageIndex
        ]?.changedRows?.VOUCHER_AMT_UPDATED;
      Entry2.CDFlag = 2;
      Entry2.recno = _temp_account_2?.recno;

      Entry1.code = _temp_account_1?.code;
      Entry1.GLcode = _temp_account_1?.glcodeglcode;
      Entry1.GLName = _temp_account_1?.glname;
      Entry1.Accountcode = _temp_account_1?.accountcode;
      Entry1.Accountname = _temp_account_1?.accountname;
      Entry1.Credit =
        tabstore?.state?.tabs[
          pageIndex
        ]?.changedRows?.VOUCHER_AMT_UPDATED;
      Entry1.CDFlag = 1;
      Entry1.recno = _temp_account_1?.recno;

      temp_array.push(Entry1);
      temp_array.push(Entry2);
    } else if (
      tabstore?.state?.tabs[pageIndex]?.changedRows
        ?.voucher_type == VOUCHER_TYPE_PAYMENT
    ) {
      // gl account  code 1 is  debit
      // gl account  code 2 is credit

      let _temp_account_2 =
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.gl_account_2;

      let _temp_account_1 =
        tabstore?.state?.tabs[pageIndex]?.changedRows
          ?.gl_account_1;

      Entry1.code = _temp_account_1?.code;
      Entry1.GLcode = _temp_account_1?.glcodeglcode;
      Entry1.GLName = _temp_account_1?.glname;
      Entry1.Accountcode = _temp_account_1?.accountcode;
      Entry1.Accountname = _temp_account_1?.accountname;
      Entry1.Debit =
        tabstore?.state?.tabs[
          pageIndex
        ]?.changedRows?.VOUCHER_AMT_UPDATED;
      Entry1.CDFlag = 2;
      Entry1.recno = _temp_account_1?.recno;

      Entry2.code = _temp_account_2?.code;
      Entry2.GLcode = _temp_account_2?.glcodeglcode;
      Entry2.GLName = _temp_account_2?.glname;
      Entry2.Accountcode = _temp_account_2?.accountcode;
      Entry2.Accountname = _temp_account_2?.accountname;
      Entry2.Credit =
        tabstore?.state?.tabs[
          pageIndex
        ]?.changedRows?.VOUCHER_AMT_UPDATED;
      Entry2.CDFlag = 1;
      Entry2.recno = _temp_account_2?.recno;

      temp_array.push(Entry1);
      temp_array.push(Entry2);
    }

    // check pending instruments array with child rows

    // loop thru all arrays and generate entries

    let disocunt_entries = new Map();
    let other_gl_amount = 0;

    tabstore?.state?.tabs[
      pageIndex
    ]?.changedRows?.pending_instruments?.forEach((element) => {
      element?.childrows?.forEach((discountgl) => {
        let temp_entry = disocunt_entries.get(discountgl.code);

        console.log("temp_entry found" + discountgl.code);
        console.log(temp_entry);

        if (temp_entry == undefined) {
          // entry does not exists

          let Entry_temp_discount = {
            code: discountgl.code,
            GLcode: "0",
            GLName: discountgl.descn,
            Accountcode: discountgl.code,
            Accountname: discountgl.descn,
            Debit: 0,
            Credit: 0,
            CDFlag: 0,
            recno: discountgl.recno,
          };

          if (
            tabstore?.state?.tabs[pageIndex]?.changedRows
              ?.voucher_type == VOUCHER_TYPE_PAYMENT
          ) {
            Entry_temp_discount.Credit = Number(discountgl.amount);
            Entry_temp_discount.Debit = 0;
            Entry_temp_discount.CDFlag = 1;
            other_gl_amount =
              Number(other_gl_amount) + Number(discountgl.amount);
          } else if (
            tabstore?.state?.tabs[pageIndex]?.changedRows
              ?.voucher_type == VOUCHER_TYPE_RECEIPT
          ) {
            Entry_temp_discount.Debit = Number(discountgl.amount);
            Entry_temp_discount.Credit = 0;
            Entry_temp_discount.CDFlag = 2;
            other_gl_amount =
              Number(other_gl_amount) + Number(discountgl.amount);
          }

          disocunt_entries.set(discountgl.code, Entry_temp_discount);
        } else {
          // entry found update record here
          if (
            tabstore?.state?.tabs[pageIndex]?.changedRows
              ?.voucher_type == VOUCHER_TYPE_PAYMENT
          ) {
            temp_entry.Credit =
              Number(temp_entry.Credit) + Number(discountgl.amount);
            other_gl_amount =
              Number(other_gl_amount) + Number(discountgl.amount);
          } else if (
            tabstore?.state?.tabs[pageIndex]?.changedRows
              ?.voucher_type == VOUCHER_TYPE_RECEIPT
          ) {
            temp_entry.Debit =
              Number(temp_entry.Debit) + Number(discountgl.amount);

            other_gl_amount =
              Number(other_gl_amount) + Number(discountgl.amount);
          }
        }
      });
    });

    console.log("disocunt_entries", disocunt_entries);

    for (const [key, value] of disocunt_entries.entries()) {
      //console.log(key, value);
      temp_array.push(value);
    }

    if (other_gl_amount > 0) {
      let code1_entry = temp_array.filter(
        (x) =>
          x.code ==
          tabstore?.state?.tabs[pageIndex]?.changedRows
            ?.gl_account_1.code
      );
      //console.log("code1_entry");

      console.log(code1_entry);
      if (code1_entry.length > 0) {
        let main_entry = code1_entry[0];

        if (
          tabstore?.state?.tabs[pageIndex]?.changedRows
            ?.voucher_type == VOUCHER_TYPE_PAYMENT
        ) {
          main_entry.Debit = Number(main_entry.Debit) + Number(other_gl_amount);
        } else if (
          tabstore?.state?.tabs[pageIndex]?.changedRows
            ?.voucher_type == VOUCHER_TYPE_RECEIPT
        ) {
          main_entry.Credit =
            Number(main_entry.Credit) + Number(other_gl_amount);
        }

        let arrayindex = temp_array.indexOf(main_entry);
        if (arrayindex !== -1) {
          temp_array[arrayindex] = main_entry;
        }
      }
    }

    let debitTotal = 0;
    let creditTotal = 0;

    temp_array.forEach((item) => {
      debitTotal = Number(debitTotal) + Number(item.Debit);
      creditTotal = Number(creditTotal) + Number(item.Credit);
    });

    if (isNaN(debitTotal) == true) {
      debitTotal = 0;
    }
    if (isNaN(creditTotal) == true) {
      creditTotal = 0;
    }

    let totalEntry = {
      code: "TOTAL",
      GLcode: "",
      GLName: "",
      Accountcode: "",
      Accountname: "TOTAL",
      Debit: debitTotal,
      Credit: creditTotal,
      CDFlag: 0,
    };

    temp_array.push(totalEntry);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "accounting_vouchers",
      text: temp_array,
    });
    //// here condition added error was shoing in other pages while saving
    if (
      tabstore?.state?.tabs[pageIndex]?.name.includes("TR021") ||
      tabstore?.state?.tabs[pageIndex]?.name.includes("TR022") ||
      tabstore?.state?.tabs[pageIndex]?.name.includes("TR121")
    ) {
      generateAPIData();
    }
  }
  console.log("payment page12223", summary);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <div style={{ margin: "15px" }}>
        <form
          style={{
            ...inputBoxes,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", width: creditcontrol ? "80vw" : "90vw", }}>


            {/* Voucher Date and Receipt From row**/}

            <div
              onKeyDown={enterPress}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1, paddingTop: "2%", flex: 1
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start", flex: 1, zIndex:2}} onKeyDown={enterPress}>
                <SaveDatePicker
                  //defaultValue={Vdate}//Vdate voucher date initialise logic start logic changes from getting data from tabstore to locally
                  //comented the default value because datepicker is getting that null
                  id={"voucherdate"}
                  displayLabel={"Voucher Date"}
                  apiKey={"voucherdate"}
                  isrequired={true}
                  returnValue={(e) => {
                    console.log("Vdate voucher date",e)
                    setVdate(e); //Vdate voucher date initialise logic start
                    returnValueChanged(e);
                  }}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "flex-start", flex: 1, }} onKeyDown={enterPress}>
                {
                  cheque === "B" ? (<SaveDatePicker
                    id={"chequedate"}
                    displayLabel={"Cheque Date"}
                    placeholder={"Cheque Date"}
                    apiKey={"chequedate"}
                    returnValue={updateReturnValue}
                  // defaultValue={
                  //   tabstore?.state?.tabs[tabstore?.state.tabIndex]
                  //     ?.changedRows?.chequedate
                  // }
                  />) : (null)
                }

              </div>
              <div
                onKeyDown={enterPress}
                style={{
                  display: "flex",
                  alignSelf: "center",
                  marginLeft: "5px",
                  marginRight: "5px", flex: 1
                }}
              >
                {
                  cheque === "B" ? (<SaveTextInput
                    type={"text"}
                    key={"chequeno"}
                    alignment="column"
                    placeholder="Enter Cheque No."
                    displayLabel={"Cheque No."}
                    apikey={"chequeno"}
                    width={"25vw"}
                    returnValue={updateReturnValue}
                    pageIndex={pageIndex}
                    defaultValue={
                      tabstore?.state?.tabs[pageIndex]
                        ?.changedRows?.chequeno
                    }
                  />) : (null)
                }

              </div>

            </div>

            {/* Received in row**/}

            <div
              onKeyDown={enterPress}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1, paddingTop: "3%"
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flex: 1,zIndex:1 }}
                onKeyDown={enterPress}
              >

                <SaveDropDownAccounts
                  displayLabel={account1label}
                  alignment="column"
                  id={"account1"}
                  placeholder={account1label}
                  apiUrl={getAccountlistURL}
                  apikey={"party1"}
                  multi={false}
                  selectionLabel={"descn"}
                  selectionValue={"code"}
                  records={20}
                  minInput={0}
                  // async={props.vouchertype == "PAYMENT" ? false : true}
                  async
                  // body={{ withcashbank: props.vouchertype == "PAYMENT" ? 1 : 2 }}
                  body={{ withcashbank : 2 }}
                  setDataToParent={handleGlUpdateNew}
                  width="25vw"
                  onChange={onAccount1Change}
                  defaultValue={
                    tabstore?.state?.tabs[pageIndex]?.changedRows
                      ?.party1?.code
                  }
                  isrequired={true}
                />

              </div>

              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flex: 1 }} >
                <SaveDropDownAccounts
                  displayLabel={account2label}
                  alignment="column"
                  id={"account2"}
                  placeholder={account2label}
                  apiUrl={getAccountlistURL}
                  apikey={"party2"}
                  multi={false}
                  selectionLabel={"descn"}
                  selectionValue={"code"}
                  records={20}
                  minInput={0}
                  // async={props.vouchertype == "PAYMENT" ? true : false}
                  width="25vw"
                  // body={{ withcashbank: props.vouchertype == "PAYMENT" ? 2 : 1 }}
                  body={{withcashbank : 1}}
                  setDataToParent={handleGlUpdate}
                  onChange={onAccount2Change}
                  defaultValue={
                    tabstore?.state?.tabs[pageIndex]?.changedRows
                      ?.party2
                  }
                  isrequired={true}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "flex-start",  flex: 1, height: "100%", flexDirection: "column" }} onKeyDown={enterPress}>
                <SaveTextInput
                  type={"number"}
                  pageIndex={pageIndex}
                  id={"txtamount"}
                  key={"VOUCHER_AMT_UPDATED"}
                  alignment="column"
                  placeholder="Enter Amount"
                  displayLabel={"Amount"}
                  width={"25vw"}
                  style={{  textAlign: "right" }}
                  apikey={"VOUCHER_AMT_UPDATED"}
                  returnValue={returnValueChanged}
                  isrequired={true}
                 

                />
                {amount < 0 ? (
                  <p style={{ marginTop: "-4px", color: "red" }}>
                    Negative value not allowed
                  </p>
                ) : amount <= 0 ? (
                  <p style={{ marginTop: "-4px", color: "red" }}>
                    Amount is compulsory
                  </p>
                ) : null}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "3%" }} onKeyDown={enterPress}>
              <div
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flex: 1, height: "100%", zIndex:0 }}
                onKeyDown={enterPress}
              >
                <SaveTextInput
                  key={"narration"}
                  id={"txtnarration"}
                  pageIndex={pageIndex}
                  placeholder="Enter Narration"
                  width={"25vw"}
                  displayLabel={"Narration"}
                  apikey={"narration"}
                  type="text"
                  returnValue={updateReturnValue}
                  defaultValue={
                    tabstore?.state?.tabs[pageIndex]?.changedRows
                      ?.narration
                  }
                  isrequired
                />
              </div>
              <div
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flex: 1, height: "100%" }}
                onKeyDown={enterPress}
              >
                <SaveTextInput
                  key={"ourreference"}
                  id={"txtourreference"}
                  pageIndex={pageIndex}
                  placeholder="Enter Our Reference"
                  width={"25vw"}

                  displayLabel={"Our Reference"}
                  apikey={"ourreference"}
                  type="text"
                  defaultValue={
                    tabstore?.state?.tabs[pageIndex]?.changedRows
                      ?.ourreference
                  }
                />
              </div>
              <div
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flex: 1, height: "100%" }}
                onKeyDown={enterPress}
              >
                <SaveTextInput
                  key={"partyreference"}
                  id={"txtpartyreference"}
                  pageIndex={pageIndex}
                  placeholder="Enter Party  Reference"
                  width={"25vw"}
                  displayLabel={"Party  Reference"}
                  apikey={"partyreference"}
                  type="text"
                  defaultValue={
                    tabstore?.state?.tabs[pageIndex]?.changedRows
                      ?.partyreference
                  }
                />
              </div>

            </div>

            {creditcontrol ? (
            <div onKeyDown={enterPress} style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", paddingTop: "3%", flexDirection: "row" }}>
                 
                  <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        tabstore.dispatch({
                          type: "UPDATE_CHANGED_DATA",
                          fieldType: "trigger_distribute",
                          text: "YES",
                        });
                        console.log("localstate", localstate);
                        setlocalState(localstate + 1);
                      }}
                    >
                      Auto Adjust Amount
                    </Button>
                
                  <Button 
                    type="primary"
                    onClick={() => {
                      handleClearAdjustment();
                    }}
                    size="large"
                  >
                    Clear Adjusted Amount
                  </Button>
              
              {/* </div> */}
            </div>
            ) : null
          }
          </div>
          <div style={{ display: "flex", width: creditcontrol ? "20vw" :null, justifyContent: "center" }}>
            <div>
              {/* Table of Selected Credit and Debot Amount */}
              {creditcontrol ? (
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginTop: "10px",
                  }}
                >
                  <table border="1" width={"100%"}>
                    <tbody style={{ display: "flex", flexDirection: "row" }}>
                      <tr
                        style={{
                          position: "sticky",
                          top: "0",
                          background: "#fff",
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <td>Credit Amt</td>
                        <td>Debit Amt</td>
                        <td>Balance Amt</td>
                        {props.vouchertype == "RECEIPT" ? (
                          <td>Received Amt</td>
                        ) : (
                          <td>Paid Amt</td>
                        )}

                        <td>Adjusted Amt</td>
                        <td>On Account</td>
                      </tr>
                      <tr style={{
                        position: "sticky",
                        top: "0",
                        background: "#fff",
                        display: "flex",
                        flexDirection: "column"
                      }}>
                        <td >
                          {summary?.credittotal}
                        </td>
                        <td >
                          {summary?.debittotal}
                        </td>
                        <td >
                          {summary?.balancetotal}
                        </td>
                        <td >
                          {summary?.received_paidtotal ? summary?.received_paidtotal : 0.00}
                          {/* {summary?.received_paidtotal.toFixed(0)} */}
                        </td>
                        <td >
                          {" "}
                          {summary?.adjusttedtotal}
                        </td>
                        {tabstore?.state?.tabs[pageIndex]
                          ?.changedRows?.VOUCHER_AMT_UPDATED == undefined ? (
                          <td >0.00</td>
                        ) : (
                          <td >
                            {summary?.on_account_amount != "NaN"
                              ? Math.abs(summary?.on_account_amount)
                              : 0}
                            {/* {Math.abs(Number(summary?.on_account_amount))} */}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        </form>

        {/* Tabs And Box Bottom Part */}
        {
          creditcontrol ? (
            <Box style={{ maxHeight: "100vh", overflow: "auto" }}>
            <Grid container rowSpacing={1} sx={{ mx: 1, my: 0, p: 0 }}>
              <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valueTab}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                    >
                    <Tab label="Adjustments" {...a11yProps(0)} /> 
                    <Tab label="Vouchers" {...a11yProps(1)} />                 
                  </Tabs>
                </Box>
                {/**    Key={state.selected_gl} */}
                { creditcontrol  ?(
                <TabPanel value={valueTab} index={0}>
                  {tabstore?.state?.tabs[pageIndex]?.changedRows?.[
                    "pending_instruments"
                  ] ? (
                    <PendingInsutrumentsTransaction
                      Key={
                        tabstore?.state?.tabs[pageIndex]
                          ?.changedRows?.["party1"]
                      }
                      distribute={
                        tabstore?.state?.tabs[pageIndex]
                          ?.changedRows?.["VOUCHER_AMT_UPDATED"]
                      }
                      onInstrumentsSettlementChanged={
                        onInstrumentsSettlementChanged
                      }
                      vouchertype={props.vouchertype}
                      pageIndex={pageIndex}
                    />
                  ) : (
                    <></>
                  )}
                </TabPanel>
                ):(null)}
                <TabPanel value={valueTab} index={1}>
                  <div>
                    <table border="1" width={"70%"}>
                      <tbody>
                        <tr>
                          <td width="10%">Sr.No</td>
                          <td style={{ display: "none" }}>Reference No</td>
                          <td style={{ display: "none" }}>GL Code</td>
                          <td style={{ display: "none" }} width="20%">
                            GL Name
                          </td>
                          <td width="20%">Account code</td>
                          <td width="40%">Account Name</td>
                          <td width="10%" align="right">
                            Debit
                          </td>
                          <td width="10%" align="right">
                            Credit
                          </td>
                        </tr>
                        {tabstore?.state?.tabs[pageIndex]
                          ?.changedRows?.accounting_vouchers?.length > 0 ? (
                          <>
                            {tabstore?.state?.tabs[
                              pageIndex
                            ]?.changedRows?.accounting_vouchers?.map(
                              (data, index) => (
                                <tr>
                                  <td width="10%">{data.Accountname === "TOTAL" ? "" : index + 1}</td>
                                  <td style={{ display: "none" }}>
                                    {data.ReferenceNo}
                                  </td>
                                  <td style={{ display: "none" }}>
                                    {data.GLcode}{" "}
                                  </td>
                                  <td style={{ display: "none" }} width="20%">
                                    {data.GLName}{" "}
                                  </td>
                                  <td width="20%">{data.Accountcode}</td>
                                  <td width="40%">{data.Accountname}</td>
                                  <td width="10%" align="right">
                                    {data.Debit}{" "}
                                  </td>
                                  <td width="10%" align="right">
                                    {data.Credit}{" "}
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
          ) : (
            <Box style={{ maxHeight: "100vh", overflow: "auto" }}>
            <Grid container rowSpacing={1} sx={{ mx: 1, my: 0, p: 0 }}>
              <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valueTab}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                    >
                    {/* <Tab label="Adjustments" {...a11yProps(0)} />  */}
                    <Tab label="Vouchers" {...a11yProps(0)} />                 
                  </Tabs>
                </Box>
                {/**    Key={state.selected_gl} */}
                {/* { creditcontrol  ?(
                <TabPanel value={valueTab} index={0}>
                  {tabstore?.state?.tabs[pageIndex]?.changedRows?.[
                    "pending_instruments"
                  ] ? (
                    <PendingInsutrumentsTransaction
                      Key={
                        tabstore?.state?.tabs[pageIndex]
                          ?.changedRows?.["party1"]
                      }
                      distribute={
                        tabstore?.state?.tabs[pageIndex]
                          ?.changedRows?.["VOUCHER_AMT_UPDATED"]
                      }
                      onInstrumentsSettlementChanged={
                        onInstrumentsSettlementChanged
                      }
                      vouchertype={props.vouchertype}
                    />
                  ) : (
                    <></>
                  )}
                </TabPanel>
                ):(null)} */}
                <TabPanel value={valueTab} index={creditcontrol ? 1 : 0}>
                  <div>
                    <table border="1" width={"70%"}>
                      <tbody>
                        <tr>
                          <td width="10%">Sr.No</td>
                          <td style={{ display: "none" }}>Reference No</td>
                          <td style={{ display: "none" }}>GL Code</td>
                          <td style={{ display: "none" }} width="20%">
                            GL Name
                          </td>
                          <td width="20%">Account code</td>
                          <td width="40%">Account Name</td>
                          <td width="10%" align="right">
                            Debit
                          </td>
                          <td width="10%" align="right">
                            Credit
                          </td>
                        </tr>
                        {tabstore?.state?.tabs[pageIndex]
                          ?.changedRows?.accounting_vouchers?.length > 0 ? (
                          <>
                            {tabstore?.state?.tabs[
                              pageIndex
                            ]?.changedRows?.accounting_vouchers?.map(
                              (data, index) => (
                                <tr>
                                  <td width="10%">{data.Accountname === "TOTAL" ? "" : index + 1}</td>
                                  <td style={{ display: "none" }}>
                                    {data.ReferenceNo}
                                  </td>
                                  <td style={{ display: "none" }}>
                                    {data.GLcode}{" "}
                                  </td>
                                  <td style={{ display: "none" }} width="20%">
                                    {data.GLName}{" "}
                                  </td>
                                  <td width="20%">{data.Accountcode}</td>
                                  <td width="40%">{data.Accountname}</td>
                                  <td width="10%" align="right">
                                    {data.Debit}{" "}
                                  </td>
                                  <td width="10%" align="right">
                                    {data.Credit}{" "}
                                  </td>
                                </tr>
                              )
                            )}
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
          )
        }
        {/* <Box style={{ maxHeight: "100vh", overflow: "auto" }}>
          <Grid container rowSpacing={1} sx={{ mx: 1, my: 0, p: 0 }}>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valueTab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                  >
                  <Tab label="Adjustments" {...a11yProps(0)} /> 
                  <Tab label="Vouchers" {...a11yProps(1)} />                 
                </Tabs>
              </Box>
              {/**    Key={state.selected_gl} */}
              {/* { creditcontrol  ?(
              <TabPanel value={valueTab} index={0}>
                {tabstore?.state?.tabs[pageIndex]?.changedRows?.[
                  "pending_instruments"
                ] ? (
                  <PendingInsutrumentsTransaction
                    Key={
                      tabstore?.state?.tabs[pageIndex]
                        ?.changedRows?.["party1"]
                    }
                    distribute={
                      tabstore?.state?.tabs[pageIndex]
                        ?.changedRows?.["VOUCHER_AMT_UPDATED"]
                    }
                    onInstrumentsSettlementChanged={
                      onInstrumentsSettlementChanged
                    }
                    vouchertype={props.vouchertype}
                  />
                ) : (
                  <></>
                )}
              </TabPanel>
              ):(null)} */}
              {/* <TabPanel value={valueTab} index={1}>
                <div>
                  <table border="1" width={"70%"}>
                    <tbody>
                      <tr>
                        <td width="10%">Sr.No</td>
                        <td style={{ display: "none" }}>Reference No</td>
                        <td style={{ display: "none" }}>GL Code</td>
                        <td style={{ display: "none" }} width="20%">
                          GL Name
                        </td>
                        <td width="20%">Account code</td>
                        <td width="40%">Account Name</td>
                        <td width="10%" align="right">
                          Debit
                        </td>
                        <td width="10%" align="right">
                          Credit
                        </td>
                      </tr>
                      {tabstore?.state?.tabs[tabstore?.state.tabIndex]
                        ?.changedRows?.accounting_vouchers?.length > 0 ? (
                        <>
                          {tabstore?.state?.tabs[
                            tabstore?.state.tabIndex
                          ]?.changedRows?.accounting_vouchers?.map(
                            (data, index) => (
                              <tr>
                                <td width="10%">{index + 1}</td>
                                <td style={{ display: "none" }}>
                                  {data.ReferenceNo}
                                </td>
                                <td style={{ display: "none" }}>
                                  {data.GLcode}{" "}
                                </td>
                                <td style={{ display: "none" }} width="20%">
                                  {data.GLName}{" "}
                                </td>
                                <td width="20%">{data.Accountcode}</td>
                                <td width="40%">{data.Accountname}</td>
                                <td width="10%" align="right">
                                  {data.Debit}{" "}
                                </td>
                                <td width="10%" align="right">
                                  {data.Credit}{" "}
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </Grid>
          </Grid>
        </Box> */} 

      </div>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



export default TransactionScreen;

const inputBoxes = {
  // border: "1px solid black",
  display: "flex",
  alignItems: "start",
  flexDirection: "row",
  gap: "15px",
  width: "fit-content",
  marginLeft: "25px",
  //margin: "auto",
  // flexWrap: "wrap",
};

const bottomControls = {
  // border: "1px solid red",
  display: "flex",
  justifyContent: "space-between",
  margin: "48px 0 0",
  flexWrap: "wrap",
};
