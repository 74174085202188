import { useState, useEffect, useContext, useRef } from "react";
import { RadioChangeEvent } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";
import SaveDatePicker from "../../../CommonControllers/SaveComponents/SaveDatePicker";
import SaveTextInput from "../../../CommonControllers/Components/SaveComponents/SaveTextInput";

import { Button, Radio, Space, Divider } from "antd";
import { getAccountlistURL } from "../../../CommonControllers/Urls";

import JVPendingInsutrumentsTransaction from "./JVPendingInstrumentsTransaction";

import { Voucher_Account } from "../Transaction/TransactionHelper";

import { AuthStore } from "../../../Auth/AuthStore";
import { TabStore } from "../../../TabsStructure/TabsStore";
import { VOUCHER_JV } from "../Transaction/TransactionHelper";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { enterPress, getToday } from "../../../CommonControllers/AppFunction";

function JVScreen(props) {
  const auth = useContext(AuthStore);
  const [valueDrCr, setValueDrCr] = useState(1);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedSettlementAcct, setselectedSettlementAcct] = useState(false);
  const [narration, setNarration] = useState("");
  const tabstore = useContext(TabStore);
  const [localstate, setlocalState] = useState(0);
  const [voucherdate, setVoucherdate] = useState(getToday());

  /*
  console.log("JVScreen");
  console.log(tabstore);
  console.log("discountgl");
  console.log(
    tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows["discountgl"]
  );
*/
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabstore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == props.fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  useEffect(() => {
    if (
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus == "Success"
    ) {
      console.log("Page Status Changed");

      document.getElementById("txtnarration").value = "";
      document.getElementById("txtpartyreference").value = "";
      document.getElementById("txtourreference").value = "";
      //setVoucherdate(getToday());
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "ourreference",
        text: "",
      });
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucherdate",
        text:voucherdate,
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "narration",
        text: "",
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "partyreference",
        text: "",
      });

      //

      generateAPIData();
      setlocalState(localstate + 1);
      console.log("set local state page change");
      tabstore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
    }
  }, [tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus]);

  useEffect(() => {
    generateAPIData();
  }, [tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows]);

  const onChangeDrCr = (e) => {
    //   console.log("radio checked", e.target.value);
    setValueDrCr(e.target.value);
  };

  const returnValueChanged = (e) => {
    console.log("returnValueChanged ");
    generateAPIData();
  };

  function getDisplayVouchersFromStore() {
    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    let displayvouchers = [];

    if (_changedRows?.hasOwnProperty("displayvouchers")) {
      displayvouchers =
        tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.[
          "displayvouchers"
        ];
    }
    return displayvouchers;
  }

  function handleAddAccount() {
    // let selectedAccount = null;
    console.log("selectedAccount", selectedAccount);
    console.log(valueDrCr);
    console.log(amount);
    if (!selectedAccount?.accountname) {
      alert("Please Select Account");
      return;
    }
    if (amount <= 0) {
      alert("Amount is Compulsory");
      return;
    }

    let changedRows = {
      ...tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows,
    };

    let vouchers = [];
    vouchers =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows[
        "displayvouchers"
      ];

    console.log("Vouchers", vouchers);

    if (vouchers == undefined) {
      let tempArray = [];

      let voucher = {
        code: selectedAccount.code,
        name: selectedAccount.accountname,
        narration: narration,
        debit: 0,
        credit: 0,
        instruments: [],
        isDiscountGL: false,
        gldetailtype: selectedAccount.gldetailtype,
      };

      /**decide DR or CR Amount  */
      if (valueDrCr == 1) {
        voucher.debit = amount;
        voucher.credit = 0;
      } else if (valueDrCr == 2) {
        voucher.credit = amount;
        voucher.debit = 0;
      }

      tempArray.push(voucher);

      changedRows["displayvouchers"] = tempArray;
      console.log("Vouchers", tempArray);
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "displayvouchers",
        text: tempArray,
      });
    } else {
      let voucher = {
        code: selectedAccount.code,
        name: selectedAccount.accountname,
        narration: narration,
        debit: 0,
        credit: 0,
        instruments: [],
        isDiscountGL: false,
        gldetailtype: selectedAccount.gldetailtype,
      };
      /**decide DR or CR Amount  */

      if (valueDrCr == 1) {
        voucher.debit = amount;
        voucher.credit = 0;
      } else if (valueDrCr == 2) {
        voucher.credit = amount;
        voucher.debit = 0;
      }

      vouchers.push(voucher);
      console.log("Vouchers", vouchers);

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "displayvouchers",
        text: vouchers,
      });
    }

    generateAPIData();

    // clear drop down
    let account_2 = new Voucher_Account("", "", "", "", "");
    setSelectedAccount(account_2);
    setNarration("");
    setAmount(0);

    // setSelectedAccount(null);
  }

  // function handleDeleteAccount(data) {
  //   console.log("handleDeleteAccount");
  //   console.log(data);

  //   let account_entry_for_delete_code = data.code;

  //   let vouchers = [];
  //   vouchers =
  //     tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows[
  //     "displayvouchers"
  //     ];

  //   if (vouchers != undefined) {
  //     const filteredvouchers = vouchers.filter((x) => x.code != data.code);

  //     const _tempVouchers = filteredvouchers.filter(
  //       (item) => item.parentcode != account_entry_for_delete_code
  //     );

  //     tabstore.dispatch({
  //       type: "UPDATE_CHANGED_DATA",
  //       fieldType: "displayvouchers",
  //       text: _tempVouchers,
  //     });
  //   }
  // }

  function handleDeleteAccount(data) {
    console.log("handleDeleteAccount");
    console.log(data);

    let vouchers = [];
    vouchers =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows[
        "displayvouchers"
      ];

    if (vouchers != undefined) {
      const indexToDelete = vouchers.findIndex((x) => x === data);

      if (indexToDelete !== -1) {
        // Remove the entry by index
        vouchers.splice(indexToDelete, 1);

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "displayvouchers",
          text: vouchers,
        });
      }
    }
  }

  function handleAmtUpdate(value) {
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount(null);
    }
  }

  function handleGlUpdate(event) {
    console.log("handleGlUpdate event", event);

    if (event != undefined) {
      console.log(event);

      let account_2 = new Voucher_Account(
        event.code,
        event.glcode,
        event.gldescn,
        event.accountcode,
        event.descn,
        event.gldetailtype
      );
      setSelectedAccount(account_2);
    }
  }

  function generateAPIData() {
    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    let displayvouchers = getDisplayVouchersFromStore();

    let postdata = {
      domainrecno: auth.state.userdata.domainrecno,
      tenantrecno: auth.state.userdata.tenantrecno,
      trdate: changedrows?.voucherdate, //20221202,
      items: [],
    };

    displayvouchers?.forEach((element) => {
      //

      let _cdflg = 0;
      let _amount = 0;

      if (element.debit > 0) {
        _cdflg = 2;
        _amount = element.debit;
      }
      if (element.credit > 0) {
        _cdflg = 1;
        _amount = element.credit;
      }

      let item_acct = {
        trdate: auth.state.workdate,
        code: element?.code,
        cdflag: _cdflg,
        amount: _amount,
        ctflag: 2,
        trntype: VOUCHER_JV,
        particular: "",
      };

      let billingdetails = [];

      let filtered_instruments = element.instruments.filter(
        (x) => x.childrows.length > 0
      );

      if (filtered_instruments?.length > 0) {
        filtered_instruments?.forEach((instrument) => {
          //

          console.log(instrument);

          let _discountdetails = [];

          // in JV there are no 2 levels of instrument adjustment
          // only set values in billing_details for API

          instrument?.childrows?.forEach((gldiscount) => {
            let billing_detail = {
              recno: instrument.recno,
              refno: instrument.refno,
              code: element?.code, // adjustment should be to selected account in instur tran
              amount: Number(gldiscount.amount),
            };

            let _discount = {};
            /*
            let _discount = {
              code: gldiscount.code,
              amount: gldiscount.amount,
            };
           */
            //  _discountdetails.push(_discount);

            //billing_detail.discountdetails = _discountdetails;
            billingdetails.push(billing_detail);
          });
        });
      }
      item_acct.billingdetails = billingdetails;
      item_acct.particular = changedrows?.narration;
      item_acct.refno = changedrows?.ourreference;
      item_acct.theirrefno = changedrows?.partyreference;

      postdata.items.push(item_acct);
    });

    console.log(" postdata for API");
    console.log(postdata);

    if (displayvouchers.length > 0) {
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "data",
        text: postdata,
      });
    }
  }

  const TotalRow = () => {
    let displayvouchers = getDisplayVouchersFromStore();

    let TotalDebit = 0;
    let TotalCredit = 0;
    let contramatch = "s";

    displayvouchers?.forEach((element) => {
      TotalDebit = Number(TotalDebit) + Number(element.debit);
      TotalCredit = Number(TotalCredit) + Number(element.credit);
    });
    if (
      Number(TotalDebit).toFixed(2) == Number(TotalCredit).toFixed(2) &&
      (TotalDebit != 0 || TotalCredit != 0)
    ) {
      contramatch = true;
    }
    if (Number(TotalDebit).toFixed(2) !== Number(TotalCredit).toFixed(2)) {
      contramatch = false;
    }

    const styles = {
      exampleStyle1: {
        backgroundColor: "red",
      },
    };
    console.log("contramatch", contramatch);
    return (
      <tr
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: 1,
          backgroundColor:
            contramatch == true
              ? "#0FB360"
              : contramatch == false
              ? "#F76B59"
              : "#ebedf0",
        }}
      >
        <td width="10%"></td>
        <td width="20%" style={{ display: "none" }}></td>
        <td width="40%">Total</td>
        <td></td>
        <td width="20%" align="right">
          {Number(TotalDebit).toFixed(2)}
        </td>
        <td width="20%" align="right">
          {Number(TotalCredit).toFixed(2)}
        </td>
        <td width="10%" align="center"></td>
        <td width="10%" align="center"></td>
      </tr>
    );
  };

  const handleCloseInstrumentsPopUp = () => {
    setOpen(false);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "selectedAccountToSettleDiscounts",
      text: "",
    });
  };

  const handleSaveInstrumentsPopup = () => {
    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;
    let _srno = 1;

    let pending_instruments = changedrows["pendinginstruments"];
    let selected_account = changedrows["selectedAccountToSettleDiscounts"];

    let displayvouchers = changedrows["displayvouchers"];

    if (pending_instruments?.length > 0 && selected_account) {
      let voucher_object = displayvouchers.find(
        (item) => item.code == selected_account.code
      );

      let item_index = displayvouchers.findIndex(
        (item) => item.code == selected_account.code
      );

      if (voucher_object != undefined) {
        voucher_object.instruments = pending_instruments;
      }

      displayvouchers[item_index] = voucher_object;

      /**
       * Generate Accounting Entries for Discount GL
       * 01FEB2023
       */

      let vouchers = [];
      let discount_entries = new Map();

      if (displayvouchers?.length > 0) {
        displayvouchers.forEach((element) => {
          if (element.isDiscountGL == false) {
            // entry added by user

            vouchers.push(element);

            let _instruments = element.instruments;

            _instruments.forEach((instrument) => {
              let childrows = instrument.childrows;
              childrows.forEach((discountgl) => {
                let _temp_gl = discount_entries.get(discountgl.code);

                if (_temp_gl == undefined) {
                  console.log("_temp_gl not found");
                  console.log(_temp_gl);

                  let voucher_acct = {
                    code: discountgl.code,
                    name: discountgl.descn,
                    debit: 0,
                    credit: 0,
                    instruments: [],
                    isDiscountGL: true,
                    parentcode: element.code,
                  };

                  if (element.credit > 0) {
                    voucher_acct.debit = discountgl.amount;
                  } else {
                    voucher_acct.credit = discountgl.amount;
                  }

                  discount_entries.set(discountgl.code, voucher_acct);
                  //
                } else {
                  console.log(" else _temp_gl  found");
                  console.log(_temp_gl);

                  if (element.credit > 0) {
                    _temp_gl.debit =
                      Number(discountgl.amount) + Number(_temp_gl.debit);
                  } else {
                    _temp_gl.credit =
                      Number(discountgl.amount) + Number(_temp_gl.credit);
                  }
                  discount_entries.set(discountgl.code, _temp_gl);
                }
              });
            });
          }
        });
      }

      // console.log("disocunt_entries");
      // console.log(discount_entries);

      for (const [key, value] of discount_entries.entries()) {
        // console.log("discount entry of voucher");
        // console.log(value);
        vouchers.push(value);
      }

      // console.log("vouchers save insturment");
      // console.log(vouchers);

      // TODO generate total row ;
      /*
      let debitTotal = 0;
      let creditTotal = 0;

      vouchers.forEach((item) => {
        debitTotal = Number(debitTotal) + Number(item.debit);
        creditTotal = Number(creditTotal) + Number(item.credit);
      });

      let voucher_acct_total = {
        code: "TOTAL",
        name: "TOTAL",
        debit: debitTotal,
        credit: creditTotal,
        instruments: [],
        isDiscountGL: true,
      };
      vouchers.push(voucher_acct_total);
    */

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "displayvouchers",
        text: vouchers,
      });

      // clear temp array
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "pendinginstruments",
        text: [],
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "selectedAccountToSettleDiscounts",
        text: {},
      });
    }

    setOpen(false);

    //generateAPIData();
  };

  const handleOpenPendingInstrumentPopUp = (data) => {
    console.log("handleOpen ");
    console.log(data);
    setselectedSettlementAcct(data);

    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    let displayvouchers = changedrows["displayvouchers"];

    if (displayvouchers?.length > 0) {
      let voucher_object = displayvouchers.find(
        (item) => item.code == data.code
      );

      let pending_instruments = voucher_object?.instruments;

      //let disocunt_entries = new Map();
      if (pending_instruments?.length > 0) {
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pendinginstruments",
          text: pending_instruments,
        });
      }
    }

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "selectedAccountToSettleDiscounts",
      text: data,
    });

    setOpen(true);
  };

  let _vouchers = [];
  let _changedRows =
    tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

  if (_changedRows?.hasOwnProperty("displayvouchers")) {
    _vouchers =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.[
        "displayvouchers"
      ];
  }

  return (
    <>
      <div style={{ margin: "36px auto" }}>
        <div
          style={{
            ...inputBoxes,
          }}
        >
          <form>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "36px",
                width: "fit-content",
                flexWrap: "wrap",
              }}
              onKeyDown={enterPress}
            >
              <div style={{ width: "fit-content" }} onKeyDown={enterPress}>
                <SaveDatePicker
                  defaultValue={voucherdate}
                  // id={"voucherdate"}
                  displayLabel={"Voucher Date"}
                  apiKey={"voucherdate"}
                  returnValue={(e) => {
                    setVoucherdate(e);
                  }}
                />
              </div>

              <div
                style={{
                  zIndex: 3,
                }}
                onKeyDown={enterPress}
              >
                <SaveDropdown
                  displayLabel={"Account"}
                  alignment="column"
                  id={"account1"}
                  placeholder={"Search Account"}
                  apiurl={getAccountlistURL}
                  apiKey={"discountgl"}
                  apiUrl={getAccountlistURL}
                  apikey={"discountgl"}
                  multi={false}
                  selectionLabel={"descn"}
                  selectionValue={"code"}
                  records={20}
                  minInput={0}
                  defaultSelected={selectedAccount?.code}
                  value={selectedAccount?.code}
                  async
                  body={{ fortrn: 1 }}
                  setDataToParent={handleGlUpdate}
                  returnValue={handleGlUpdate}
                  width="30vw"
                />
              </div>
              <div onKeyDown={enterPress}>
                <Radio.Group
                  style={{ marginLeft: "10px" }}
                  onChange={onChangeDrCr}
                  value={valueDrCr}
                >
                  <Radio value={1}>Debit</Radio>
                  <Radio value={2}>Credit</Radio>
                </Radio.Group>
              </div>
              <div style={{ position: "relative" }} onKeyDown={enterPress}>
                <label
                  className={`label ${"focused"}`}
                  style={{
                    // zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-12px",
                    left: "12px",
                    //backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  Amount
                </label>
                <input
                  style={{
                    padding: "7px",
                    border: "1px solid #dfdfdf",
                    width: "90px",
                    fontSize: "16px",
                    marginTop: "2px",
                    borderRadius: "8px",
                    textAlign: "right",
                    height: "36px",
                  }}
                  type="number" /// here validation
                  value={amount}
                  onChange={(e) => {
                    handleAmtUpdate(e.target.value);
                  }}
                ></input>
              </div>
              <div style={{ width: "fit-content" }}>
                <SaveTextInput
                  key={"narration"}
                  id={"txtnarration"}
                  alignment="column"
                  placeholder="Enter Narration"
                  displayLabel={"Narration"}
                  apikey={"narration"}
                  type="text"
                  change={true}
                  pageIndex={pageIndex}
                  // width="700px"
                  defaultValue={
                    // tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                    //   ?.narration
                    narration
                  }
                  returnValue={(e) => {
                    returnValueChanged(e);
                    setNarration(e);
                  }}
                />
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    handleAddAccount();
                  }}
                >
                  Add{" "}
                </Button>
              </div>
            </div>
          </form>
        </div>

        <div
          style={{
            margin: "40px auto",
            width: "1200px",
            minHeight: "250px",
            // border: "1px solid black",
          }}
        >
          <div
            style={{
              overflowX: "auto",
              width: "97%",
              height: "400px",
              borderTop: "none",
              border: "1px solid black",
              borderBottom: "1px solid black",
              borderLeft: "1px solid black",
              marginTop: "10px",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
          >
            <table
              className="tr023-table"
              style={{
                tableLayout: "fixed",
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#ebedf0",
                  border: "1px solid black",
                }}
              >
                <tr style={{ position: "sticky" }}>
                  <td width="10%">Sr.No</td>

                  <td width="20%" style={{ display: "none" }}>
                    Account code
                  </td>
                  <td width="60%">Account Name</td>
                  <td width="30%">Narration</td>
                  <td width="20%" align="right">
                    Debit
                  </td>
                  <td width="20%" align="right">
                    Credit
                  </td>
                  <td width="30%" align="center">
                    Instruments
                  </td>
                  <td width="10%" align="center">
                    Delete
                  </td>
                </tr>
              </thead>
              <tbody style={{ maxHeight: "200px", overflowY: "scroll" }}>
                {_vouchers?.map((data, index) => (
                  <>
                    <tr key={index}>
                      <td width="10%">{index + 1}</td>
                      <td width="20%" style={{ display: "none" }}>
                        {data.code}
                      </td>
                      <td width="60%">{data.name}</td>
                      <td width="30%">{data.narration} </td>
                      <td width="20%" align="right">
                        {data.debit}
                      </td>
                      <td width="20%" align="right">
                        {data.credit}
                      </td>
                      <td
                        width="30%"
                        align="center"
                        style={{ padding: "20px" }}
                      >
                        {data?.isDiscountGL == false ? (
                          data?.gldetailtype == "C" ||
                          data?.gldetailtype == "D" ? (
                            <Button
                              type="primary"
                              onClick={(e) => {
                                handleOpenPendingInstrumentPopUp(data);
                              }}
                            >
                              Pending Instruments
                            </Button>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </td>
                      <td width="20%" align="center">
                        {data?.isDiscountGL == false ? (
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={(e) => {
                              handleDeleteAccount(data);
                              generateAPIData();
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
              <tfoot>
                <TotalRow />
              </tfoot>
            </table>
          </div>

          <div style={{ ...bottomControls }}>
            <div>
              <SaveTextInput
                key={"ourreference"}
                id={"txtourreference"}
                placeholder="Enter Reference"
                displayLabel={"Our Reference"}
                apikey={"ourreference"}
                type="text"
                alignment="column"
                change={true}
                pageIndex={pageIndex}
                defaultValue={
                  tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                    ?.ourreference
                }
                returnValue={(e) => {
                  returnValueChanged(e);
                }}
              />
            </div>
            <div>
              <SaveTextInput
                key={"partyreference"}
                id={"txtpartyreference"}
                placeholder="Enter Reference"
                displayLabel={"Party  Reference"}
                apikey={"partyreference"}
                type="text"
                alignment="column"
                pageIndex={pageIndex}
                defaultValue={
                  tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                    ?.partyreference
                }
                returnValue={(e) => {
                  returnValueChanged(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleCloseInstrumentsPopUp}
        fullWidth
        PaperProps={{
          style: {
            minHeight: "90%",
            maxHeight: "90%",
            width: "70%",
            maxWidth: "90%",
          },
        }}
      >
        <DialogTitle>Instrument Sellement</DialogTitle>
        <DialogContent>
          <div>
            <b> Code:</b> {selectedSettlementAcct.code} - <b>Account Name: </b>{" "}
            {selectedSettlementAcct.name}
            <JVPendingInsutrumentsTransaction
              selectedaccount={selectedSettlementAcct}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="primary" onClick={handleCloseInstrumentsPopUp}>
            Cancel{" "}
          </Button>
          <Button type="primary" onClick={handleSaveInstrumentsPopup}>
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default JVScreen;

const inputBoxes = {
  // border: "1px solid black",
  display: "flex",
  alignItems: "center",
  gap: "36px",
  width: "fit-content",
  margin: "auto",
  flexWrap: "wrap",
};

const bottomControls = {
  // border: "1px solid red",
  display: "flex",
  justifyContent: "space-between",
  margin: "48px 0 0",
  flexWrap: "wrap",
};
