// Kaushik Koparkar 6/01/2023
//~# Majid Sayyed - 02/03/2023
import { Button, Collapse, Input, Modal, Space, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getToday,
  dateFormatter,
  enterPress,
} from "../../CommonControllers/AppFunction";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import { handleAdd, poColumns } from "./SalesOrderBillingTableColumns";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import "../../CommonControllers/global.css";
import { IoAperture } from "react-icons/io5";
import ItemsTable from "./ItemsTable";
import { useRef } from "react";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import {
  domaincustomerlist,
  domaincustomerlocationlist,
  getDomaincustomerlist,
  getDomainCustomerSalesman,
  getDomainLocationUrl,
} from "../../CommonControllers/Urls";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import { getDate } from "date-fns";
import { currentTabData } from "../../CommonControllers/Variable";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import SaveTextBox from "../../CommonControllers/NewComponents/SaveComponents/SaveTextBox";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";

function SalesOrder() {
  const [getSelectedItem, setSelectedItem] = useState("");
  const [rowData, setRowData] = useState([]);
  // const [supplierdata, setSupplierdata] = useState(null);
  const useChangedRows = useSaveChangedRows();
  const [igstappl, setigstappl] = useState(false);

  const [displayMessages, setDisplayMessages] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [purchaseRate, setPurchaseRate] = useState(" ");
  const [boxpurchaseRate, setBoxPurchaseRate] = useState(" "); 
  const [selectedQty, setSelectedQty] = useState(" "); // Qty as in pieces in UI, total = pieces * rate
  const [box, setBox] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [customerData, setCustomerData] = useState(); //~# Setting customer data in this state
  const { Panel } = Collapse;
  const auth = useContext(AuthStore);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const itemSearch = useRef(null);
  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  // render
  const [count, setCount] = useState(0);
  const [soNumber, setSoNumber] = useState();
  const [location, setlocation] = useState(); //~# Setting customer location in this state
  const [trdat, settrdat] = useState(auth?.state?.userdata?.workdate   );
  const [status, setstatus] = useState(true);
  const [statusOptions, setStatusOptions] = useState([]);
  const [pageDetails, setPageDetails] = useState();
  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        console.log("Resposnse of Fast Path API", res.Message);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
      }
    });
  }, []);

  const [pageIndex, setPageIndex] = useState();
  console.log("Page Index is", pageIndex);

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR012") {
        setPageIndex(i);
        break;
      }
    }
  }, []);


  // --------------------------------------------------------------
  ///-------------

  ////dropdown clear//////

  const [modalcount, setModalCount] = useState(0);

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const rateRef = useRef(null);
  const btnRef = useRef(null);

  let grossAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
      Number(purchaseRate) || "00";

  let convFactor = getSelectedItem
    ? `${getSelectedItem?.conversionfactor} / box`
    : "";

  useEffect(() => {
    getSelectedItem?.salerate !== undefined
      ? setPurchaseRate(getSelectedItem?.salerate)
      : setPurchaseRate("");

      getSelectedItem?.salerate !== undefined
      ? setBoxPurchaseRate(getSelectedItem?.salerate * getSelectedItem?.conversionfactor)
      : setBoxPurchaseRate("");
  }, [getSelectedItem?.salerate]);

 
  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  function dateSetter() {
    //~# date setter logic here

    const expDays = customerData?.expdeliverydays; //~% prev supplierdata

    let day = workdate?.slice(0, 2);
    let month = workdate?.slice(3, 5);
    let year = workdate?.slice(-4);

    setExpDeliveryDays(`${1 + Number(day)}/${month}/${year}`);
 
  }

  function setDatatoSupplierdata(e) {
    // setSupplierdata(e);
  }

  function handleBoxChange(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setBox(event.target.value);
    }
  }

  function handlePiecesChange(event) {
    const regex = /^[0-9\b]+$/;
    const value = event.target.value;
    if (value === "" || regex.test(value)) {
      // let modulo = Number(value) % 10;
      // let carryOver = Number(value) / 10;
      // carryOver = Math.floor(carryOver);
      // setBox((prev) => Number(prev) + carryOver);

      setSelectedQty(value);
    }
  }


  const handleRateChange = (event) => {
    //~# Regex checking for decimal upto two places
    if (box == 0 && selectedQty == 0) {
      alert("Enter Box or pieces");
      box == 0 ? boxRef.current.focus() : piecesRef.current.focus();
      return;
    }
    const regex = /^\d*(\.\d{0,2})?$/;
    const value = event.target.value;

    if (value == "0.00" || value == "0.0") {
      alert("Cannot enter 0.00 as rate");
      return false;
    }

    if (value === "" || regex.test(value)) {
      setPurchaseRate(value);
    }
  };

  const addItemstoTable = () => {
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");
        setSelectedQty("");
        setPurchaseRate("");
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          selectedQty,
          box
        );
        itemSearch.current.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch.current.focus();
      }
    }
  };

  // useEffect(() => {
  //   if (customerData?.sezappl) {
  //     useChangedRows.updateValue("igstappl", true);
  //    // setigstappl(useChangedRows.getValue("igstappl"));
  //     console.log("setigstappl-11",useChangedRows.getValue("igstappl"))
  //     dateSetter();
  //   } else {
  //     //useChangedRows.updateValue("igstappl", false);
  //     // setigstappl(useChangedRows.getValue("igstappl"));
  //     console.log("setigstappl-112",useChangedRows.getValue("igstappl"))
  //     dateSetter();
  //   }
  // }, [customerData,useChangedRows.getValue("igstappl")]);

  useEffect(() => {
    //console.log("setigstappl-112",customerData)
    if (customerData?.igstappl) {
      setigstappl(true);
      dateSetter();
    } else {
      setigstappl(false);
      dateSetter();
    }
    dateSetter();
  }, [customerData]);

  // useEffect(() => {
  //   if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
  //     setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
  //     setStatusOptions([
  //       {
  //         label: "Save",
  //         value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
  //       },
  //       {
  //         label: "Proceed",
  //         value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
  //       },
  //     ]);
  //   }
  // }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      if (tabStore.state.tabs[pageIndex]?.changedRows?.items.length > 0) {
        // setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
        const newdata = tabStore.state.tabs[pageIndex]?.changedRows?.items.map((i) => {
          return { ...i, boxpurrate: Number(i?.salerate) * Number(i?.conversionfactor) };
        });
        setRowData(newdata);
      }
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
      var logData = tabStore.state.tabs[pageIndex]?.changedRows;

      logData.dataFromEdit = false;

      tabStore.dispatch({
        type: "UPDATE_TABS_ARRAY",
        changedRowsValue: logData,
        tabsFastPath: "TR013",
        keyName: "changedRows",
      });
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);


  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    // var status = "P";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    // var shortguid;

    amount = rowData.reduce((total, item) => total + Number(item?.amount), 0);
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item?.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item.igstamt), 0);
    // shortguid = existingObject.shortguid ? (existingObject.shortguid) : (shortguid.getShortguid("SR"));
    // console.log("new/old shortguid is", shortguid)

    existingObject = {
      ...existingObject,
      cgstamt: cgstamt,
      igstamt: igstamt,
      sgstamt: sgstamt,
      taxableamount: taxableamount,
      amount: amount,
      trdate: trdat || auth?.state?.workdate,
      // shortguid: shortguid,
      items: rowData,
      status: status ? statusOptions[1]?.value : statusOptions[0]?.value
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData, status]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PO"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        // setRowData(items);
        const newdata = items.map((i) => {
          return { ...i, boxpurrate: Number(i?.salerate) * Number(i?.conversionfactor) };
        });
        setRowData(newdata);
      }
    }
  }, []);

  /////validation/////
  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const { packingqty, packingextraqty, rate } = rowData[i];

      if (
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty == "" && packingextraqty == "") ||
        rate == 0 ||
        rate == "" ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0)
      ) {
        isButtonDisabled = true;
        console.log("isButtonDisabled:122", isButtonDisabled);
        break;
      } else if (!customerData) {
        isButtonDisabled = true;
        break;
      }
      console.log("isButtonDisabled:", rowData[0]);
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("SO"));
    }
    setCustomerData(useChangedRows.getValue("customerrecno"));

    setSoNumber(useChangedRows.getValue("orderno"));
    setlocation(useChangedRows.getValue("customerlocationrecno"));
    settrdat(useChangedRows.getValue("trdate"));

    var items = useChangedRows.getValue("items");
    if (items) {
      // setRowData(items);
      const newdata = items.map((i) => {
        return { ...i, boxpurrate: Number(i?.salerate) * Number(i?.conversionfactor) };
      });
      setRowData(newdata);
      if (items[0]?.igstrate > 0) {
        useChangedRows.updateValue("igstappl", true);
        //setigstappl(useChangedRows.getValue("igstappl"));
        //console.log("setigstappl-113",useChangedRows.getValue("igstappl"))
      }
    }
  }, [showFilterModal]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      // useChangedRows.updateValue("igstappl",false);
      setSelectedItem(null);
      setCustomerData(null);
      settrdat(auth?.state?.userdata?.workdate);
      setStatusOptions([
        {
          label: "Save",
          value: pageDetails?.defaultStatus,
        },
        {
          label: "Proceed",
          value: pageDetails?.nextStatus,
        },
      ]);
      setstatus(true);
      console.log(
        "Customer Data is.......................>>>>>>>>>>>>>>>>>",
        customerData,
        useChangedRows.getValue("customerrecno")
      );
      setSelectedQty(" ");
      setPurchaseRate(" ");
      setSoNumber("To Be Allotted");

      setModalCount((p) => p + 1);
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("SO"));

      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [
    tabStore.state.tabs[pageIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows).length,
  ]);

  //// after selecting customer refresh the pages
  useEffect(() => {
    setlocation("");
  }, [customerData]);
  /////validation/////
  useEffect(() => {
    let isButtonDisabled = false;
    console.log("Custermer", rowData);
    for (let i = 0; i < rowData.length; i++) {
      const { packingqty, packingextraqty, salerate } = rowData[i];

      if (
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty == "" && packingextraqty == "") ||
        salerate == 0 ||
        salerate == "" ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0)
      ) {
        isButtonDisabled = true;
        console.log("isButtonDisabled:122", isButtonDisabled);
        break;
      } else if (!customerData) {
        isButtonDisabled = true;
        break;
      }
      console.log(
        "isButtonDisabled:",
        isButtonDisabled,
        packingqty,
        packingextraqty,
        salerate
      );
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);

  return (
    <div style={{ ...mainFrame }}>
      {/* Changing the Format of the UI to create Different Panels */}
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "remarks",
            document.getElementById("remarks").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Cust</p>
          <Input
            id={"remarks"}
            placeholder="Enter Message for Cust"
            defaultValue={useChangedRows.getValue("remarks")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setCount((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setCount((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={count}
      >
        <SearchFilterByBillNo
          fastpath="RP105"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
         <form   style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "40px", // Adjust the column gap as needed
                rowGap: "15px",
                marginTop:"5px"
              }}>
       
      
           
              {/* Select Supplier */}
              <div
               onKeyDown={enterPress}
                style={{
                  zIndex: 10,
                  pointerEvents: rowData.length > 0 && "none",
                  marginTop: "10px"
                }}
              >
                <SaveDropdown
                  defaultSelected={customerData}
                  disabled={rowData && rowData.length > 0?1:0}
                  displayLabel="Cust"
                  placeholder="Cust"
                  //{Pradeep task id-398 }/// added isrequired property
                  isrequired
                  alignment="column"
                  apikey="customerrecno"
                  apiurl={getDomaincustomerlist}
                  selectionLabel="customerdescn"
                  selectionValue="customerrecno"
                  returnValue={(event) => {
                    console.log(
                      "Customer Data is in Retrun Value",
                      customerData
                    );

                  console.log("event---", event);

                  setCustomerData(event);
                }}
                key={modalcount}
              />
            </div>
            <div
              style={{ ...buttons, marginTop: "10px" }}
              onKeyDown={enterPress}
            >
              <SaveDropdown
                // defaultSelected={location}
                placeholder={"Cust Locn"}
                displayLabel="Cust Locn"
                alignment="column"
                apiurl={domaincustomerlocationlist}
                apikey="customerlocationrecno"
                selectionLabel="descn"
                selectionValue="customerlocationrecno"
                returnValue={() => setlocation()}
                /// added for refreshi the dropdown every mount
                // body={customerData && { customerrecno: customerData?.customerrecno }}
                //after editing so the Location is not coming after this change it is coming
                //rishi - 239
                body={{ customerrecno: customerData?.customerrecno }}
                key={modalcount}
              />
            </div>
            {/* Surgeon DropDown Commented for Oneip If need it will come from Backend in the additional details panel */}
            {/* <div style={{ ...buttons }}>
                <SaveDropdown
                  displayLabel="Surgeon"
                  alignment="column"
                  apiurl="getdomaincustomerrefby"
                  apikey="refbyrecno"
                  selectionLabel="refbydescn"
                  selectionValue="refbyrecno"
                  //rishi - 239
                  body={{ customerrecno: customerData?.customerrecno }}
                  key={modalcount}
                />
              </div> */}

            {/* </div>

            <div style={{ ...seconddiv }}> */}
            <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
              <div style={{ position: "relative" }}>
                <label
                  // htmlFor={id}
                  className={`label ${"focused"}`}
                  style={{
                    // zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-19px",
                    left: "12px",
                    //backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  SO No.
                </label>
                <input
                  style={{ ...poNumberStyle }}
                  placeholder={soNumber ? soNumber : "To Be Allotted"}
                  disabled
                  key={modalcount}
                />
              </div>
            </div>
            <div
              style={{ ...buttons, marginTop: "10px" }}
              onKeyDown={enterPress}
            >
              <SaveDatePicker
                defaultValue={trdat}
                disabled={rowData && rowData.length > 0?1:0}
                apiKey="trdate"
                displayLabel="SO Date"
                id="tr012-so-trdate"
                dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                postAlertMsg={`SO Date cannot be greater than Today's Date or less than Opening date`}
                dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New Date Validation
                returnValue={(e) => {
                  // if (e > auth?.state?.userdata?.workdate) {
                  //   settrdat(0);
                  // } else {
                    settrdat(e);
                  // }
                }}
                key={modalcount}
              />
            </div>

            {/* Status */}
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
             onKeyDown={enterPress}>
              <div style={{ position: "relative" }}>
                {/* <label
                  className={`label ${"focused"}`}
                  style={{
                    // zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-12px",
                    left: "12px",
                    //backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  Status
                </label>
                <div
                  onKeyDown={enterPress}
                  className="saveproceed"
                  style={{
                    padding: "8.7px",
                    marginTop: "4px",
                  }}
                >
                  <SaveRadioBtn
                    apikey={"status"}
                    // defaultValue={pageDetails?.defaultStatus}
                    key={modalcount}
                    options={statusOptions}
                  />
                </div> */}
                <SaveCheckbox 
                  displayLabel={"Proceed"}
                  defaultValue={status}
                  editable
                  apikey={"status"}
                  returnValue={(e) => setstatus(e) }                    
                />
              </div>
            </div>

            <div onKeyDown={enterPress}>
              <Button
                className="btn"
                // amol-temp - change the style values
                style={{  marginTop: "8px" }}
                type="default"
                onClick={() => {
                  setshowFilterModal(true);
                  setCount((p) => p + 1);
                }}
              >
                Search Bill No.
              </Button>
            </div>

            <div onKeyDown={enterPress}>
              <Button
              className="btn"
                // amol-temp - change the style values
                //style={{ ...button, marginTop: "8px" }}
                type="default"
                onClick={(e) => setDisplayMessages(!displayMessages)}
              >
                Add / View Messages
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <AdditionalDetails />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onKeyDown={enterPress}
            >
              <SaveCheckbox
                apikey={"isPrint"}
                displayLabel="Print SO"
                editable
                key={modalcount} /// clear after save butoon date 27jun 2023
              />
            </div>
            {/* =============================//*Edit Transaction*\\=================================== */}
          </form>
        </Panel>

        <Panel header="Items Details" key="3">
          <Space size="large">
            <div style={{ zIndex: 2, position: "relative" }}>
              <ItemSearch
                //itemSearchRef={itemSearch}
                inputRef={itemSearch} /// task id-256 rajendra auto focus issue  itemSearchRef-inputRef because in common components inputRef is used
                forFlag={"S"}
                returnValue={setSelectedItem}
                supplierdata={customerData?.customerrecno} //~% prev supplierdata?.supplierrecno
                dropdown
                defaultSelectedItem={getSelectedItem}
                forPage={"SO"}
              />
              {getSelectedItem ? (
                <p>
                  {getSelectedItem?.itemdescn} <br />
                  Available Qty : {getSelectedItem?.availablestock} &nbsp;
                  Pending PO Qty : {getSelectedItem?.pendingpoqty} &nbsp;
                  Pending SO Qty : {getSelectedItem?.pendingsoqty}{" "}
                </p>
              ) : null}
            </div>
            <div style={{ ...callbackTextInputStyles }}>
              {getSelectedItem?.packingextrarecno > 0 &&
              pageDetails?.wantpackingextra ? (
                <div>
                  <p>
                    Boxes:<span style={{ color: "red" }}>*</span>{" "}
                    {`${convFactor}`}
                  </p>
                  <input
                    //{Pradeep, task id -272}
                    //  disabled={getSelectedItem?.packingextrarecno>0? false:true}
                    ref={boxRef}
                    style={{ ...inputStyles }}
                    value={box}
                    id="tr001-input-box"
                    onChange={handleBoxChange}
                    type="number"
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.target.value.length > 0 &&
                          piecesRef.current.focus();
                      }
                    }}
                  />
                </div>
              ) : null}
              <div>
                <p>
                  Pieces <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  //{Pradeep, task id -272}
                  //  disabled={getSelectedItem?.packingextrarecno==0? false:true}
                  ref={piecesRef}
                  style={{ ...inputStyles }}
                  value={selectedQty}
                  // value={selectedQty}
                  id="tr001-input-pieces"
                  type="number"
                  onChange={handlePiecesChange}
                  onKeyDown={(event) => {
                    const { key } = event;
                    if (key === "Enter") {
                      event.target.value.length > 0 && rateRef.current.focus();
                    }
                  }}
                />
              </div>

              {getSelectedItem?.packingextrarecno > 0 &&
                pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress} style={{}}>
                    <p>Box Rate</p>
                    <input
                      ref={rateRef}
                      type="number"
                      style={{ ...inputStyles }}
                      value={boxpurchaseRate}
                      id="tr001-input-rate"
                      disabled={
                        true
                      } 
                    />
                  </div>
                ) : null}

              <div>
                <p>Rate</p>
                <input
                  ref={rateRef}
                  type="number"
                  style={{ ...inputStyles }}
                  value={purchaseRate}
                  disabled={
                    pageDetails?.changesalerateinbill === 0 ? true : false
                  } //  17 Oct 2023
                  id="tr001-input-rate"
                  onChange={handleRateChange}
                  onKeyDown={(event) => {
                    const { key } = event;
                    if (key === "Enter") {
                      if (
                        event.target.value != 0 &&
                        event.target.value.length > 0
                      ) {
                        if (box == 0 && selectedQty == 0) {
                          alert("Enter non-zero values in Box / Pieces");
                          boxRef.current.focus();
                        } else {
                          btnRef.current.focus();
                        }
                      } else {
                        alert("Cannot enter 0 as rate");
                      }
                    }
                  }}
                />
              </div>
              <div>
                <p>Taxable Amount</p>
                <input disabled value={Number(grossAmt).toFixed(2)} style={{ ...grossAmtStyle }}/>
              </div>
              <div>
                <Button
                  ref={btnRef}
                  //style={{ ...btnStyles }}
                  className="btn item-search-btn"
                  icon={<IoAperture size="1.2rem" />}
                  size="large"
                  onClick={() => {
                    console.log("Enter55555", trdat);
                    if (
                      purchaseRate == 0 ||
                      purchaseRate == undefined ||
                      Number(box) + Number(selectedQty) == 0 ||
                      (box == undefined && selectedQty == undefined) ||
                      trdat == 0
                    ) {
                      alert("Enter Valid Data");
                      boxRef.current.focus();
                      return;
                    } else {
                      addItemstoTable();
                    }
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
            {/* <SaveCheckbox apikey={"isPrint"} displayLabel="Print SO" editable /> */}
          </Space>

          <div
            style={{ zIndex: 1, position: "relative", margin: "24px auto 0" }}
          >
            {rowData.length > 0 && (
              <ItemsTable
                rowData={rowData}
                setRowData={setRowData}
                igstappl={igstappl}
                pageDetails={pageDetails}
              />
            )}
          </div>
        </Panel>
      </Collapse>

      {/* Table for Selection  */}
    </div>
  );
}

export default SalesOrder;
// amol-temp - mainFrame width change to 99vw from 100vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
  padding: "10px",
};

const topRowFilters = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  // border:"1px solid red"
  // amol-390 - changed in CSS - gap from 5px to 24px
  gap: "24px",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  // amol-390 - changed in CSS - gap from 36px to 24px
  gap: "24px",
  marginTop: -10, //Pradeep task id -398 added margintop here
  // width:"200px",
  // paddingTop: "20px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "26px",
  height: "41px",
  border: "1px solid rgb(120,120,120)",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  marginTop: "5px",
  borderRadius: "6px",
  // border:"1px solid red"
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "120px",
  borderRadius: "6px",
  marginTop: "6px",
};
// amol-temp - change the values
const poNumberStyle = {
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  height: "39px",
};
const buttons = {};
// amol-temp - added buttonStyle
const buttonStyle = {
  marginTop: "28px",
  textAlign: "right",
  border: "1px solid #dfdfdf",
  height: "36px",
};
const seconddiv = {
  display: "flex",
  gap: "19px",
  // alignItems: "center",
  // // flexWrap: "wrap",
  marginTop: "25px",
};

const button = {
  marginTop: "7px",
  textAlign: "right",
  border: "1px solid #dfdfdfdf",
  height: "37px",
  boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  backgroundColor: "#87CEEB",
};
//~# getdomainsupplieritemlist api endpoint for Item name => pass payload as { supplierrecno : 78123 } for item name
//~# conversionfactor = boxes * conversionfactor + pieces =
